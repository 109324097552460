//import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import Admin from '@/components/Admin';
import Billing from '@/components/Billing';
import Support from '@/components/Support';
import CloudServices from '@/components/CloudServices';
import Json from '@/components/Json';
import CloudSessions from '@/components/CloudSessions';
import Home from './views/Home.vue';
//import PoolStatus from '@/components/PoolStatus';
//Vue.use(Router)

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/sessions',
      name: 'CloudSessions',
      component: CloudSessions,
    },
    {
      path: '/services',
      name: 'CloudServices',
      component: CloudServices,
    },
    {
      path: '/database',
      name: 'Json',
      component: Json,
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
    },
    {
      path: '/billing',
      name: 'Billing',
      component: Billing,
    },
    {
      path: '/support',
      name: 'Support',
      component: Support,
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    },
    {
      path: '/.well-known/matrix/server'
    },
    {
      path: '/.well-known/matrix/client'
    }
    ,
    {
      path: '/chat'
    }
  ]
})
