/* eslint-disable no-console */
<template>
  <div class="uk-container">
    <h1 class="uk-h1" >Billing & Alerts &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refresh">&#8635;</button></h1>

  <div class="uk-margin-large-bottom">
    <div v-if="orders.length !== 0">
      <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
      <h3 class="uk-card-title uk-text-primary">Orders &nbsp; <button class="uk-button uk-button-primary" v-if="pdfSrc==''" @click="getOrderPreview">Get current order preview (pdf)</button></h3>
      <p>Click view to retrieve order document.</p>

    <table class="uk-table uk-table-striped">
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Action</th>
          </tr>
        </thead>
    <tbody>
          <tr
                v-for="order in this.orders" v-bind:key="order.orderId"
          >
            <td>{{ order.fromDate }}</td>
            <td>{{ order.toDate }}</td>
            <td>
              <button class="uk-button uk-button-default" @click="getOrderView(order.orderId)">View</button>
            </td>
          </tr>
    </tbody>
    </table>
    <div v-if="pdfSrc!==''" ><button class="uk-button uk-button-default uk-margin-small uk-align-right" @click="clearPreview">Clear</button><canvas id="the-canvas" /></div>
    </div>
    </div>
    <div>
    <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">    
      <h3 class="uk-card-title uk-text-primary">Order period
      </h3>
      <p>Get a preview of the current order summary or select dates to retrieve detailed usage information to be retrieved and listed below.</p>
      
      From: <input ref="billfrom" class="uk-input uk-form-width-medium"
        icon="off"
        type="date" 
        :value="billfrom && new Date(billfrom.getTime()-(billfrom.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]"
        @input="billfrom = $event.target.valueAsDate;billfrom.setHours(0,0);"
        @change="getOrders"
      >
      &nbsp;To: <input ref="billto" class="uk-input uk-form-width-medium"
        type="date"
        :value="billto && new Date(billto.getTime()-(billto.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]"
        @input="billto = $event.target.valueAsDate;billto.setHours(23,59,59);"
        @change="getOrders"
      >
      &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="getBill">&#8635;</button>
    </div>
    
    </div>
    <div
      v-for="bill in this.bills" v-bind:key="bill.company"
    >
      <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
            <h3 class="uk-card-title uk-text-primary">
              <span v-if="bill.users.length != 1">Current costs for {{ bill.company }} ( Total: {{computeTotal(bill)}} )</span>
              <span v-else>Current costs for {{ bill.users[0] }} @ {{ bill.company }}</span>
            </h3>
      
            <table class="uk-table uk-table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Reference</th>
                  <th>End user</th>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Cost</th>
                  <!--<th>Discount</th>-->
                </tr>
              </thead>
              <tbody>
                <tr v-for="billitem in bill.items.slice().reverse()" v-bind:key="billitem.uid">
                  <td>{{ billitem.date }}</td>
                  <td>{{ billitem.purchaseBy }}</td>
                  <td>{{ billitem.endUser }}</td>
                  <td>{{ billitem.item.description }}</td>
                  <td>{{ billitem.quantity }}</td>
                  <td>{{ billitem.item.price }}&nbsp;{{billitem.currency}}</td>
                  <!--<td>{{ billitem.discount }} </td>-->
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//@/components/vue-pdf.vue
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

UIkit.use(Icons);
export default {
  name: "Billing",
  data() {
    return {
      licenses: [],
      bills: [],
      orders: [],
      billTimer: {},
      companyTimer: {},
      company: {},
      billfrom:{},
      billto:{},
      pdfModal:false,
      pdfSrc:""
    };
  },
  methods: {
    // Add pdf viewer PDFJS.getDocument({ data: 'pdfAsStringHere...', });
    alertMe(obj){
      console.log(obj);
    },
    getBill() {
      if (this.getAuth()!=null) {
        const interval = {"user": "", "company": "", "start": this.$refs.billfrom.value,"stop":this.$refs.billto.value};
        this.$http
          .post("/api/bill",interval, {
            headers: {
              Authorization: this.getAuth(),
            },
          })
          .then((response) => {
            if (!response.data.error) {
              this.$store.commit("addPositiveMessage", response.data.msg);
              this.bills = response.data.data.bills;
            } else {
              this.$store.commit("addNegativeMessage", response.data.msg);
            }
            //clearTimeout(this.billTimer);
            //this.billTimer = setTimeout(this.getBill, 3600000);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
            //clearTimeout(this.billTimer);
            //this.billTimer = setTimeout(this.getBill, 60000);
          });
      }
    },
    computeTotal(bill){
      var tot = 0;
      var curr = "";
      bill.items.forEach(function(itm) {
         if(itm && itm.quantity!=undefined){
           tot = tot + itm.quantity*itm.item.price;
           if(itm.currency)
           curr = itm.currency;
         }});
      return tot.toFixed(2) + " " + curr;
    },
    getOrders() {
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          const interval = {"start": this.$refs.billfrom.value,"stop":this.$refs.billto.value};
          this.$http
            .post("/api/orders", interval,{//{"start": Date(this.billfrom.getTime()-(this.billfrom.getTimezoneOffset()*60*1000)).toISOString().split('T')[0], "stop": Date(this.billto.getTime()-(this.billto.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]},{
              headers: {
              Authorization: this.getAuth()
              }
            })
            .then(response => {
              if(!response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
                this.orders = response.data.data;
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
              this.$store.commit('setBusyState',false);
            })
            .catch(() => {
              //Notify user on error...
              this.$store.commit('setBusyState',false);
            });
        }
    },
    getOrderView(orderid) {
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          this.$http
            .post("/api/bill-preview", {"orderId": orderid},{
              headers: {
              Authorization: this.getAuth()
              }
            })
            .then(response => {
              if(!response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
                this.pdfSrc = atob(response.data);
                var loadingTask = this.pdfjsLib.getDocument({data: this.pdfSrc});
                loadingTask.promise.then(this.showPdf, function (reason) {
                  // PDF loading error
                  // eslint-disable-next-line no-console
                  console.error(reason);
                  })
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
              this.$store.commit('setBusyState',false);
            })
            .catch(() => {
              //Notify user on error...
              this.$store.commit('setBusyState',false);
            });
        }
    },
    getOrderPreview() {
      if (this.getAuth()!=null) {
        this.$http
          .get("/api/bill-preview", {
            headers: {
              Authorization: this.getAuth(),
            },
          })
          .then((response) => {
            if (!response.data.error) {

              //var newBlob = new Blob([response], {type: "application/pdf"})

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              //  window.navigator.msSaveOrOpenBlob(newBlob);
              //  return;
              //} 

              // For other browsers: 
              // Create a link pointing to the ObjectURL containing the blob.
              //const data = window.URL.createObjectURL(newBlob);
              //var link = document.createElement('a');
              //link.href = data;
              //link.download="file.pdf";
              //link.click();
              //setTimeout(function(){
                // For Firefox it is necessary to delay revoking the ObjectURL
              //  window.URL.revokeObjectURL(data);
              //}, 100);
              //this.$store.commit("addPositiveMessage", response.data.msg);
              //var tab = window.open("data:application/pdf", "_blank");
              //tab.document.write(response); // where 'html' is a variable containing your HTML
              //tab.document.close();
              //var myWindow = window.open("data:application/pdf," + response,
              //         "_blank", "width=600,height=800");
              //myWindow.focus();
              this.pdfSrc = atob(response.data)
              var loadingTask = this.pdfjsLib.getDocument({data: this.pdfSrc});
              loadingTask.promise.then(this.showPdf, function (reason) {
                // PDF loading error
                // eslint-disable-next-line no-console
                console.error(reason);
              });
            } else {
              this.$store.commit("addNegativeMessage", response.data.msg);
            }
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
      }
    },
    clearPreview(){
     this.pdfSrc="";
    },
    getLicenses() {
      if (this.getAuth()!=null) {
        this.$http
          .get("/api/licenses", {
            headers: {
              Authorization: this.getAuth(),
            },
          })
          .then((response) => {
            if (!response.data.error) {
              this.$store.commit("addPositiveMessage", response.data.msg);
              this.licenses = Array.from(response.data.data);
              this.getBill();
              this.getOrders();
            } else {
              this.$store.commit("addNegativeMessage", response.data.msg);
            }
            //clearTimeout(this.licensesTimer)
            //this.licensesTimer=setTimeout(this.getLicenses, 60000);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
            //clearTimeout(this.licensesTimer)
            //this.licensesTimer=setTimeout(this.getLicenses, 60000);
          });
      }
    },
    getLength(arr) {
      arr.length;
    },
    showPdf(pdf) {
      // eslint-disable-next-line no-console
      console.log('PDF loaded');
      // Fetch the first page
      this.currPage = 1;
      this.thePDF = pdf;
      this.numPages = pdf.numPages;
      if(this.numPages !== 1){
        pdf.getPage(this.currPage).then(this.handlePages);
      }
      else{
        pdf.getPage(this.currPage).then(function(page) {
          // eslint-disable-next-line no-console
          console.log('Page loaded');
          
          var scale = 1.5;
          var viewport = page.getViewport({scale: scale});

          // Prepare canvas using PDF page dimensions
          var canvas = document.getElementById('the-canvas');
          var context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render PDF page into canvas context
          var renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          var renderTask = page.render(renderContext);
          renderTask.promise.then(function () {
            // eslint-disable-next-line no-console
            console.log('Page rendered');
            
          });
        });
      }          
    },
    handlePages(page)
      {
          var scale = 1.5;
          var viewport = page.getViewport({scale: scale});

          //We'll create a canvas for each page to draw it on
          var canvasContainer = document.getElementById('the-canvas').parentNode;
          var canvas = this.currPage==1 ? document.getElementById('the-canvas') : document.createElement( "canvas" );
          canvas.style.display = "block";
          var context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
         

          //Draw it on the canvas
          page.render({canvasContext: context, viewport: viewport});

          //Add it to the web page
          if( this.currPage!=1 ) {
            canvasContainer.appendChild( canvas );
          }
          //Move to next page
          this.currPage++;
          if ( this.thePDF !== null && this.currPage <= this.numPages )
          {
             // eslint-disable-next-line no-console
            //console.log('Page loaded, load next...');
            this.thePDF.getPage( this.currPage ).then( this.handlePages );
          }
      },
      refresh() {
        this.getLicenses();
        this.getOrders();
      },
      getAuthBilling(){
        return (localStorage.getItem("token")!=null && localStorage.getItem("token").length > 0)?
              "Basic ".concat(localStorage.getItem("token") )
            :((localStorage.getItem("bearerjwt")!=null && localStorage.getItem("bearerjwt").idToken )?
              "Bearer ".concat(localStorage.getItem("bearerjwt").idToken)
              : null);
    }
  },
  created() {
    this.licenses = [];
    this.orders = [];
    this.bills = [];
    var from = new Date();
    from.setDate(1);
    from.setHours(0,0);
    this.billfrom = new Date(from.getTime());//-(from.getTimezoneOffset()*60*1000));
    var to = new Date();
    to.setHours(23,59,59);
    this.billto = new Date(to.getTime());//-(to.getTimezoneOffset()*60*1000)+23.9*60*60*1000);
    this.getLicenses();
    this.pdfSrc="";
    this.pdfjsLib = window['pdfjs-dist/build/pdf'];
    this.pdfjsLib.GlobalWorkerOptions.workerSrc = '/static/pdf.worker.js';
    // Using DocumentInitParameters object to load binary data.
    this.numPages = 0;
    this.currPage = 1;
    this.thePDF = null;
  },
};
</script>
