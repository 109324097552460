// Import the plugin module here
import localStoragePlugin from './plugins/localStorage';

export const appmessages = {
  state: () => ({
      isBusy: 0,
      sessionMessages: Array.from([]),
      positiveMessages: Array.from([]),
      negativeMessages: Array.from([]),
      contents: {}
    }),
  plugins: [localStoragePlugin],
  mutations: {
    initializeStore() {
      const data = localStorage.getItem('appState');
      if (data) {
        const dataObj = JSON.parse(data)
        dataObj.isBusy=0;
        dataObj.sessionMessages=Array.from([]);
        dataObj.positiveMessages=Array.from([]);
        dataObj.negativeMessages=Array.from([]);
        dataObj.contents = {};
        this.replaceState(Object.assign(this.state, dataObj));
      }
    },
    setBusyState(mystate,isbusystate){
      mystate.isBusy+=isbusystate?1:-1;
      mystate.isBusy = mystate.isBusy < 0 ? 0 : mystate.isBusy; 
    },
    addNegativeMessage(mystate,msg){
      if(msg!==""){
        mystate.negativeMessages.push(msg);
      }
    },
    addPositiveMessage(mystate,msg){
      if (msg!==""){
        mystate.positiveMessages.push(msg);
      }
    },
    addSessionMessage(mystate,msg){
      if (msg!==""){
        mystate.sessionMessages.push(msg);
      }
    },
    clearNegativeMessages(mystate){
      mystate.negativeMessages.length=0;
    },
    clearPositiveMessages(mystate){
      mystate.positiveMessages.length=0;
    },
    clearSessionMessages(mystate){
      mystate.sessionMessages.length=0;
    },
    setContents(mystate,msg){
      if (msg!==""){
        mystate.contents=msg;
      }
    },
    clearContents(mystate){
      mystate.contents={};
    },
  },
  actions: {

  },
  getters:{
    getIsBusy(mystate){
      return mystate.isBusy > 0;
    },
    getContents(mystate){
      return mystate.contents;
    }
  }
}
