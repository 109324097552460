<template>
  <div 
    class="uk-alert" 
    v-if="showInstallBanner"
  >
    <div>
    Do you want to <a
      href="#" 
      @click.prevent="install"
    >
      add this app to your home screen?
    </a>
    <span class="close" @click="this.reject">&times;</span>
    </div>
  </div>
</template>
<script>
let installEvent;

export default {
  name: 'InstallPrompt',
  data() {
    return {
      showInstallBanner: false
    };
  },
  created() {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      if(localStorage.getItem("rejectInstall")==null){
        installEvent = e;
        this.showInstallBanner = true;
      }
    });
  },
  methods: {
    reject() {
      this.showInstallBanner=false;
      localStorage.setItem("rejectInstall",true)
      installEvent = null;
    },
    install() {
      this.showInstallBanner = false;
      installEvent.prompt();
      installEvent.userChoice.then(() => {
        installEvent = null;
      });
    }
  }
};
</script>