<template>
  <div class="uk-container">   
    <div id="modal-add-user" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Select User to add</h2>
        <div>
        <h4 class="uk-h4">
          Exclusive user for {{ currentPool }}
        </h4>
        <select class="uk-select"
          :value="selectedUser"
          v-model="selectedUser"
        >
          <option
            v-for="opt in userOptions"
            :key="opt.value"
            :value="opt.value"
            :selected="selectedUser==opt.value"
          >
            {{ opt.text }}
          </option>
        </select>  
      </div>
      <div class="uk-margin-small-top uk-align-right">
        <button class="uk-button uk-button-default uk-margin-small-right" type="button"
          @click="hideAddUserModal"
        >
          Cancel
        </button>
        <button class="uk-button uk-button-primary" type="button"
          :disabled="selectedUser==''"
          @click="updateUsers(currentPool,selectedUser)"
        >
          Add
        </button>
      </div>
    </div>
    </div>
    <div id="modal-invite-user" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Invite user</h2>
         <div class="uk-container">
          <Form @submit="register" class="uk-form-stacked">
            <div class="uk-margin">
              <label class="uk-label uk-label-default uk-margin-small-top" for="username">Choose a short user name ( 20 char max) </label>
              <input class="uk-input"  type="text" name="username"  placeholder="User name" autocomplete="nickname" v-model="proposedUsername"/>
            </div>
            <div class="uk-margin">
            <select name="newemail" class="uk-select" :value="selectedUser" v-model="selectedUser">
            <option 
            v-for="opt in employeeOptions"
            :key="opt.value"
            :value="opt.value"
            :selected="selectedUser==opt.value"
          >
            {{ opt.text }}
          </option>
          </select>
            </div>
        <p class="uk-text-center">
          <button
            class="uk-button uk-button-default"
            @click="hideNewUserModal"
            type="button"
          >
            Cancel
          </button>
          <button class="uk-button uk-button-primary" type="submit">
            Proceed with purchase
          </button>
        </p>
         </Form>
         </div>
      </div>
    </div>
    <div id="modal-new-employee" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Add employee</h2>
         <div class="uk-container">
          <Form @submit="newEmployee" class="uk-form-stacked">
            <div class="uk-margin">
              <label class="uk-label uk-label-default uk-margin-small-top" for="email">Email address</label>
            <input class="uk-input" 
              name="email"
              label="Email Adress"
              placeholder="email"
              autocomplete="email"
              v-model="selectedUser"
            />
            </div>
            <div class="uk-margin">
              <label class="uk-label uk-label-default uk-margin-small-top" for="first_name">First name</label>
            <input class="uk-input" 
              name="first_name"
              label="First name"
              placeholder="First name"
              autocomplete="given-name"
              v-model="first_name"
            />
            </div>
            <div class="uk-margin">
              <label class="uk-label uk-label-default uk-margin-small-top" for="last_name">Family name</label>
            <input class="uk-input" 
              name="last_name"
              
              placeholder="Last name"
              autocomplete="family-name"
              v-model="last_name"
            />
            </div>
            <div class="uk-margin">
            <label class="uk-label uk-label-default uk-margin-small-top" for="country">Country</label>
            <select name="country" class="uk-select" autocomplete="on" :value="country" v-model="country">
            <option disabled value="">Select country</option>
            <option 
              v-for="opt in countries"
              :key="opt.key"
              :value="opt.value"
              :selected="country==opt.value"
            >
            {{ opt.text }}
            </option>
            </select>
            </div>
            <div class="uk-margin">
              <label class="uk-label uk-label-default uk-margin-small-top" for="language">Language</label>
              <select name="language" class="uk-select" autocomplete="on" :value="language" v-model="language">
              <option disabled value="">Select language</option>
              <option 
              v-for="opt in [{text:'English',value:'en',key:'en'},{text:'Swedish',value:'sv',key:'sv'},{text:'German',value:'de',key:'de'},{text:'Finnish',value:'fi',key:'fi'}]"
              :key="opt.key"
              :value="opt.value"
              :selected="language==opt.value"
            >
              {{ opt.text }}
            </option>
            </select>
            </div>
        <p class="uk-text-center">
          <button
            class="uk-button uk-button-default"
            @click="hideNewEmployeeModal"
            type="button"
          >
            Cancel
          </button>
          <button class="uk-button uk-button-primary" type="submit"
          :disabled="this.selectedUser=='' || this.first_name=='' || this.last_name=='' || this.language=='' || this.country==''"
          >
            Proceed
          </button>
        </p>
         </Form>
         </div>
      </div>
    </div>
    <div id="modal-add-user-to-share" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
      <h2 class="uk-modal-title">Select User to add to project</h2>
        <label  for="userselector" class="uk-label uk-label-default uk-margin-small-top"> Company user for {{ currentShare }}</label>
        <select id="userselector" class="uk-select"
          :value="selectedUser"
          v-model="selectedUser"
        >
          <option
            v-for="opt in userOptions"
            :key="opt.value"
            :value="opt.value"
            :selected="selectedUser==opt.value"
          >
            {{ opt.text }}
          </option>
        </select>  
      
      <div>
        <span>
          User to add
        </span>
        <input class="uk-input"
          v-model="selectedUser"
        >  
      </div>
        <p class="uk-text-center">
          <button class="uk-button uk-button-default" type="button"
          @click="hideAddUserToShareModal"
        >
          Cancel
        </button>
        <button class="uk-button uk-button-primary" type="button"
          :disabled="selectedUser==''"
          @click="updateUsersOfShare(currentShare,selectedUser)"
        >
          Add
        </button>
      </p>
      </div>
    </div>


    <div id="modal-create-pool" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
      <h2 class="uk-modal-title">Create new pool</h2>
      <p>
      Pool names can only contain any combination of alphanumeric characters along with dash (-) and underscore (_).
       The name must be from 1 through 64 characters long.</p>
      <form class="uk-form-stacked">
        <span>
          Name: ( {{(company.name??"").replace(/[^\x00-\x7F]/g, "").replaceAll(' ', '-')}}- will be prepended)
          <input class="uk-input"
            v-model="poolname" 
            type="text"
          >
        </span>
        <span>
          Pool usage type:
          <select class="uk-select"
            :value="poolreplace"
            v-model="poolreplace"
          >
            <option
              v-for="opt in [{text:'Simulations',value:'ice-pool'},{text:'Daylight',value:'daylight-pool'},{text:'CFD',value:'cfd-pool'}]"
              :key="opt.value"
              :value="opt.value"
              :selected="poolreplace==opt.value"
            >
              {{ opt.text }}
            </option>
          </select> 
        </span>
        <span>
          Node size:
          <select class="uk-select"
            :value="poolnodesize"
            v-model="poolnodesize"
          >
            <option 
              v-for="opt in [{text:'64 cores',value:'64'},{text:'48 cores',value:'48'},{text:'32 cores',value:'32'},{text:'16 cores',value:'16'},{text:'8 cores',value:'8'},{text:'4 cores',value:'4'},{text:'2 cores',value:'2'}]"
              :key="opt.value"
              :value="opt.value"
              :selected="poolnodesize==opt.value"
            >
              {{ opt.text }}
            </option>
          </select>
        </span>
        <span>
          Max tasks per node:
          <select class="uk-select"
            :value="poolnodetaskmax"
            v-model="poolnodetaskmax"
          >
            <option 
              v-for="opt in [{text:'64',value:'64'},{text:'48',value:'48'},{text:'32',value:'32'},{text:'16',value:'16'},{text:'8',value:'8'},{text:'4',value:'4'},{text:'2',value:'2'},{text:'1',value:'1'}]"
              :key="opt.value"
              :value="opt.value"
              :selected="poolnodetaskmax==opt.value"
            >
              {{ opt.text }}
            </option>
          </select>
        </span>
        <span>
          Max pool size: 
          <input  class="uk-input"
            v-model="poolmaxsize" 
            type="number"
          >
        </span>
      </form>
      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-margin-small-right" type="button"
          @click="hideCreatePoolModal"
        >
          Cancel
        </button>
        <button class="uk-button uk-button-primary" type="button"
          :disabled="poolname.length==0 || poolreplace.length==0 || poolnodesize.length==0 || poolnodetaskmax.length==0"
          @click="createPool(this.company.name.replace(/[^\x00-\x7F]/g,'').replaceAll(' ', '-')+'-'+poolname,poolreplace,poolnodesize,poolnodetaskmax,poolmaxsize)"
        >
          Create
        </button>
      </p>
      </div>
    </div>
    <div class="uk-margin-large-bottom">
      <h1 class="uk-h1">Subscriptions &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refresh">&#8635;</button></h1>
        <p>Manage subscriptions for the company. Buy and allocate computing or storage resources and software licenses for users. </p>
        <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
          <h3 id="employees" class="uk-card-title uk-text-primary">
            <button 
              class="uk-button uk-button-small-left uk-margin-small-right"
              :uk-icon="this.$route.hash=='#employees'?'minus':'plus'" 
              type="button" 
              @click="this.$route.hash=='#employees'?this.$router.replace({hash: ''}):this.$router.replace({hash: '#employees'});"
            />    
            Employees 
          </h3> 
          <p>Add employees to your company EQUA Cloud Service account. Impersonate one of the company users in order to act on their behalf or just see the serivces from their perspective. To stop impersonating, press clear alias where you normally find the logout button. For users without cloud accounts these can be created and users invited to activate.</p>
          <div  v-show="this.$route.hash=='#employees'">
          <div> 
          <h4 class="uk-margin-small-bottom">{{ company? company.name :"" }} 
          <button
              class="uk-button uk-button-default uk-align-right"
              uk-icon="plus" 
              type="button" 
              @click="showNewEmployeeModal">
              Add company employee
          </button> 
        </h4>
          

          <div class="uk-margin" uk-grid >
          <select class="uk-select uk-width-1-1 uk-grid-match uk-margin-small-right"
            :value="selectedUser"
            v-model="selectedUser"
          >
        
            <option
            v-for="opt in userOptions"
            :key="opt.value"
            :value="opt.value"
            :selected="selectedUser==opt.value"
            >
            {{ opt.text }}
            </option>
          </select>
          
          <button :hidden="selectedUser=='' || !this.companyUsers.includes(selectedUser)" 
              class="uk-width-1-1 uk-grid-match uk-button uk-button-default uk-margin-small-right" 
              type="button" v-if="selectedUser!=''" @click="setAlias(selectedUser)">Impersonate</button>  
          <button :hidden="selectedUser=='' || this.companyUsers.includes(selectedUser)"
              class="uk-width-1-1 uk-grid-match uk-button uk-button-default uk-margin-small-right"
              type="button"
              @click="showNewUserModal"
            >
              Create account and invite user
          </button>
      </div>
          </div>
          </div>
        </div>
        <div  
          v-if="company!={} && company.licenses"
        >
          <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
            <h3 id="allocation" class="uk-card-title uk-text-primary">
              <button 
                class="uk-button uk-button-small-left uk-margin-small-right"
                :uk-icon="this.$route.hash=='#allocation'?'minus':'plus'"  
                type="button" 
                @click="this.$route.hash=='#allocation'?this.$router.replace({hash: ''}):this.$router.replace({hash: '#allocation'})"
              />
              Allocation</h3>
              <p>Allocate licenses to users and manage automatic renewals of subscriptions. Please note that it may take up to 30 minutes before the license information is synchronised throughout the system and that the user needs to start a new session to activate the new license.</p>
              <table class="uk-table uk-table-striped" v-show="this.$route.hash=='#allocation'"> 
                <thead>
                  <tr>
                    <th>E-mail address</th>
                    <th>IDA ICE License</th>
                    <th class="uk-text-center">Expires</th>
                    <th class="uk-text-center">Automatic Renewal</th>
                    <th class="uk-text-center">Locked until</th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="lic in company.licenses.filter((x)=>{return x.companyid==company.id}).sort(function(a,b) { return (a.user.toLowerCase() > b.user.toLowerCase())?1:((a.user.toLowerCase() < b.user.toLowerCase())?-1:((a.article > b.article)?-1:((a.article < b.article)?1:0)))})"
                    :key="lic.uuid"
                  >
                    <td class="uk-text-middle" v-if="lic.gmtLockedUntil=='' && !(lic.uuid in this.license) && lic.extname!='iceCloud'">
                      <select 
                        name="LicenseUser"
                        @change="reallocate(lic)"
                        class="form-control uk-select"
                        :value="lic.user"
                        v-model="lic.user"
                      >
                        <option
                          v-for="opt in userOptionsWithFree"
                          :key="opt.value"
                          :value="opt.value"
                          :selected="opt.value==lic.user"
                        >
                          {{ opt.text }}
                        </option>
                      </select>           
                    </td>
                    <td class="uk-text-middle" v-else>
                      <span v-if="lic.uuid in this.license" class="uk-padding-remove-left uk-text-nowrap uk-text-muted uk-padding-small"><span uk-icon='lock' uk-tooltip="checked out"></span> {{ lic.user }}</span>
                      <span v-else class="uk-text-nowrap uk-text-muted uk-padding-small">{{ lic.user }}</span>
                    </td>
                    <td class="uk-text-middle">{{ ((company.offers).filter( (x) =>{ return (x.article==lic.article)})[0] ?? lic).description }}</td>
                    <td class="uk-text-center uk-text-middle">{{ lic.gmtExpires }}</td>
                    <td class="uk-text-center uk-text-middle" v-if="lic.gmtExpires!='' && lic.extname!='iceCloud'">
                      <button  style="border-width:0;background-color:transparent;" type="button"
                        @click="cancel(lic.uuid)"
                        v-if="lic.renew">
                        <label class="uk-switch" for="default-1">
                        <input type="checkbox" id="default-1" disabled checked>
                        <div class="uk-switch-slider"></div>
                      </label>
                      </button>
                      <button style="border-width:0;background-color:transparent;" type="button"
                        @click="uncancel(lic.uuid)"
                      v-else>
                      <label class="uk-switch" for="default-1">
                        <!--<input type="checkbox" id="default-1">-->
                        <div class="uk-switch-slider"></div>
                        </label>
                      </button>
                    </td>
                    <td class="uk-text-center uk-text-middle" v-else> 
                      <span class="uk-text-muted">N/A</span>
                    </td>
                    <td v-if="(lic.gmtLockedUntil!='' || (lic.uuid in this.license)) && isAdmin && lic.extname!='iceCloud'"  class="uk-text-center uk-text-middle"><button  class="uk-button uk-button-default uk-button-small " type="button"
                        uk-icon='unlock' @click="unlock(lic.uuid)">{{ lic.gmtLockedUntil }}</button></td>
                    <td v-else class="uk-text-center uk-text-middle">{{ lic.gmtLockedUntil }} </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
        <div
      v-if="company!={} && company.offers"
    >
      <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
        <h3 id="licenses" class="uk-card-title uk-text-primary">
              <button 
                class="uk-button uk-button-small-left uk-margin-small-right"
                :uk-icon="this.$route.hash=='#licenses'?'minus':'plus'" 
                type="button" 
                muk-toggle="target: .licenses; animation: uk-animation-fade"
                @click="this.$route.hash=='#licenses'?this.$router.replace({hash: ''}):this.$router.replace({hash: '#licenses'})"
              />
          License subscriptions</h3>
          
          
      
        <p>List of active and available licenses. It is possible to sign up for additional licences.</p>
        
      <table class="uk-table uk-table-striped" v-show="this.$route.hash=='#licenses'">
        
        <thead>
          
          <tr>
            <th>Type</th>
            <th class="uk-text-center">Total</th>
            <th class="uk-text-center">Used</th>
            <th class="uk-text-center">Available</th>
            <th class="uk-text-center">Price</th>
            <th class="uk-text-center">Buy</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="type in Array.from(company.offers.map(s=>s.article)).filter( onlyUnique ).sort(function(a,b) { return (a.toLowerCase() > b.toLowerCase())?1:((a.toLowerCase() < b.toLowerCase())?-1:0)})"
            :key="type"
            :hidden="!show_all && company.licenses.filter( (x)=> { return (x.article === type && x.companyid==company.id)}).length==0"
          >
            <td class="uk-text-middle"> {{ company.offers.filter( (x) =>{ return (x.article === type)})[0].description }} </td>
            <td v-if='!type.startsWith("CLD-USE")' class="uk-text-center uk-text-middle"> {{ company.licenses.filter( (x)=> { return (x.article === type && x.companyid==company.id)}).length }}</td>
            <td v-else></td>
            <td v-if='!type.startsWith("CLD-USE")' class="uk-text-center uk-text-middle"> {{ company.licenses.filter( (x)=> { return (x.article === type && x.companyid==company.id && ( x.user && x.user.length > 0))}).length }}</td>
            <td v-else></td>
            <td v-if='!type.startsWith("CLD-USE")' class="uk-text-center uk-text-middle"> {{ company.licenses.filter( (x)=> { return (x.article === type && x.companyid==company.id && (!x.user || x.user.length == 0))}).length }}</td>
            <td v-else></td>
            <td class="uk-text-center uk-text-middle"> {{ company.offers.filter( (x) =>{ return (x.article === type &&  ( x.price && x.price.length > 0) &&  x.price!="")}).map((x)=>x.price).shift() }} </td>
            <td class="uk-text-center uk-text-middle">
              <button  class="uk-button uk-button-primary uk-button-small" type="button"
                v-if='type!=="CLD-ACCT-USER" && !type.startsWith("CLD-USE")' 
                uk-icon="plus"
                @click="buy(type)"
              />
            </td>  
          </tr>
          <tr>
            <th>
            <label for="showall">Show all</label>
            <input name="showall" v-model="show_all" class="uk-checkbox uk-margin-left" type="checkbox" />
           </th>
         </tr>
        </tbody>
      </table>
    </div>
    </div>

    <div
      v-if="company!={} && company.pools"
    >
    <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
      <h3 id="computelimits" class="uk-card-title uk-text-primary">
          <button 
                class="uk-button uk-button-small-left uk-margin-small-right"
                :uk-icon="this.$route.hash=='#computelimits'?'minus':'plus'" 
                type="button" 
                @click="this.$route.hash=='#computelimits'?this.$router.replace({hash: ''}):this.$router.replace({hash: '#computelimits'})"
              />
        Batch pools and compute limits</h3>
      <p>Manage company dedicated computational resurces. It is possible to create and delete pools with compute nodes with configurable access for specific users.</p>
      <div v-show="this.$route.hash=='#computelimits'">
      <button
                class="uk-button uk-button-primary uk-button-small"
                uk-icon="plus" 
                @click="showCreatePoolModal">Add Pool </button>
     
      <table class="uk-table uk-table-striped">
        <thead>
          <tr>
            <th>Pool name<span>
              
            </span>
            </th>
            <th>Replace default type</th>
            <th>Max concurrent tasks</th>
            <th>Exclusive users</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="pool in company.pools.sort(function(a,b) { return (a.displayname.toLowerCase() > b.displayname.toLowerCase())?1:((a.displayname.toLowerCase() < b.displayname.toLowerCase())?-1:0)})"
            :key="pool.id"
          >
            <td class="uk-text-middle" v-if="pool.replace.length>0">
               <span
                uk-icon="trash" 
                @click="deletePool(pool.id)"> 
                {{ pool.displayname }}
              </span>
            </td>
            <td class="uk-text-middle" v-else>
              {{ pool.id }}
            </td>
            <td class="uk-text-middle">{{ pool.replace.length==0?"EQUA default":pool.replace }}</td>
            <td class="uk-text-middle">
              <input class="uk-input uk-form-width-xsmall"
                v-model="pool.user_max_cores" 
                @change="setMaxCores(pool.id,pool.user_max_cores)"
              />
              <button class="uk-button uk-button-primary uk-button-small uk-margin-small-left" type="button" 
                uk-icon="plus"
                @click="increase(pool.id)"
              />
              <button class="uk-button uk-button-secondary uk-button-small" type="button"
                uk-icon="minus"
                @click="decrease(pool.id)"  
              />
            </td>
            <td class="uk-text-middle" v-if="pool.exclusive_users.length > 0">
              <table class="uk-table uk-table-divider">
                <td>
                  <tr
                    v-for="user in pool.exclusive_users.sort(function(a,b) { return (a.toLowerCase() > b.toLowerCase())?1:((a.toLowerCase() < b.toLowerCase())?-1:0)})"
                    :key="user"
                  >
                    <td>
                        {{ user }}
                        <button class="uk-button uk-button-primary uk-button-small uk-align-right" type="button"
                          uk-icon="minus"
                          @click="removeUser(pool.id,user)"
                        />
                    </td>
                  </tr>
                </td>
                <td class="uk-text-middle">
                  <tr>
                    <button 
                      class="uk-button uk-button-primary uk-button-small" type="button" 
                      uk-icon="plus"
                      @click="addUser(pool.id)"
                    />
                  </tr>
                </td>
              </table>
            </td>
            <td class="uk-text-middle" v-else>
              <span>Anyone</span>
              <button 
                class="uk-button uk-button-primary uk-button-small uk-margin-small-left" type="button" 
                uk-icon="plus"
                @click="addUser(pool.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    </div>




    <div
      v-if="company!={} && company.shares"
    >
      <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
        <h3 id="projects" class="uk-card-title uk-text-primary">
          <button 
                class="uk-button uk-button-small-left uk-margin-small-right "
                :uk-icon="this.$route.hash=='#projects'?'minus':'plus'"  
                type="button" 
                muk-toggle="target: .projects; animation: uk-animation-fade"
                @click="this.$route.hash=='#projects'?this.$router.replace({hash: ''}):this.$router.replace({hash: '#projects'})"
              />     
          Projects with shared storage </h3>
        <p>Manage shared drives for collaboration between users. Access can be shared with any cloud service user, also in other companies. Please note that it may take up to 30 minutes before the license information is synchronised throughout the system and that the user needs to start a new session to access the shared storage.</p>
        <div v-show="this.$route.hash=='#projects'">
        <span>
                <button class="uk-button uk-button-primary uk-button-small" type="button" uk-icon="plus"  @click="createShare">Add Project </button>
              </span>
      <table class="uk-table uk-table-striped">
        <thead>
          <tr>
            <th>
              Project name 
            </th>
            <th>Users with access</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="share in company.shares"
            :key="share.id"
          >
            <td >
              <span
                uk-icon="trash"
                @click="deleteShare(share.id)"
              > {{ share.projectname }}</span>         
            </td>
            <td  v-if="share.users.length > 0">
              <table class="uk-table uk-table-striped">
                <tbody>
                <td>
                  <tr
                    v-for="user in share.users.sort(function(a,b) { return (a.toLowerCase() > b.toLowerCase())?1:((a.toLowerCase() < b.toLowerCase())?-1:0)})"
                    :key="user"
                  >
                    <td>
                        {{ user }}
                    </td>
                    <td>
                        <span
                          uk-icon="trash"
                          @click="removeUserFromShare(share.id,user)"
                        />
                      
                    </td>
                  </tr>
                </td>
                <td>
                  
                    <button class="uk-button uk-button-primary uk-button-small" type="button"
                      uk-icon="plus"
                      @click="addUserToShare(share.id)"
                    />
                  
                </td>
                </tbody>
              </table>
            </td>
            <td class="uk-text-middle" v-else>
              <span>No one</span>
              <button class="uk-button uk-button-primary uk-button-small uk-margin-small-left" type="button"
                uk-icon="plus"
                @click="addUserToShare(share.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    </div>

      

  </div></div>
</template>
<script>

//import TextInput from "@/components/TextInput.vue";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

UIkit.use(Icons);
export default {
  name: 'Admin',
  components: {
    //TextInput
  },
  data() {
    return {
      licenses: [],
      license: {},
      company: {},
      employeeOptionsWithFree: [],
      employeeOptions: [],
      companyUsers:[],
      userOptionsWithFree: [],
      userOptions: [],
      licensesTimer:{},
      companyTimer:{},
      selected: null,
      selectedUser: "",
      first_name:"",
      last_name:"",
      language:"",
      country:"",
      proposedUsername:"",
      currentPool: "", 
      currentShare: "",
      poolname:"",
      poolreplace:"",
      poolnodesize:"",
      poolnodetaskmax:"",
      poolmaxsize: 10,
      isAdmin: false,
      isPowerUser: false,
      isUser: false,
      visibilityCheck: true,
      show_all:true,
      isVisibleEmployees:'plus',
      isVisibleAllocation:'plus',
      isVisibleComputelimits:'plus',
      isVisibleProjects:'plus',
      isVisibleLicenses:'plus',
      countries: [
        { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
        { key: 'ax', value: 'ax', flag: 'ax', text: 'Aland Islands' },
        { key: 'al', value: 'al', flag: 'al', text: 'Albania' },
        { key: 'dz', value: 'dz', flag: 'dz', text: 'Algeria' },
        { key: 'as', value: 'as', flag: 'as', text: 'American Samoa' },
        { key: 'ad', value: 'ad', flag: 'ad', text: 'Andorra' },
        { key: 'ao', value: 'ao', flag: 'ao', text: 'Angola' },
        { key: 'ai', value: 'ai', flag: 'ai', text: 'Anguilla' },
        { key: 'ag', value: 'ag', flag: 'ag', text: 'Antigua' },
        { key: 'ar', value: 'ar', flag: 'ar', text: 'Argentina' },
        { key: 'am', value: 'am', flag: 'am', text: 'Armenia' },
        { key: 'aw', value: 'aw', flag: 'aw', text: 'Aruba' },
        { key: 'au', value: 'au', flag: 'au', text: 'Australia' },
        { key: 'at', value: 'at', flag: 'at', text: 'Austria' },
        { key: 'az', value: 'az', flag: 'az', text: 'Azerbaijan' },
        { key: 'bs', value: 'bs', flag: 'bs', text: 'Bahamas' },
        { key: 'bh', value: 'bh', flag: 'bh', text: 'Bahrain' },
        { key: 'bd', value: 'bd', flag: 'bd', text: 'Bangladesh' },
        { key: 'bb', value: 'bb', flag: 'bb', text: 'Barbados' },
        { key: 'by', value: 'by', flag: 'by', text: 'Belarus' },
        { key: 'be', value: 'be', flag: 'be', text: 'Belgium' },
        { key: 'bz', value: 'bz', flag: 'bz', text: 'Belize' },
        { key: 'bj', value: 'bj', flag: 'bj', text: 'Benin' },
        { key: 'bm', value: 'bm', flag: 'bm', text: 'Bermuda' },
        { key: 'bt', value: 'bt', flag: 'bt', text: 'Bhutan' },
        { key: 'bo', value: 'bo', flag: 'bo', text: 'Bolivia' },
        { key: 'ba', value: 'ba', flag: 'ba', text: 'Bosnia' },
        { key: 'bw', value: 'bw', flag: 'bw', text: 'Botswana' },
        { key: 'bv', value: 'bv', flag: 'bv', text: 'Bouvet Island' },
        { key: 'br', value: 'br', flag: 'br', text: 'Brazil' },
        { key: 'vg', value: 'vg', flag: 'vg', text: 'British Virgin Islands' },
        { key: 'bn', value: 'bn', flag: 'bn', text: 'Brunei' },
        { key: 'bg', value: 'bg', flag: 'bg', text: 'Bulgaria' },
        { key: 'bf', value: 'bf', flag: 'bf', text: 'Burkina Faso' },
        { key: 'bi', value: 'bi', flag: 'bi', text: 'Burundi' },
        { key: 'tc', value: 'tc', flag: 'tc', text: 'Caicos Islands' },
        { key: 'kh', value: 'kh', flag: 'kh', text: 'Cambodia' },
        { key: 'cm', value: 'cm', flag: 'cm', text: 'Cameroon' },
        { key: 'ca', value: 'ca', flag: 'ca', text: 'Canada' },
        { key: 'cv', value: 'cv', flag: 'cv', text: 'Cape Verde' },
        { key: 'ky', value: 'ky', flag: 'ky', text: 'Cayman Islands' },
        { key: 'cf', value: 'cf', flag: 'cf', text: 'Central African Republic' },
        { key: 'td', value: 'td', flag: 'td', text: 'Chad' },
        { key: 'cl', value: 'cl', flag: 'cl', text: 'Chile' },
        { key: 'cn', value: 'cn', flag: 'cn', text: 'China' },
        { key: 'cx', value: 'cx', flag: 'cx', text: 'Christmas Island' },
        { key: 'cc', value: 'cc', flag: 'cc', text: 'Cocos Islands' },
        { key: 'co', value: 'co', flag: 'co', text: 'Colombia' },
        { key: 'km', value: 'km', flag: 'km', text: 'Comoros' },
        { key: 'cg', value: 'cg', flag: 'cg', text: 'Congo Brazzaville' },
        { key: 'cd', value: 'cd', flag: 'cd', text: 'Congo' },
        { key: 'ck', value: 'ck', flag: 'ck', text: 'Cook Islands' },
        { key: 'cr', value: 'cr', flag: 'cr', text: 'Costa Rica' },
        { key: 'ci', value: 'ci', flag: 'ci', text: 'Cote Divoire' },
        { key: 'hr', value: 'hr', flag: 'hr', text: 'Croatia' },
        { key: 'cu', value: 'cu', flag: 'cu', text: 'Cuba' },
        { key: 'cy', value: 'cy', flag: 'cy', text: 'Cyprus' },
        { key: 'cz', value: 'cz', flag: 'cz', text: 'Czech Republic' },
        { key: 'dk', value: 'dk', flag: 'dk', text: 'Denmark' },
        { key: 'dj', value: 'dj', flag: 'dj', text: 'Djibouti' },
        { key: 'dm', value: 'dm', flag: 'dm', text: 'Dominica' },
        { key: 'do', value: 'do', flag: 'do', text: 'Dominican Republic' },
        { key: 'ec', value: 'ec', flag: 'ec', text: 'Ecuador' },
        { key: 'eg', value: 'eg', flag: 'eg', text: 'Egypt' },
        { key: 'sv', value: 'sv', flag: 'sv', text: 'El Salvador' },
        { key: 'gb', value: 'gb', flag: 'gb', text: 'England' },
        { key: 'gq', value: 'gq', flag: 'gq', text: 'Equatorial Guinea' },
        { key: 'er', value: 'er', flag: 'er', text: 'Eritrea' },
        { key: 'ee', value: 'ee', flag: 'ee', text: 'Estonia' },
        { key: 'et', value: 'et', flag: 'et', text: 'Ethiopia' },
        { key: 'eu', value: 'eu', flag: 'eu', text: 'European Union' },
        { key: 'fk', value: 'fk', flag: 'fk', text: 'Falkland Islands' },
        { key: 'fo', value: 'fo', flag: 'fo', text: 'Faroe Islands' },
        { key: 'fj', value: 'fj', flag: 'fj', text: 'Fiji' },
        { key: 'fi', value: 'fi', flag: 'fi', text: 'Finland' },
        { key: 'fr', value: 'fr', flag: 'fr', text: 'France' },
        { key: 'gf', value: 'gf', flag: 'gf', text: 'French Guiana' },
        { key: 'pf', value: 'pf', flag: 'pf', text: 'French Polynesia' },
        { key: 'tf', value: 'tf', flag: 'tf', text: 'French Territories' },
        { key: 'ga', value: 'ga', flag: 'ga', text: 'Gabon' },
        { key: 'gm', value: 'gm', flag: 'gm', text: 'Gambia' },
        { key: 'ge', value: 'ge', flag: 'ge', text: 'Georgia' },
        { key: 'de', value: 'de', flag: 'de', text: 'Germany' },
        { key: 'gh', value: 'gh', flag: 'gh', text: 'Ghana' },
        { key: 'gi', value: 'gi', flag: 'gi', text: 'Gibraltar' },
        { key: 'gr', value: 'gr', flag: 'gr', text: 'Greece' },
        { key: 'gl', value: 'gl', flag: 'gl', text: 'Greenland' },
        { key: 'gd', value: 'gd', flag: 'gd', text: 'Grenada' },
        { key: 'gp', value: 'gp', flag: 'gp', text: 'Guadeloupe' },
        { key: 'gu', value: 'gu', flag: 'gu', text: 'Guam' },
        { key: 'gt', value: 'gt', flag: 'gt', text: 'Guatemala' },
        { key: 'gw', value: 'gw', flag: 'gw', text: 'Guinea-Bissau' },
        { key: 'gn', value: 'gn', flag: 'gn', text: 'Guinea' },
        { key: 'gy', value: 'gy', flag: 'gy', text: 'Guyana' },
        { key: 'ht', value: 'ht', flag: 'ht', text: 'Haiti' },
        { key: 'hm', value: 'hm', flag: 'hm', text: 'Heard Island' },
        { key: 'hn', value: 'hn', flag: 'hn', text: 'Honduras' },
        { key: 'hk', value: 'hk', flag: 'hk', text: 'Hong Kong' },
        { key: 'hu', value: 'hu', flag: 'hu', text: 'Hungary' },
        { key: 'is', value: 'is', flag: 'is', text: 'Iceland' },
        { key: 'in', value: 'in', flag: 'in', text: 'India' },
        { key: 'io', value: 'io', flag: 'io', text: 'Indian Ocean Territory' },
        { key: 'id', value: 'id', flag: 'id', text: 'Indonesia' },
        { key: 'ir', value: 'ir', flag: 'ir', text: 'Iran' },
        { key: 'iq', value: 'iq', flag: 'iq', text: 'Iraq' },
        { key: 'ie', value: 'ie', flag: 'ie', text: 'Ireland' },
        { key: 'il', value: 'il', flag: 'il', text: 'Israel' },
        { key: 'it', value: 'it', flag: 'it', text: 'Italy' },
        { key: 'jm', value: 'jm', flag: 'jm', text: 'Jamaica' },
        { key: 'jp', value: 'jp', flag: 'jp', text: 'Japan' },
        { key: 'jo', value: 'jo', flag: 'jo', text: 'Jordan' },
        { key: 'kz', value: 'kz', flag: 'kz', text: 'Kazakhstan' },
        { key: 'ke', value: 'ke', flag: 'ke', text: 'Kenya' },
        { key: 'ki', value: 'ki', flag: 'ki', text: 'Kiribati' },
        { key: 'kw', value: 'kw', flag: 'kw', text: 'Kuwait' },
        { key: 'kg', value: 'kg', flag: 'kg', text: 'Kyrgyzstan' },
        { key: 'la', value: 'la', flag: 'la', text: 'Laos' },
        { key: 'lv', value: 'lv', flag: 'lv', text: 'Latvia' },
        { key: 'lb', value: 'lb', flag: 'lb', text: 'Lebanon' },
        { key: 'ls', value: 'ls', flag: 'ls', text: 'Lesotho' },
        { key: 'lr', value: 'lr', flag: 'lr', text: 'Liberia' },
        { key: 'ly', value: 'ly', flag: 'ly', text: 'Libya' },
        { key: 'li', value: 'li', flag: 'li', text: 'Liechtenstein' },
        { key: 'lt', value: 'lt', flag: 'lt', text: 'Lithuania' },
        { key: 'lu', value: 'lu', flag: 'lu', text: 'Luxembourg' },
        { key: 'mo', value: 'mo', flag: 'mo', text: 'Macau' },
        { key: 'mk', value: 'mk', flag: 'mk', text: 'Macedonia' },
        { key: 'mg', value: 'mg', flag: 'mg', text: 'Madagascar' },
        { key: 'mw', value: 'mw', flag: 'mw', text: 'Malawi' },
        { key: 'my', value: 'my', flag: 'my', text: 'Malaysia' },
        { key: 'mv', value: 'mv', flag: 'mv', text: 'Maldives' },
        { key: 'ml', value: 'ml', flag: 'ml', text: 'Mali' },
        { key: 'mt', value: 'mt', flag: 'mt', text: 'Malta' },
        { key: 'mh', value: 'mh', flag: 'mh', text: 'Marshall Islands' },
        { key: 'mq', value: 'mq', flag: 'mq', text: 'Martinique' },
        { key: 'mr', value: 'mr', flag: 'mr', text: 'Mauritania' },
        { key: 'mu', value: 'mu', flag: 'mu', text: 'Mauritius' },
        { key: 'yt', value: 'yt', flag: 'yt', text: 'Mayotte' },
        { key: 'mx', value: 'mx', flag: 'mx', text: 'Mexico' },
        { key: 'fm', value: 'fm', flag: 'fm', text: 'Micronesia' },
        { key: 'md', value: 'md', flag: 'md', text: 'Moldova' },
        { key: 'mc', value: 'mc', flag: 'mc', text: 'Monaco' },
        { key: 'mn', value: 'mn', flag: 'mn', text: 'Mongolia' },
        { key: 'me', value: 'me', flag: 'me', text: 'Montenegro' },
        { key: 'ms', value: 'ms', flag: 'ms', text: 'Montserrat' },
        { key: 'ma', value: 'ma', flag: 'ma', text: 'Morocco' },
        { key: 'mz', value: 'mz', flag: 'mz', text: 'Mozambique' },
        { key: 'na', value: 'na', flag: 'na', text: 'Namibia' },
        { key: 'nr', value: 'nr', flag: 'nr', text: 'Nauru' },
        { key: 'np', value: 'np', flag: 'np', text: 'Nepal' },
        { key: 'an', value: 'an', flag: 'an', text: 'Netherlands Antilles' },
        { key: 'nl', value: 'nl', flag: 'nl', text: 'Netherlands' },
        { key: 'nc', value: 'nc', flag: 'nc', text: 'New Caledonia' },
        { key: 'pg', value: 'pg', flag: 'pg', text: 'New Guinea' },
        { key: 'nz', value: 'nz', flag: 'nz', text: 'New Zealand' },
        { key: 'ni', value: 'ni', flag: 'ni', text: 'Nicaragua' },
        { key: 'ne', value: 'ne', flag: 'ne', text: 'Niger' },
        { key: 'ng', value: 'ng', flag: 'ng', text: 'Nigeria' },
        { key: 'nu', value: 'nu', flag: 'nu', text: 'Niue' },
        { key: 'nf', value: 'nf', flag: 'nf', text: 'Norfolk Island' },
        { key: 'kp', value: 'kp', flag: 'kp', text: 'North Korea' },
        { key: 'mp', value: 'mp', flag: 'mp', text: 'Northern Mariana Islands' },
        { key: 'no', value: 'no', flag: 'no', text: 'Norway' },
        { key: 'om', value: 'om', flag: 'om', text: 'Oman' },
        { key: 'pk', value: 'pk', flag: 'pk', text: 'Pakistan' },
        { key: 'pw', value: 'pw', flag: 'pw', text: 'Palau' },
        { key: 'ps', value: 'ps', flag: 'ps', text: 'Palestine' },
        { key: 'pa', value: 'pa', flag: 'pa', text: 'Panama' },
        { key: 'py', value: 'py', flag: 'py', text: 'Paraguay' },
        { key: 'pe', value: 'pe', flag: 'pe', text: 'Peru' },
        { key: 'ph', value: 'ph', flag: 'ph', text: 'Philippines' },
        { key: 'pn', value: 'pn', flag: 'pn', text: 'Pitcairn Islands' },
        { key: 'pl', value: 'pl', flag: 'pl', text: 'Poland' },
        { key: 'pt', value: 'pt', flag: 'pt', text: 'Portugal' },
        { key: 'pr', value: 'pr', flag: 'pr', text: 'Puerto Rico' },
        { key: 'qa', value: 'qa', flag: 'qa', text: 'Qatar' },
        { key: 're', value: 're', flag: 're', text: 'Reunion' },
        { key: 'ro', value: 'ro', flag: 'ro', text: 'Romania' },
        { key: 'ru', value: 'ru', flag: 'ru', text: 'Russia' },
        { key: 'rw', value: 'rw', flag: 'rw', text: 'Rwanda' },
        { key: 'sh', value: 'sh', flag: 'sh', text: 'Saint Helena' },
        { key: 'kn', value: 'kn', flag: 'kn', text: 'Saint Kitts and Nevis' },
        { key: 'lc', value: 'lc', flag: 'lc', text: 'Saint Lucia' },
        { key: 'pm', value: 'pm', flag: 'pm', text: 'Saint Pierre' },
        { key: 'vc', value: 'vc', flag: 'vc', text: 'Saint Vincent' },
        { key: 'ws', value: 'ws', flag: 'ws', text: 'Samoa' },
        { key: 'sm', value: 'sm', flag: 'sm', text: 'San Marino' },
        { key: 'gs', value: 'gs', flag: 'gs', text: 'Sandwich Islands' },
        { key: 'st', value: 'st', flag: 'st', text: 'Sao Tome' },
        { key: 'sa', value: 'sa', flag: 'sa', text: 'Saudi Arabia' },
        { key: 'sn', value: 'sn', flag: 'sn', text: 'Senegal' },
        { key: 'cs', value: 'cs', flag: 'cs', text: 'Serbia' },
        { key: 'rs', value: 'rs', flag: 'rs', text: 'Serbia' },
        { key: 'sc', value: 'sc', flag: 'sc', text: 'Seychelles' },
        { key: 'sl', value: 'sl', flag: 'sl', text: 'Sierra Leone' },
        { key: 'sg', value: 'sg', flag: 'sg', text: 'Singapore' },
        { key: 'sk', value: 'sk', flag: 'sk', text: 'Slovakia' },
        { key: 'si', value: 'si', flag: 'si', text: 'Slovenia' },
        { key: 'sb', value: 'sb', flag: 'sb', text: 'Solomon Islands' },
        { key: 'so', value: 'so', flag: 'so', text: 'Somalia' },
        { key: 'za', value: 'za', flag: 'za', text: 'South Africa' },
        { key: 'kr', value: 'kr', flag: 'kr', text: 'South Korea' },
        { key: 'es', value: 'es', flag: 'es', text: 'Spain' },
        { key: 'lk', value: 'lk', flag: 'lk', text: 'Sri Lanka' },
        { key: 'sd', value: 'sd', flag: 'sd', text: 'Sudan' },
        { key: 'sr', value: 'sr', flag: 'sr', text: 'Suriname' },
        { key: 'sj', value: 'sj', flag: 'sj', text: 'Svalbard' },
        { key: 'sz', value: 'sz', flag: 'sz', text: 'Swaziland' },
        { key: 'se', value: 'se', flag: 'se', text: 'Sweden' },
        { key: 'ch', value: 'ch', flag: 'ch', text: 'Switzerland' },
        { key: 'sy', value: 'sy', flag: 'sy', text: 'Syria' },
        { key: 'tw', value: 'tw', flag: 'tw', text: 'Taiwan' },
        { key: 'tj', value: 'tj', flag: 'tj', text: 'Tajikistan' },
        { key: 'tz', value: 'tz', flag: 'tz', text: 'Tanzania' },
        { key: 'th', value: 'th', flag: 'th', text: 'Thailand' },
        { key: 'tl', value: 'tl', flag: 'tl', text: 'Timorleste' },
        { key: 'tg', value: 'tg', flag: 'tg', text: 'Togo' },
        { key: 'tk', value: 'tk', flag: 'tk', text: 'Tokelau' },
        { key: 'to', value: 'to', flag: 'to', text: 'Tonga' },
        { key: 'tt', value: 'tt', flag: 'tt', text: 'Trinidad' },
        { key: 'tn', value: 'tn', flag: 'tn', text: 'Tunisia' },
        { key: 'tr', value: 'tr', flag: 'tr', text: 'Turkey' },
        { key: 'tm', value: 'tm', flag: 'tm', text: 'Turkmenistan' },
        { key: 'tv', value: 'tv', flag: 'tv', text: 'Tuvalu' },
        { key: 'ug', value: 'ug', flag: 'ug', text: 'Uganda' },
        { key: 'ua', value: 'ua', flag: 'ua', text: 'Ukraine' },
        { key: 'ae', value: 'ae', flag: 'ae', text: 'United Arab Emirates' },
        { key: 'us', value: 'us', flag: 'us', text: 'United States' },
        { key: 'uy', value: 'uy', flag: 'uy', text: 'Uruguay' },
        { key: 'um', value: 'um', flag: 'um', text: 'Us Minor Islands' },
        { key: 'vi', value: 'vi', flag: 'vi', text: 'Us Virgin Islands' },
        { key: 'uz', value: 'uz', flag: 'uz', text: 'Uzbekistan' },
        { key: 'vu', value: 'vu', flag: 'vu', text: 'Vanuatu' },
        { key: 'va', value: 'va', flag: 'va', text: 'Vatican City' },
        { key: 've', value: 've', flag: 've', text: 'Venezuela' },
        { key: 'vn', value: 'vn', flag: 'vn', text: 'Vietnam' },
        { key: 'wf', value: 'wf', flag: 'wf', text: 'Wallis and Futuna' },
        { key: 'eh', value: 'eh', flag: 'eh', text: 'Western Sahara' },
        { key: 'ye', value: 'ye', flag: 'ye', text: 'Yemen' },
        { key: 'zm', value: 'zm', flag: 'zm', text: 'Zambia' },
        { key: 'zw', value: 'zw', flag: 'zw', text: 'Zimbabwe' },
      ] 
    };
  },
  methods: {
    newEmployee(e) {
      e.preventDefault();
      this.hideNewEmployeeModal();
      this.doRegisterNewEmployee(this.selectedUser,this.first_name,this.last_name,this.language,this.country);
    },
    doRegisterNewEmployee(mail,first_name,last_name,language,country){
        if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          this.$http
            .post("/api/company", {"command": "newuser", "argument": 
            "{ \"email\": \""+mail+"\","+
            " \"first_name\":\""+first_name+"\","+
            " \"last_name\":\"" +last_name+"\","+
            " \"language\":\""+language+"\","+
            " \"country\":\""+country+"\""+
            "}"},{
              headers: {
              Authorization: this.getAuth()
              }
            })
            .then(response => {
              if(response.data && !response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
                this.company = response.data.data;
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
              this.$store.commit('setBusyState',false);
            })
            .catch(() => {
              //Notify user on error...
              this.$store.commit('setBusyState',false);
            });
        }
        else{
          this.$store.commit('addNegativeMessage',"You are not authorized to invite users!");
        }
    },
    showNewEmployeeModal() {
      UIkit.modal("#modal-new-employee").show();
    },
    hideNewEmployeeModal() {
      UIkit.modal("#modal-new-employee").hide();
    },
    register(e) {
      e.preventDefault();
      this.doRegister(this.selectedUser,this.proposedUsername,this.first_name,this.last_name,this.company.name);
      //Validate username and email
      //create and sign a new user form
      this.hideNewUserModal();
      //Proceed with asking for password
      //UIkit.modal.dialog("<iframe id='pwmnewuser' title='New user' width='600' height='400' src='pwm/public/newuser?signedForm="+this.signedForm+"'></iframe>")
      //this.getUserInfo();
      //this.$router.replace(this.$route.query.redirect || "/");
    },
    doRegister(mail,proposedUsername){
        if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          this.$http
            .post("/api/company", {"command": "invite", "argument": "{ \"email\": \""+mail+"\", \"username\":\""+proposedUsername+"\"}"},{
              headers: {
              Authorization: this.getAuth()
              }
            })
            .then(response => {
              if(response.data && !response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
                this.company = response.data.data;
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
              this.$store.commit('setBusyState',false);
            })
            .catch(() => {
              //Notify user on error...
              this.$store.commit('setBusyState',false);
            });
        }
        else{
          this.$store.commit('addNegativeMessage',"You are not authorized to invite users!");
        }
    },
    showNewUserModal() {
      UIkit.modal("#modal-invite-user").show();
    },
    hideNewUserModal() {
      UIkit.modal("#modal-invite-user").hide();
    },
    reallocate(newlic){
      const currcnt = this.company.licenses.filter((x)=>{return newlic.user !== "" && x.companyid==newlic.companyid && x.extname==newlic.extname && x.user==newlic.user}).length;
      const askadditional = "Note that " +newlic.user + " already has this license. Please confirm reallocation of the license " + newlic.extname + " to "+ newlic.user +". After the change the license will be locked for the subscription period."
      const question = newlic.user !== "" ? "Please confirm reallocation of the license " + newlic.extname + " to "+ newlic.user +". After the change the license will be locked for the subscription period.":"Please confirm deallocation of the license " + newlic.extname;
      UIkit.modal.confirm(currcnt>1?askadditional:question,{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doReallocate(newlic);},() => {this.refresh()});               
    },
    doReallocate(newlic){
        if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          this.$http
            .post("/api/company", {"command": "reallocate", "argument": "{ \"uuid\": \""+newlic.uuid+"\", \"user\":\""+newlic.user+"\"}"},{
              headers: {
              Authorization: this.getAuth()
              }
            })
            .then(response => {
              if(!response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
                this.company = response.data.data;
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
              this.$store.commit('setBusyState',false);
            })
            .catch(() => {
              //Notify user on error...
              this.$store.commit('setBusyState',false);
            });
        }
        else{
          this.$store.commit('addNegativeMessage',"You are not authorized to reallocate licenses!");
        }
      },
    buy(type){
       var item = this.company.offers.filter( (x) =>{ return (x.article === type)})[0]
       UIkit.modal.confirm("Please confirm intent to purchase "+item.description,{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doBuyConfirmToken(type);},() => {})
    },
    doBuyConfirmToken(type){
        if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          //Wait for confirm dialog, activate spinner
          if(localStorage.getItem("mailtoken"))
          {
            this.$store.commit('setBusyState',true);
            this.$http
                  .post("/api/company",  {"command": "buy", "argument": "{ \"name\": \""+type+"\",\"code\":\""+localStorage.getItem("mailtoken")+"\"}"},{
                    headers: {
                    Authorization: this.getAuth()
                    }
                  })
                  .then(response => {
                    if(!response.data.error){
                      this.$store.commit('addPositiveMessage',response.data.msg);
                      this.company = response.data.data;
                    }
                    else{
                      localStorage.removeItem("mailtoken");
                      this.$store.commit('addNegativeMessage',response.data.msg);
                    }
                    this.$store.commit('setBusyState',false);
                  })
                  .catch((e) => {
                    localStorage.removeItem("mailtoken");
                    //Notify user on error...
                    // eslint-disable-next-line no-console
                    console.log(e);
                    this.$store.commit('setBusyState',false);
                  });
          }
          else{
             UIkit.modal.confirm("A verification code for confirmation of this purchase will be sent to your e-mail.").then(() => {this.doBuyMailTokenConfirm(type);},() => {})
          }
        }
        else{
          this.$store.commit('addNegativeMessage',"You are not authorized to make a purchase!");
        }
    },
    doBuyMailTokenConfirm(type){
        this.$store.commit('setBusyState',true);
        this.$http
        .get("/api/token", {
          headers: {
          Authorization: this.getAuth()
          }
        }).then(response => {
          if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            // eslint-disable-next-line no-console
            console.log("Token response: "+ response.data.data); //Remove when using email...
            UIkit.modal.prompt("Confirm by entering the code sent to your e-mail:" ).then( result => {
            localStorage.setItem("mailtoken",result);
            this.$http
              .post("/api/company",  {"command": "buy", "argument": "{ \"name\": \""+type+"\",\"code\":\""+result+"\"}"},{
                headers: {
                Authorization: this.getAuth()
                }
              })
              .then(response => {
                if(!response.data.error){
                  this.$store.commit('addPositiveMessage',response.data.msg);
                  this.company = response.data.data;
                }
                else{
                  this.$store.commit('addNegativeMessage',response.data.msg);
                }
                this.$store.commit('setBusyState',false);
              })
              .catch((e) => {
                //Notify user on error...
                // eslint-disable-next-line no-console
                console.log(e);
                this.$store.commit('setBusyState',false);
              });
            }
            ,() => {
              this.$store.commit('setBusyState',false);
            });   
          }
          else{
              this.$store.commit('addNegativeMessage',response.data.msg);
              this.$store.commit('setBusyState',false);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
          this.$store.commit('setBusyState',false);
        });
    },
    doCancel(uuid){
        if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          this.$http
            .post("/api/company", {"command": "cancel", "argument": "{ \"uuid\": \""+uuid+"\"}"},{
              headers: {
              Authorization: this.getAuth()
              }
              // Remove unallocated license from company
            })
            .then(response => {
              if(!response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
                this.company = response.data.data;
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
              this.$store.commit('setBusyState',false);
            })
            .catch(() => {
              //Notify user on error...
              this.$store.commit('setBusyState',false);
            });
        }
    },
    doUnCancel(uuid){
        if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          this.$http
            .post("/api/company", {"command": "uncancel", "argument": "{ \"uuid\": \""+uuid+"\"}"},{
              headers: {
              Authorization: this.getAuth()
              }
              // Remove unallocated license from company
            })
            .then(response => {
              if(!response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
                this.company = response.data.data;
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
              this.$store.commit('setBusyState',false);
            })
            .catch(() => {
              //Notify user on error...
              this.$store.commit('setBusyState',false);
            });
        }
    },
    doUnlock(uuid){
        if(this.getAuth()!=null && this.licenses.filter( (x)=> {return x.extname == "cloudAdmin"}).length){
          this.$store.commit('setBusyState',true);
          this.$http
            .post("/api/company", {"command": "unlock", "argument": "{ \"uuid\": \""+uuid+"\"}"},{
              headers: {
              Authorization: this.getAuth()
              }
              // Remove unallocated license from company
            })
            .then(response => {
              if(!response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
                this.company = response.data.data;
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
              this.$store.commit('setBusyState',false);
            })
            .catch(() => {
              //Notify user on error...
              this.$store.commit('setBusyState',false);
            });
        }
    },
    cancel(uuid){
      UIkit.modal.confirm("Please confirm your intent to cancel the subscription",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doCancel(uuid);},() => {})
    },
    uncancel(uuid){
      UIkit.modal.confirm("Please confirm your intent to re-enable the subscription",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doUnCancel(uuid);},() => {})
    },
    unlock(uuid){
      UIkit.modal.confirm("Please confirm your intent to unlock the license",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doUnlock(uuid);},() => {})
    },
    onlyUnique(value, index, self) { 
       return self.indexOf(value) === index;
    },    
    getLicenses() {
      if(this.getAuth()!=null){
        this.$http
          .get("/api/licenses", {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
            if(response.status==200 && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.licenses = Array.from(response.data.data);
              this.isAdmin =
                this.licenses.filter((x) => {
                  return x.extname == "cloudAdmin";
                }).length > 0 || localStorage.getItem('isActuallyCloudAdmin');
              this.isPowerUser =
                this.licenses.filter((x) => {
                  return x.extname == "companyAdmin";
                }).length > 0;
              this.isUser =
                this.licenses.filter((x) => {
                  return x.extname == "ice" || x.extname == "ice-net";
                }).length > 0;
              if(!this.company.name){
                this.getCompany()
              }
            }
            else if(response.status==200){
              this.$store.commit('addNegativeMessage',response.data.msg);
              this.$store.commit('setBusyState',false);
            }
            else{
              this.$store.commit('addNegativeMessage',"Failed to get licenses");
              this.$store.commit('setBusyState',false);
            }
            clearTimeout(this.licensesTimer)
            this.licensesTimer=setTimeout(this.getLicenses, 60000);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
            clearTimeout(this.licensesTimer)
            this.licensesTimer=setTimeout(this.getLicenses, 60000);
            this.$store.commit('setBusyState',false);
          });

          this.$http.get("/api/license", {
              headers: {
                Authorization: this.getAuth(),
              },
            })
            .then((response) => {      
              if(response.data) {
                  this.license=response.data;   
              }  
                else {
                  this.$store.commit("addNegativeMessage", "Failed to check status of licenses!");
                }
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.log(e);
            });  
      }
    },
    getCompany() {
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
        this.$store.commit('setBusyState',true);
        this.$http
          .get("/api/company", {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.company = response.data.data;
              this.employeeOptions=Array.from(this.company.employees.map((s)=> { var newObj = {}; newObj["text"] = s.toLowerCase(); newObj["value"]=s;return newObj;}))
                .sort(function(a,b){return (a.text.toLowerCase() > b.text.toLowerCase())?1:((a.text.toLowerCase() < b.text.toLowerCase())?-1:0)})
              this.employeeOptionsWithFree=[{text:"Free",value:""}]
                .concat(this.employeeOptions)
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            clearTimeout(this.companyTimer)
            this.companyTimer = setTimeout(this.getCompany, 60000);
            this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            clearTimeout(this.companyTimer)
            this.companyTimer = setTimeout(this.getCompany, 60000);
            this.$store.commit('setBusyState',false);
          });
        this.$http
          .get("/api/company-users", {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.companyUsers = Array.from(response.data.data);
              this.userOptions=Array.from(this.companyUsers.map((s)=> { var newObj = {}; newObj["text"] = s.toLowerCase(); newObj["value"]=s;return newObj;}))
                .sort(function(a,b){return (a.text.toLowerCase() > b.text.toLowerCase())?1:((a.text.toLowerCase() < b.text.toLowerCase())?-1:0)})
              this.userOptionsWithFree=[{text:"Available",value:""}]
                .concat(this.userOptions)
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            clearTimeout(this.companyTimer)
            this.companyTimer = setTimeout(this.getCompany, 60000);
            this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            clearTimeout(this.companyTimer)
            this.companyTimer = setTimeout(this.getCompany, 60000);
            this.$store.commit('setBusyState',false);
          });
     }
    },
    getLength(arr){
      arr.length;
    },
    increase(pool){
      this.doIncrease(pool);
      //UIkit.modal.confirm("Please confirm your intent to rezise the pool",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doIncrease(pool);},() => {})
    },
    doIncrease(pool){
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
        this.$store.commit('setBusyState',true);
        this.$http
          .post("/api/company", {"command": "increase", "argument": "{ \"pool\": \""+pool+"\"}"},{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.company = response.data.data;
             }
             else{
               this.$store.commit('addNegativeMessage',response.data.msg);
             }
             this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
      }
    },
    decrease(pool){
      this.doDecrease(pool);
      //UIkit.modal.confirm("Please confirm your intent to rezise the pool",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doDecrease(pool);},() => {})
    },
    doDecrease(pool){
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
        this.$store.commit('setBusyState',true);
        this.$http
          .post("/api/company", {"command": "decrease", "argument": "{ \"pool\": \""+pool+"\"}"},{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.company = response.data.data;
             }
             else{
               this.$store.commit('addNegativeMessage',response.data.msg);
             }
             this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
      }
    },
    setMaxCores(pool,newsize){
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
        this.$store.commit('setBusyState',true);
        this.$http
          .post("/api/company", {"command": "setsize", "argument": "{ \"pool\": \""+pool+"\", \"user_max_cores\": "+newsize+"}"},{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.company = response.data.data;
             }
             else{
               this.$store.commit('addNegativeMessage',response.data.msg);
             }
             this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
      }
    },
    addUser(pool){
      this.currentPool = pool;
      this.selectedUser = "";
      this.showAddUserModal();
    },
    updateUsers(pool,userToAdd){
      this.hideAddUserModal();
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
        this.$store.commit('setBusyState',true);
        this.$http
        .post("/api/company", {"command": "adduser", "argument": "{ \"pool\": \""+pool+"\",\"user\": \""+userToAdd+"\"}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
            if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.company = response.data.data;
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            this.$store.commit('setBusyState',false);
        })
        .catch(() => {
          //Notify user on error...
          this.$store.commit('setBusyState',false);
        });
      } 
    },
    removeUser(pool,userToRemove){
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          this.$http
          .post("/api/company", {"command": "removeuser", "argument": "{ \"pool\": \""+pool+"\",\"user\": \""+userToRemove+"\"}"},{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.company = response.data.data;
             }
             else{
               this.$store.commit('addNegativeMessage',response.data.msg);
             }
             this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
        }
    },
    doCreatePool(pool,repl,sz,tpn,mxsz){
        if(pool && this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          this.$http
          .post("/api/pools",{"command": "create", "argument": "{ \"pool\": \""+pool+"\",\"replace\":\""+repl+"\",\"size\": \""+sz+"\",\"taskmax\":\""+tpn+"\",\"maxsize\":\""+mxsz+"\"}"},{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
              if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.company = response.data.data;
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
              this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
        }
    },
    createPool(pool,repl,sz,tpn,mxsz){
      this.hideCreatePoolModal();
      UIkit.modal.confirm("Please confirm your intent to create a new pool designed to replace the default "+repl,{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doCreatePool(pool,repl,sz,tpn,mxsz);},() =>{});
    },
    doDeletePool(pool){
     if(pool && this.getAuth()!=null  &&  this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
        this.$store.commit('setBusyState',true);
        this.$http
        .post("/api/pools",{"command": "delete", "argument": "{ \"pool\": \""+pool+"\"}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
            if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.company = response.data.data;
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            this.$store.commit('setBusyState',false);
        })
        .catch(() => {
          //Notify user on error...
          this.$store.commit('addNegativeMessage',"An error occured");
          this.$store.commit('setBusyState',false);
        });
      }
    },
    deletePool(pool){
      UIkit.modal.confirm("This operation can not be undone. Proceed?").then(()=>{this.doDeletePool(pool);},()=>{});
    },
    createShare(){
      UIkit.modal.prompt("Enter project name:").then(result => this.doCreateShare(result))
    },
    doCreateShare(share){
      if(share && this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
        this.$store.commit('setBusyState',true);
        this.$http
        .post("/api/share", {"share_id": share, "command": "create", "argument": "{}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.company = response.data.data;
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            this.$store.commit('setBusyState',false);
        })
        .catch(() => {
          //Notify user on error...
          this.$store.commit('setBusyState',false);
        });
      }
    },
    doDeleteShare(share){
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
        this.$store.commit('setBusyState',true);
        this.$http
        .post("/api/share", {"share_id": share, "command": "delete", "argument": "{}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
            if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.company = response.data.data;
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            this.$store.commit('setBusyState',false);
        })
        .catch(() => {
          //Notify user on error...
          this.$store.commit('setBusyState',false);
        });
      }
    },
    deleteShare(share){
      UIkit.modal.confirm("Please confirm your intent to delete the project and all shared files: "+share,{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(()=>{this.doDeleteShare(share);},()=>{});
    },
    addUserToShare(share){
      this.currentShare = share;
      this.selectedUser = "";
      this.showAddUserToShareModal();
    },
    updateUsersOfShare(share,userToAdd){
      this.hideAddUserToShareModal();
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
        this.$store.commit('setBusyState',true);
        this.$http
        .post("/api/share", {"share_id": share, "command": "adduser", "argument": "{ \"email\": \""+userToAdd+"\"}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
            if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.company = response.data.data;
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            this.$store.commit('setBusyState',false);
        })
        .catch(() => {
          //Notify user on error...
          this.$store.commit('setBusyState',false);
        });
      } 
    },
    removeUserFromShare(share,userToRemove){
      if(this.getAuth()!=null && this.licenses.filter( (x)=> { return x.extname == "cloudAdmin" || x.extname == "companyAdmin" }).length){
          this.$store.commit('setBusyState',true);
          this.$http
          .post("/api/share", {"share_id": share, "command": "removeuser", "argument": "{ \"email\": \""+userToRemove+"\"}"},{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.company = response.data.data;
             }
             else{
               this.$store.commit('addNegativeMessage',response.data.msg);
             }
             this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
        }
    },
    clearAlias(){
      if(this.getAuth()!=null){
         this.$store.commit('setBusyState',true);
        this.$http.post("/api/alias",{"command": "remove", "argument": "{ \"alias\":\"\" }"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
            this.theWindow.location.reload();
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
        })
        .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
      }
    },
    setAlias(email){
       this.$store.commit('setBusyState',true);
      if(this.getAuth()!=null){
        this.$http.post("/api/alias",{"command": "create", "argument": "{ \"alias\":\""+email+"\" }"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
            this.$router.push("/");
            window.location.reload();
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
        }).catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
      }
    },
    showAddUserModal() {
      UIkit.modal("#modal-add-user").show();
    },
    showAddUserToShareModal() {
      UIkit.modal("#modal-add-user-to-share").show();
    },
    hideAddUserModal() {
      UIkit.modal("#modal-add-user").hide();
    },
    hideAddUserToShareModal() {
      UIkit.modal("#modal-add-user-to-share").hide();
    },
    showCreatePoolModal() {
      UIkit.modal("#modal-create-pool").show();
    },
    hideCreatePoolModal() {
      UIkit.modal("#modal-create-pool").hide();
    },
    refresh(){
      this.company.name=undefined;//Trigger full company update
      this.getLicenses();
    },
    getAuthAdmin(){
      return (localStorage.getItem("token")!=null && localStorage.getItem("token").length > 0)?
              "Basic ".concat(localStorage.getItem("token") )
            :((localStorage.getItem("bearerjwt")!=null && localStorage.getItem("bearerjwt").idToken )?
              "Bearer ".concat(localStorage.getItem("bearerjwt").idToken)
              : "null");
    },
    isVisible(id){
      if(!this.$refs[id]) return false;
            
      if(this.$refs[id].computedStyleMap().get('display').value=='none'){
        return false;
      }
      else{
       return true;
      }
    },
  },
  watch: {
    visibilityCheck(ov,nv){
      if(ov!=nv){
        this.isVisibleEmployees=     this.isVisible('employeesvisibility')?     'minus' : 'plus';
        this.isVisibleAllocation=    this.isVisible('allocationvisibility')?    'minus' : 'plus';
        this.isVisibleComputelimits= this.isVisible('computelimitsvisibility')? 'minus' : 'plus';
        this.isVisibleProjects=      this.isVisible('projectsvisibility')?      'minus' : 'plus';
        this.isVisibleLicenses=      this.isVisible('licensesvisibility')?      'minus' : 'plus';
      }
    },
    $route($to, from) {
        console.log("changed from " + from.hash + " to "+$to.hash);
        if(this.$refs[from.hash.substring(1)+'visibility']) UIkit.toggle(this.$refs[from.hash.substring(1)+'visibility']).toggle();
        if(this.$refs[$to.hash.substring(1)+'visibility']) UIkit.toggle(this.$refs[$to.hash.substring(1)+'visibility']).toggle();
        //if(!this.isVisible($to.hash.substring(1)+'visibility')){
          console.log("Show "+$to.hash.substring(1));
        //  UIkit.toggle(this.$refs[$to.hash.substring(1)+'visibility']).toggle();
        //}
     }
  },
  created() {
    this.licenses = [];
    this.license = {};
    this.company = {};
    this.getLicenses();
    //Placeholder
    //if(this.$route.hash=='#employees' && !this.isVisible('employeesvisibility')) UIkit.toggle(this.$refs['employeesvisibility']).toggle();
    //this.showCreatePoolModal();
    /*
    navigator.serviceWorker.addEventListener('message',function(event) {
      if(event.data && event.data.equa){
        // eslint-disable-next-line no-console
        console.log("Admin page got: "+ JSON.stringify(event.data));
      } 
    });
    */
  }
};
</script>
