<template>
  <h1>Welcome to EQUA Cloud Services</h1>
  <p>Get access to EQUA software running on cloud resources utilizing 
    <a href="https://docs.microsoft.com/en-us/azure/virtual-desktop/user-documentation/">Azure Virtual Desktop</a> as a subsciption service. 
    In addition to accessing applications through the browser or native clients the services include storage, cloud computing, collaboration features and more.
    No need for software installation or investments in computing hardware, it is all available through your favourite browser on all platforms.
   </p>
    <p>
      Before you can register for an account to access the services your company needs to be granted access through EQUA and your e-mail address needs to be associated with the company account.
      Learn more about EQUA Software like IDA ICE, ESBO, Tunnel and RTV at <a href="https://equa.se">equa.se</a>, where you will also find information on how to contact EQUA to setup your company cloud account.        
    </p>
  <!--<iframe src="https://devs.equa.se/en/services/simulation-services/cssp" style="height:900px;width:100%;border:1px;" title=""></iframe>-->
</template>
<script>
export default {
  name: "Slider"
};
</script>

