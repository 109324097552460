<template>
  <div class="" id="app">
    <div v-if="isBusy" class="uk-cover-container uk-overlay-default uk-position-cover overlay">
      <div class="uk-position-center" uk-spinner="ratio: 3" />
    </div>
    <div>
    <InstallPrompt />

    <!-- Start header -->
    <div class="uk-background-muted uk-width-1-1 uk-margin-remove-left" uk-grid>
      <div class="uk-width-1-4 uk-margin-remove-left">
        <img
          class="uk-margin-small-top uk-margin-small-bottom "
          src="static/images/EQUA_logo.png"
          width="150"
        />
      </div>
      <div class="uk-width-3-4 uk-text-right">
        <div
          v-if="this.isLoggedIn"
          id="nav"
          class="uk-width-1-1 uk-grid-match"
        >
            <nav class="uk-nav-container" uk-nav>
              <div class="uk-nav-left">
                <ul class="uk-nav-nav">
                  <!--<li id="loginButton"
                    class="uk-button uk-button-default uk-margin-small-right"
                    type="button"
                    @click="showLoginModal"
                  >
                    Login
                  </li>-->
                  <!--<li
                        class="uk-button uk-button-default uk-margin-small-right"
                        type="button"
                        @click="showNewUserModal"
                      >
                        Register
                      </li>-->
                </ul>
              </div>
            </nav>
        </div>

        <!-- User -->
        <div v-else class="">
          <div class="uk-inline">
            
            <div class="uk-padding-small">
              <div v-if="alias.length > 0">
                 <div class="uk-width-auto" uk-icon="users" /> <span class="uk-text-primary">{{ this.alias }}</span>
              </div>
              <div v-else>
                 <div class="uk-width-auto" uk-icon="user" /> <span class="uk-text-primary">{{ this.userid }}</span>
              </div>
            </div>
            <div uk-dropdown="mode: click" class="uk-padding-small">
              <div class="uk-text-left uk-margin-small uk-padding-remove" uk-grid>
                
                <div v-if="username" class="uk-width-1-1 uk-text-left uk-margin-small"><span class="uk-text-bold">Email:</span><br>
                <span class="uk-text-primary">{{ this.username }}</span></div>
                    
                <div v-if="companyname" class="uk-width-1-1 uk-text-left uk-margin-small"><span class="uk-text-bold">Company:</span><br>
                <span class="uk-text-primary">{{ this.companyname }}</span></div>
                    
                <div v-if="companymanagers.length" class="uk-width-1-1 uk-text-left uk-margin-small"><span class="uk-text-bold">Cloud manager:</span><br>
                <span  :uk-tooltip="this.companymanagers.join('<br>')" class="uk-text-primary">{{ this.companymanagers[0] }}</span></div>
                <div class="uk-text-left uk-margin-small uk-width-1-1">
                <span class="uk-text-bold">Licenses:</span>                 
                <table class="uk-table uk-table-striped uk-table-small uk-margin-small">
                  <tbody>
                    <tr class="uk-text-primary uk-text-small" v-for="lic in this.licenses" :key="lic.uuid">
                        {{
                          lic.description
                        }} <span class="uk-text-muted uk-align-right uk-margin-remove" uk-icon='lock' uk-tooltip="checked out" v-if="lic.uuid in this.license"></span><br>
                        <span class="uk-text-muted uk-text-small" v-if="!lic.renew && lic.gmtExpires.length > 0">
                          (&nbsp;Expires:&nbsp;{{ lic.gmtExpires }}&nbsp;)
                        </span>
                        
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
                <div
                    v-if="alias.length > 0"
                    @click="clearAlias()"
                    class="
                      uk-button
                      uk-button-default
                      uk-width-1-1
                      uk-margin-small-bottom
                    "
                  >
                  <span>Clear Alias</span>
                </div>
                <div v-else class="uk-button uk-width-1-1 uk-button-default uk-margin-small-bottom"
                  @click="logout()">
                  <span class="ui">Logout</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Start header -->

    <div class="uk-margin" uk-grid >
      <div class="uk-width-1-4@m">
        <div uk-sticky="media: @m">
          <div :class="
                    'uk-margin-small-left '+
                    'uk-margin-small-right '+
                    'uk-grid-small ' 
                    "
                    >
          <div
            v-if="this.isLoggedIn"
            id="nav"
            class="uk-width-1-1 uk-grid-match"
          >
             <p class="uk-link-muted" style="display: block;">
              <button @click="this.showLoginModal" class="uk-button uk-button-primary uk-margin-small-bottom">login</button>
                    <a
                      class="
                        uk-button
                        uk-button-default
                        uk-width-1-1
                        uk-margin-small-bottom
                        uk-text-center
                        uk-link-reset
                      "
                      rel="noopener noreferrer"
                      :href="'/pwm/public/newuser?forwardURL=https://'+this.domain+'/api/new-user'"
                    >
                      <span class="link-button uk-width-1-1 uk-text-center">Sign up</span>
                    </a>
                    <a
                      class="
                        uk-button
                        uk-button-secondary
                        uk-width-1-1
                        uk-margin-small-bottom
                        uk-text-center
                        uk-link-reset
                      "
                      rel="noopener noreferrer"
                      href="/pwm/public/forgottenpassword"
                    >
                      <span class="secondary-link-button uk-width-1-1 uk-text-center">Forgot password</span>
                    </a>
             </p>
          <!--<button
                        class="uk-button uk-button-default uk-margin-small-right"
                        type="button"
                        @click="showNewUserModal"
                      >
                        New user registration
          </button>-->
          </div>    
          <div v-else class="uk-width-1-1@s uk-grid-match">
            <!-- headmenu -->
            <nav class="" uk-nav>
              <div class="uk-margin">
                <ul class="uk-nav uk-nav-default">
                  <li
                    @click="this.$router.push('/')"
                    :class="
                      'uk-button '+
                      (this.$route.path=='/'?'uk-button-primary ':'uk-button-default ')+
                      'uk-width-1-1 '+
                      'uk-margin-small-bottom'
                    "
                  >
                    <span>Overview</span>
                  </li>
                  <li  v-if="this.companyname && this.isUser"
                    :class="
                      'uk-button '+
                      (this.$route.path=='/sessions'?'uk-button-primary ':'uk-button-default ')+
                      'uk-width-1-1 '+
                      'uk-margin-small-bottom'
                    "
                    @click="this.$router.push('/sessions')"
                  >
                    <span>Sessions</span>
                  </li>
                  <li
                    :class="
                      'uk-button '+
                      (this.$route.path=='/services'?'uk-button-primary ':'uk-button-default ')+
                      'uk-width-1-1 '+
                      'uk-margin-small-bottom'
                    "
                    type="button"
                    v-if="this.isAdmin"
                    @click="this.$router.push('/services')"
                  >
                    <span >Manage services</span>
                  </li>
                  <li
                    :class="
                      'uk-button '+
                      (this.$route.path=='/database'?'uk-button-primary ':'uk-button-default ')+
                      'uk-width-1-1 '+
                      'uk-margin-small-bottom'
                    "
                    type="button"
                    v-if="this.isAdmin"
                    @click="this.$router.push('/database')"
                  >
                    <span>Database</span>
                  </li>
                  <li
                    :class="
                      'uk-button '+
                      (this.$route.path=='/admin'?'uk-button-primary ':'uk-button-default ')+
                      'uk-width-1-1 '+
                      'uk-margin-small-bottom'
                    "
                    type="button"
                    v-if="this.companyname && (this.isAdmin || this.isPowerUser)"
                    @click="this.$router.push('/admin')"
                  >
                    <span>Subscriptions</span>
                  </li>
                  <li>
                  <div class="uk-container" style="padding-right:0;">
                    <ul class="uk-nav uk-nav-default">
                    <li v-if="(this.isAdmin || this.isPowerUser) && this.$route.path=='/admin'">
                      <a
                        :class="
                          'uk-button ' +
                          (this.$route.hash=='#employees'?'uk-button-secondary ':'uk-secondary-button-default ')+
                          'uk-width-1-1 '+
                          'uk-margin-small-bottom ' +
                          'uk-text-center '+
                          'uk-align-right '+
                          'uk-padding-remove '+
                          'uk-link-reset'
                        "
                        href="#employees"
                      >
                        <span class="link-button uk-width-1-1 uk-text-center uk-padding-left">Employees</span>
                      </a>
                      <a
                        :class="
                          'uk-button ' +
                          (this.$route.hash=='#allocation'?'uk-button-secondary ':'uk-secondary-button-default ')+
                          'uk-width-1-1 '+
                          'uk-margin-small-bottom ' +
                          'uk-text-center '+
                          'uk-align-right '+
                          'uk-padding-remove '+
                          'uk-link-reset'
                        "
                        href="#allocation"
                      >
                        <span class="link-button uk-width-1-1 uk-text-center">Allocation</span>
                      </a>
                      <a
                        :class="
                          'uk-button ' +
                          (this.$route.hash=='#licenses'?'uk-button-secondary ':'uk-secondary-button-default ')+
                          'uk-width-1-1 '+
                          'uk-margin-small-bottom ' +
                          'uk-text-center '+
                          'uk-align-right '+
                          'uk-padding-remove '+
                          'uk-link-reset'
                        "
                        href="#licenses"
                      >
                        <span class="link-button uk-width-1-1 uk-text-center">License subscriptions</span>
                      </a>
                      <a
                        :class="
                          'uk-button ' +
                          (this.$route.hash=='#computelimits'?'uk-button-secondary ':'uk-secondary-button-default ')+
                          'uk-width-1-1 '+
                          'uk-margin-small-bottom ' +
                          'uk-text-center '+
                          'uk-align-right '+
                          'uk-padding-remove '+
                          'uk-link-reset'
                        "
                        href="#computelimits"
                      >
                        <span class="link-button uk-width-1-1 uk-text-center">Batch pools and compute limits</span>
                      </a>
                      <a
                        :class="
                          'uk-button ' +
                          (this.$route.hash=='#projects'?'uk-button-secondary ':'uk-secondary-button-default ')+
                          'uk-width-1-1 '+
                          'uk-margin-small-bottom ' +
                          'uk-text-center '+
                          'uk-align-right '+
                          'uk-padding-remove '+
                          'uk-link-reset'
                        "
                        href="#projects"
                      >
                        <span class="link-button uk-width-1-1 uk-text-center">Projects with shared storage</span>
                      </a>
                      
                      </li>
                    </ul>
                  </div>
                  </li>
                  <li
                    :class="
                      'uk-button '+
                      (this.$route.path=='/billing'?'uk-button-primary ':'uk-button-default ')+
                      'uk-width-1-1 '+
                      'uk-margin-small-bottom'
                    "
                    type="button"
                    v-if="this.companyname && (this.isAdmin || this.isPowerUser)"
                    @click="this.$router.push('/billing')"
                  >
                    <span>Billing &amp; Alerts</span>
                  </li>
                  <li>
                    <a
                      class="
                        uk-button
                        uk-button-default
                        uk-width-1-1
                        uk-margin-small-bottom
                        uk-text-center
                        uk-link-reset
                      "
                      rel="noopener noreferrer"
                      href="/pwm/private/login"
                    >
                      <span class="link-button uk-width-1-1 uk-text-center">Account self service <div class="uk-width-auto uk-text-center" uk-icon="icon: link; ratio: .8" /></span>
                    </a>
                  </li>
                  <li v-if="this.companyname && this.isUser && !!this.forumurl && this.forumurl!='https://www.equa.se'">
                    <a
                      class="
                        uk-button
                        uk-button-default
                        uk-width-1-1
                        uk-margin-small-bottom
                        uk-text-center
                        uk-link-reset
                      "
                      target="_blank"
                      rel="noopener noreferrer"
                      :href="forumurl"
                      @click="this.refreshUserInfo()"
                    >
                      <span class="link-button uk-width-1-1 uk-text-center">IDA ICE Forum <div class="uk-width-auto uk-text-center" uk-icon="icon: link; ratio: .8" /></span>
                    </a>
                  </li>
                  <li
                    :class="
                      'uk-button '+
                      (this.$route.path=='/support'?'uk-button-primary ':'uk-button-default ')+
                      'uk-width-1-1 '+
                      'uk-margin-small-bottom'
                    "
                    type="button"
                    v-if="this.companyname && this.isUser"
                    @click="this.$router.push('/support')"
                  >
                    <span>Support</span>
                  </li>
                  <li v-if="this.isUser || this.isESBOUser" class="">
                    <a id="icelink"
                      class="
                        uk-button
                        uk-button-default
                        uk-width-1-1
                        uk-margin-small-bottom
                        uk-text-center
                        uk-link-reset
                      "
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://windows365.microsoft.com/ent#/"
                    >
                      
                      <span v-if="this.isUser && !this.isESBOUser" class="link-button uk-width-1-1 uk-text-center">IDA ICE Web Client <div class="uk-width-auto uk-text-center" uk-icon="icon: link; ratio: .8" /></span>
                      <span v-if="!this.isUser && this.isESBOUser" class="link-button uk-width-1-1 uk-text-center">IDA ESBO Web Client <div class="uk-width-auto uk-text-center" uk-icon="icon: link; ratio: .8" /></span>
                      <span v-if="this.isUser && this.isESBOUser" class="link-button uk-width-1-1 uk-text-center">IDA Web Client <div class="uk-width-auto uk-text-center" uk-icon="icon: link; ratio: .8" /></span>
                    </a>

                    <tiny>
                      The button above opens the Azure Virtual Desktop webclient where you have to login as <span class="uk-label uk-text-lowercase">{{ this.userid }}</span>. Tip: Right click and open in private window. For instructions on e.g. alternative ways of connecting please click the button below (recommended).
                    </tiny>
                    <br>
                    <a   
                          class="
                            uk-button
                            uk-button-default
                            uk-width-1-1
                            uk-margin-small-bottom
                            uk-text-center
                            uk-link-reset
                         "
                         target="_blank"
                         rel="noopener noreferrer"
                         href="https://docs.microsoft.com/en-us/azure/virtual-desktop/user-documentation/">
                         <span class="link-button uk-width-1-1 uk-text-center">Azure Virtual Desktop Manual<div class="uk-width-auto uk-text-center" uk-icon="icon: link; ratio: .8" /></span>
                    </a>
                  </li>
                  <li v-else>
                    <p v-if="this.isPowerUser || this.isAdmin">
                       You do currently not have a licese to run any  applications. You can allocate one to yourself on the subscriptions page.
                    </p>
                    <p v-else>
                      You do currently not have a licese to run any applications. Please contact one of the subscription administrators at your company ( e.g. <a :uk-tooltip="this.companymanagers.join('<br>')" :href="`mailto:${this.companymanagers.length>0?this.companymanagers[0]:''}`">{{this.companymanagers.length>0?this.companymanagers[0]:''}}</a> ) to have licenses allocated to you.
                    </p>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
         </div>
        </div><!-- sticky -->
      </div>
      <div class="uk-width-3-4@m uk-text-left">
        <div uk-grid>
          <!-- This is the login modal -->
          <div id="modal-login" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
              <h2 class="uk-modal-title">Login</h2>
              <div>
                <ValidationForm @submit="login">
                  <TextInput 
                    name="email"
                    label="Email Adress"
                    placeholder="email"
                    autocomplete="email"
                  />
                  <TextInput
                    name="pass"
                    label="Password"
                    type="password"
                    autocomplete="current-password"
                    placeholder="password"
                  />
                  <span v-if="loginFailed">Login failed!</span>
                  <p class="uk-text-right">
                    <button
                      class="uk-button uk-button-default uk-margin-small-right"
                      @click="hideLoginModal"
                      type="button"
                    >
                      Cancel
                    </button>
                    <button v-if="false" :class="loginFailed?'uk-button uk-button-primary uk-margin-small-right':'uk-button uk-button-default uk-margin-small-right'"
                      @click="showForgotModal"
                      type="button"
                    >
                      Forgot
                    </button>
                    <a v-else
                     :class="loginFailed?'uk-button uk-button-primary uk-margin-small-right uk-text-center uk-link-reset':'uk-button uk-button-default uk-margin-small-right uk-text-center uk-link-reset'"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/pwm/public/forgottenpassword"
                    >
                      <span class="uk-width-1-1 uk-text-center">Forgot</span>
                    </a>
                    <button :class="loginFailed?'uk-button uk-button-default uk-margin-small-right':'uk-button uk-button-primary uk-margin-small-right'" type="submit">
                      Login
                    </button>
                  </p>
                </ValidationForm>
              </div>
            </div>
          </div>
          <div id="modal-forgot" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
              <h2 class="uk-modal-title">Enter account details</h2>
              <div class="uk-container">
                <ValidationForm @submit="reset">
                  <TextInput 
                    name="resetemail"
                    label="@"
                    placeholder="email"
                    autocomplete="email"
                  />
                  <p class="uk-text-center">
                    <button
                      class="uk-button uk-button-default"
                      @click="hideForgotModal"
                      type="button"
                    >
                      Cancel
                    </button>
                    <button class="uk-button uk-button-primary" type="submit">
                      Reset
                    </button>
                  </p>
                </ValidationForm>
              </div>
            </div>
          </div>
          <div id="modal-new-user" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
              <h2 class="uk-modal-title">Enter email registered with EQUA</h2>
              <div class="uk-container">
                <ValidationForm @submit="register">
                  <TextInput 
                    name="registeremail"
                    label="@"
                    placeholder="email"
                    autocomplete="email"
                  />
                  <p class="uk-text-center">
                    <button
                      class="uk-button uk-button-default"
                      @click="hideNewUserModal"
                      type="button"
                    >
                      Cancel
                    </button>
                    <button class="uk-button uk-button-primary" type="submit">
                      Register
                    </button>
                  </p>
                </ValidationForm>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s uk-grid-match">
            <div v-if="(username && username.length) || hasToken">
              <router-view v-if="companyname" />
              <div v-else class="uk-container">
                <CloudSessions v-if="(username && username.length)"/>
              </div>    
            </div>
            <div v-else>
              <div class="uk-container">
                <About />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

// @ is an alias to /src
import InstallPrompt from "@/components/InstallPrompt.vue";
import About from "@/views/About.vue";
import TextInput from "@/components/TextInput.vue";
import { Form  as  ValidationForm } from "vee-validate";
import * as yup from "yup";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import CloudSessions from "@/components/CloudSessions.vue";
//import { PublicClientApplication } from '@azure/msal-browser';
UIkit.use(Icons);
export default {
  name: "App",
  components: {
    InstallPrompt,
    TextInput,
    About,
    CloudSessions,
    //Field,
    ValidationForm,
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required(),
    });
    return {
      signedForm: "XXXXX",
      schema,
      firstname: "",
      lastname: "",
      username: "",
      cid:"",
      ws: undefined,
      companyname: "",
      companymanagers: [],
      email: "",
      alias: "",
      userid: "",
      company: "",
      country: "",
      city: "",
      phone: "",
      pass: "",
      passrepeat: "",
      forumurl: "",
      loginFailed: false,
      registrationFailed: false,
      resetMailSent: false,
      registerMailSent: false,
      licenses: [],
      license:{},
      isAdmin: false,
      isPowerUser: false,
      isUser: false,
      isESBOUser: false,
      sendCredsToSw: true,
      domain: document.domain,
      //sharedWorker: undefined,
      
    };
  },
  methods: {
    // Validator function
    isRequired(value) {
      return value ? true : "This field is required";
    },
    handleDismissPositive() {
      this.$store.commit("clearPositiveMessages");
      this.$forceUpdate();
    },
    handleDismissNegative() {
      this.$store.commit("clearNegativeMessages");
      this.$forceUpdate();
    },
    getAlias() {
      if (this.getAuth()) {
        this.$http
          .get("/api/alias", {
            headers: {
              Authorization: this.getAuth(),
            },
          })
          .then((response) => {
            if(!response.isAxiosError){
              if (response.data && response.data.error==false) {
                this.alias = response.data.data.email;
                if (this.username != "" && this.alias != "") {
                  this.username = this.alias + " ( impersonating )";
                }
                this.$store.commit("addPositiveMessage", response.data.msg);
              } else {
                this.$store.commit("addNegativeMessage", response.data.msg);
              }
            }
          });
      }
    },
    clearAlias() {
      if (this.getAuth()!=null) {
        this.alias = "";
        this.username = "";
        this.$http
          .post(
            "/api/alias",
            { command: "remove", argument: '{ "alias":"" }' },
            {
              headers: {
    
                Authorization: this.getAuth(),
              },
            }
          )
          .then((response) => {
            if(!response.isAxiosError){
              if (response.data.error==false) {
                this.getUserInfo();
                this.$store.commit("addPositiveMessage", response.data.msg);
              } else {
                this.$store.commit("addNegativeMessage", response.data.msg);
              }
            }
          });
      }
    },
    setAlias(email) {
      if (this.getAuth()!=null) {
        this.$http
          .post(
            "/api/alias",
            { command: "create", argument: '{ "alias":"' + email + '" }' },
            {
              headers: {
                Authorization: this.getAuth(),
              },
            }
          )
          .then((response) => {
            if (response.data.error==false) {
              this.alias = email;
              this.username = "";
              this.getUserInfo();
              this.$store.commit("addPositiveMessage", response.data.msg);
            } else {
              this.$store.commit("addNegativeMessage", response.data.msg);
            }
          });
      }
    },
    getCid() {
      // Get connection id from myrtille
      this.$http
        .get("/api/getcid", {
          headers: {
            Authorization: this.getAuth(),
          },
        })
        .then((response) => {
          this.cid = response.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    logoutBrowser() {
      this.$http
        .get("/api/logoutBrowser")
        .then((response) => {
           this.$store.commit("setBusyState", false);
           if(response.response.status!== 200){
              console.log("encountered a logout problem");
           }
        })
        .catch((e) => {
          console.log(e);
        });
      //this.sendMessage("Log out browser");
    },
    refreshUserInfo(){
      this.$http
          .get("/api/expireuserinfo", {
            headers: {
              Authorization: this.getAuth(),
            },
          }).then(() => {this.getUserInfo();})
    },
    getUserInfo() {
      if (this.getAuth()!=null) {
        this.$store.commit("setBusyState", true);
        this.$http
          .get("/api/userinfo", {
            headers: {
              Authorization: this.getAuth(),
            },
          })
          .then((response) => {
            this.$store.commit("setBusyState", false);
            
            if(response && response.data){
              
              if (response.data.error==false) {        
                //this.sendMessage("Got userinfo!");
                this.$store.commit("addPositiveMessage", response.data.msg);
                this.username = response.data.data.email;
                if (this.username != "" && this.alias != "") {
                  this.username = this.alias + " ( impersonating )";
                }
                this.forumurl = response.data.data.forumurl;
                this.userid = response.data.data.userid;
                this.companyname = response.data.data.company;
                this.companymanagers = response.data.data.managers;
                localStorage.setItem("userid", response.data.data.userid);
                localStorage.setItem("username", this.email);
                localStorage.setItem("forumurl", this.forumurl);
                localStorage.setItem("sessionid", response.data.data.sessionid)
                this.initws();
                this.loginFailed = false;
                this.$http
                  .get("/api/licenses", {
                    headers: {
                      Authorization: this.getAuth(),
                    },
                  })
                  .then((response) => {
                    if(response && response.data){
                      if (response && response.data && response.data.error==false) {
                        this.$store.commit("addPositiveMessage", response.data.msg);
                        this.licenses = response.data.data;
                        this.isAdmin =
                          (this.licenses ?? []).filter((x) => {
                            return x.extname == "cloudAdmin";
                          }).length > 0;
                        this.isPowerUser =
                          (this.licenses ?? []).filter((x) => {
                            return x.extname == "companyAdmin";
                          }).length > 0;
                        this.isUser =
                          (this.licenses ?? []).filter((x) => {
                            return x.extname == "ice" || x.extname == "ice-net";
                          }).length > 0;
                        this.isESBOUser =
                          (this.licenses ?? []).filter((x) => {
                            return x.extname == "esbo";
                          }).length > 0;
                      } else {
                        this.$store.commit("addNegativeMessage", response.data.msg);
                      }
                    }
                  })
                  this.$http
                  .get("/api/license", {
                    headers: {
                      Authorization: this.getAuth(),
                    },
                  })
                  .then((response) => {      
                    if(response.data) {
                        this.license=response.data;
                        
                    }  
                     else {
                        this.$store.commit("addNegativeMessage", "Failed to check status of licenses!");
                      }
                  })
                  .catch((e) => {
                    // eslint-disable-next-line no-console
                    console.log(e);
                  });
                this.doSendCredsToSw();
              } else {
                this.$store.commit("addNegativeMessage", response.data.msg);
              }
            } 
          })
          .catch((e) => {
            this.$store.commit("setBusyState", false);
            if (e && e.response && e.response.status === 401) {
              // eslint-disable-next-line no-console
              localStorage.removeItem("token");
              localStorage.removeItem("bearerjwt");
              console.log("Login failed: " + e);
              this.loginFailed = this.getAuth() !== null;
              if (this.loginFailed) {
                this.showLoginModal();
              }
            } else {
              {
                //console.log("Login failed: " + e);
                //this.$store.commit("addNegativeMessage", "EQUA Cloud Services are currently offline");
              }
              //this.hasToken = false;
            }
          });
      }
    },
    login(values) {
      if (values.pass !== undefined && values.pass.length > 0 
          && values.email !== undefined && values.email.length > 0) {
          localStorage.setItem(
            "token",
            btoa(values.email.concat(":").concat(values.pass))
          );
        this.hideLoginModal();
        this.loginFailed = true;
        this.getAlias();
        this.getUserInfo(btoa(values.email.concat(":").concat(values.pass)));
        this.$router.replace(this.$route.query.redirect || "/");
      }
    },
    getCookie(name) {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        var cookieName = name + '=';
        if (cookie.startsWith(cookieName)) {
            return decodeURIComponent(cookie.substring(cookieName.length));
        }
    }
    return null;
    },
    deleteCookie(name) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
    },
    reset(values) {
      UIkit.modal.alert(JSON.stringify(values, null, 2));
      if (values.resetemail != undefined && values.resetemail.length > 0) {
        this.resetMailSent = true;
      }
      this.hideForgotModal();
      this.$router.replace(this.$route.query.redirect || "/");
    },
    register(values) {
      if (values.registeremail != undefined && values.registeremail.length > 0) {
        this.registerMailSent = true;
        var req = { email : values.registeremail};
        this.$http
        .post("/api/new-user", req, {
          headers: {
          Authorization: this.getAuth(),
          }
        })
        .then(response => {
          if(response && response.data && response.data.msg){
            this.$store.commit('addPositiveMessage',response.data.msg);
          }
          else{
            this.$store.commit('addNegativeMessage',response);
          }
           this.updateSupportMessages();
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
      }
      else{
         UIkit.modal.alert("Empty email, aborting...");
      }
      this.hideNewUserModal();

      this.$router.replace(this.$route.query.redirect || "/");
    },
    logoutBrowserAuth(url){
      var str = url.replace("http://", "http://" + new Date().getTime() + "@");
      var xmlhttp;
      if (window.XMLHttpRequest) xmlhttp=new XMLHttpRequest();
      // eslint-disable-next-line no-undef
      else xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
      xmlhttp.onreadystatechange=function()
      {
          if (xmlhttp.readyState==4) location.reload();
      }
      xmlhttp.open("GET",str,true);
      xmlhttp.setRequestHeader("Authorization","Basic YXNkc2E6")
      xmlhttp.send();
      return false;
    },
    logout() {
      //this.sendMessage("Logging out!");
      this.username = "";
      this.userid = "";
      this.alias = "";
      localStorage.removeItem("token");
      if(this.getAuth()!=null){
        localStorage.removeItem("bearerjwt");
        this.signOut();
      }
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      localStorage.removeItem("mailtoken");
      localStorage.removeItem("forumurl");
      /*
      navigator.serviceWorker.ready.then(function() {
           //reg.controller.postMessage({"authString":""});
          //reg.showNotification("Logout")
          navigator.serviceWorker.controller.postMessage({"authString":""});
      })
      this.sharedWorker.port.postMessage({"authString":""});
      */
      this.logoutBrowser();
      this.$router.replace("/");
      window.location.reload();
    },
    receive(msg) {
      //var data = JSON.parse(msg.data).data;
      alert("Got message in receive");
      console.log(JSON.stringify(msg,2))
      var path = msg.path.reverse().join("/");
      var ts = new Date(msg.timestamp);
      if (msg.prototype.hasOwnProperty.call("stateUpdate")) {
        // eslint-disable-next-line no-console
        console.log(
          "[" + ts + " ] " + path + " State: " + msg.event.stateUpdate.state
        );
      }
      if (msg.prototype.hasOwnProperty.call("dataUpdate")) {
        // eslint-disable-next-line no-console
        console.log(
          "[" +
            ts +
            " ] " +
            path +
            " Data: " +
            JSON.stringify(msg.event.dataUpdate)
        );
      }
    },
    sendMessage(msg){
      if(this.ws!==undefined){
        this.ws.send(msg);
      }
    },
    signScript(str,timestamp){
      if(str!==undefined && timestamp!==undefined) {  
        return localStorage.getItem("sessionid");
      }
      else {
        return ""
      }
    },
    initws() {
      // eslint-disable-next-line no-console
      console.log("Connecting websocket to " + document.location.host+"/api/");
      if (this.ws==undefined){
        if (document.location.host=='ecm'){
          this.ws = new WebSocket("wss://" + document.location.host+"/api/");
        }
        else {
          this.ws = new WebSocket("wss://" + document.location.host+"/api/");
        }
        this.ws.binaryType = 'arraybuffer';
      }
      this.ws.onopen = function (ev) {
        // eslint-disable-next-line no-console
        console.log("Connected: " + JSON.stringify(ev));
        const d = new Date();
        var msg ={};
        msg['message-type'] = 'ida-script';
        msg['version'] = '1';
        //msg['queue'] = '';
        //msg['callback-id'] = '';
        var script = {};
        script.script = '(ida-dom-get-ida-status 1)'
        script.timestamp = d.getTime();
        script.signature = '';//this.signScript(JSON.stringify(script.script),script.timestamp);
        msg['message'] = script;
        msg['reply'] = 'IDA-ICE'+script.signature+'.websocketuid';
        //this.sendMessage(JSON.stringify(msg));
      };
      this.ws.onmessage = function (event) {
        if(event.data instanceof ArrayBuffer ){
         //var buffer = event.data;
         console.log('Received arraybuffer');
        }
        console.log("Got message " + JSON.stringify(event.data));
        //TODO: Forward to worker
      }
      //this.receive;
      //ws.onclose = reconnect
      this.ws.onerror = function (ev) {
        // eslint-disable-next-line no-console
        console.log(ev);
      };
    },
    errors() {
      // eslint-disable-next-line no-console
      console.log("Error");
      this.errs;
    },
    showLoginModal(e) {
      if (e.shiftKey) {
        UIkit.modal("#modal-login").show();
      }
      else{
        this.signIn()
      }
    },
    showForgotModal() {
      //UIkit.modal.dialog(
      //  "<iframe id='pwmnewuser' title='New user' width='600' height='400' src='pwm/public/forgottenpassword'></iframe>"
      //);
      UIkit.modal("#modal-forgot").show();
    },
    showNewUserModal() {
      UIkit.modal("#modal-new-user").show();
    },
    hideLoginModal() {
      UIkit.modal("#modal-login").hide();
    },
    hideForgotModal() {
      UIkit.modal("#modal-forgot").hide();
    },
    hideNewUserModal() {
      UIkit.modal("#modal-new-user").hide();
    },
    displayPositiveMessages() {
      UIkit.notification(this.$store.state.appmessages.positiveMessages, {
        status: "success",
        pos: "top-left",
      });
    },
    displayNegativeMessages() {
      UIkit.notification(this.$store.state.appmessages.negativeMessages, {
        status: "warning",
        pos: "top-left",
      });
    },
    addPositiveMessage() {
      this.$store.commit("addPositiveMessage", "Confirmed");
    },
    addNegativeMessage() {
      this.$store.commit("addNegativeMessage", "Rejected");
    }
  },
  watch: {
    hasPositiveMessages: function (val) {
      if (val == true) {
        this.displayPositiveMessages();
      }
    },
    hasNegativeMessages: function (val) {
      if (val == true) {
        this.displayNegativeMessages();
      }
    }
  },
  computed: {
    isBusy() {
      return this.$store.state.appmessages.isBusy > 0;
    },
    hasPositiveMessages() {
      return this.$store.state.appmessages.positiveMessages.length > 0;
    },
    hasNegativeMessages() {
      return this.$store.state.appmessages.negativeMessages.length > 0;
    },
    isLoggedIn() {
      return !((this.username && this.username.length) || this.hasToken || (this.alias && this.alias.length)); 
    },
    hasToken(){
      return this.getAuth() !== null;
    },
    managerMail(){
      return 'mailto:'+this.companymanagers.length>0?this.companymanagers[0]:''
    }
  },
  created() {
    this.$store.commit("setBusyState", false);
    localStorage.removeItem("authenticating");
    this.username = "";
    this.companyname = "";
    this.companymanagers = [];
    this.loginFailed = false;
    /*
    this.msalConfigApp = {
      auth: {
          clientId: "feddb6da-4b70-4826-a598-fbaff74ee722",
          authority: "https://login.microsoftonline.com/e2ce1cd3-f9bb-4161-bffb-57274421f5dc",
          redirectUri: window.location.pathname
          //redirectUri: `http://localhost:3000`
      },
      cache: {
          cacheLocation: "sessionStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
          loggerOptions: {
              loggerCallback: (level, message, containsPii) => {
                  if (containsPii) {
                      return;
                  }
                  console.log(message);
              }
          }
      }
    };
    this.myMSALObjApp = new PublicClientApplication(this.msalConfigApp);
    this.loginRequestApp ={
      scopes: ["User.Read"]
    };
    this.myMSALObjApp.handleRedirectPromise().then(this.handleResponseApp);
    
    this.accountIdApp = {};
    const accountsApp =  this.myMSALObjApp.getAllAccounts();
    if (accountsApp.length > 0) {
        this.accountIdApp = accountsApp[0];
    }
    */
    if(this.getAuth()!=null){
      this.getUserInfo();
      this.getAlias();
    }
    else{
      let cookie=this.getCookie("new-user")
      if(cookie) {
        if(cookie=="nil"){
                    UIkit.modal.alert("<div class='uk-container'><h3>Welcome new user!</h3> Once you have finished the activation of your account, it can take up to 30 minutes before it has been synchronized across services and can be used to log in.</div>").then(()=>{this.deleteCookie("new-user")})
        }
        else{
          UIkit.modal.alert("<div class='uk-container'><h3>Hello <span class='uk-label-primary'>"+cookie+"@"+document.domain+"</span></h3> Once you have finished the activation of your account, it can take up to 30 minutes before it has been synchronized across services and can be used to log in.</div>").then(()=>{this.deleteCookie("new-user")})
        }
      }
    }
    this.$router.replace(this.$route.query.redirect || "/");
    this.licenses = [];
    this.license={}
    //UIkit.modal.confirm("Please confirm intent to purchase ").then(this.addPositiveMessage,this.addNegativeMessage)
    //UIkit.modal.confirm('UIkit confirm!').then(this.addPositiveMessage,this.addNegativeMessage);
  }
}
</script>
<style lang="less">
@import "../node_modules/uikit/src/less/uikit.less";
@import "./assets/less/theme.less";
@import "./assets/less/style.less";
.overlay{
    opacity:0.75;
    background-color:#eee;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
}
</style>
