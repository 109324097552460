//import Vue from 'vue'
import { createStore } from 'vuex'
// Import the plugin module here
import {appmessages} from './appmessages';
//Vue.use(Vuex)
export const store = createStore({
  modules:{
    appmessages
  }
})
