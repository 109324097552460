<template>
<div class="uk-container">
<input
    type="file"
    id="file-input"
    multiple
    style="display: none;"
    @change="uploadFiles"
/>
<article class="uk-article">
    <h1>Support&nbsp;<a class="uk-button uk-button-default uk-text-large uk-margin-small-right" target="_blank" href="/chat">&#128172;</a><button class="uk-button uk-button-default uk-text-large" @click="refresh">&#8635;</button></h1>
    <p>Below active support cases initiated from the ida ice send to support feature are listed with links to relevant files.</p>
    <p v-if="supportMessages.length==0">
       No active support cases found.
    </p>
    <table class="uk-table uk-margin-remove-vertical uk-padding-remove-vertical" v-else v-for="container in supportMessages.filter((x) =>{ return (x.msgtype === 'container')})" :key="container.timestamp">
       <thead>
        <tr>
            <th>{{new Date(container.timestamp).toLocaleString()}} {{container.session_id}} &nbsp;
                <button class="uk-button" @click="message(container)"  uk-icon="mail" />
                <button class="uk-button" @click="deleteContainer(container)"  uk-icon="trash" />
                 <button class="uk-button" uk-icon="cloud-upload"  @click="selectAndUploadFiles(container)"/>
                </th>
        </tr>
       </thead>
       <tbody>
       <tr>
        <ul  class="uk-list uk-list-striped" uk-accordion="multiple: true">
          <li class="uk-margin-remove-vertical uk-padding-remove-vertical" v-for="mess in supportMessages.filter((x) =>{ return (x.session_id == container.session_id && x.msgtype === 'mail')})"
            :key="mess.name">
            <a class="uk-accordion-title uk-text-small" href="#">{{JSON.parse(mess.json).subject}}  <small>{{new Date(mess.timestamp).toLocaleString()}}</small></a>
            <div class="uk-accordion-content" ><span v-html="JSON.parse(mess.json).htmlmessage"></span></div>
          </li>
          <li class="uk-margin-remove-vertical uk-padding-remove-vertical" v-for="mess in supportMessages.filter((x) =>{ return (x.session_id == container.session_id && x.msgtype != 'mail' && x.msgtype != 'container')})"
            :key="mess.name">
            <a class="uk-accordion-title uk-text-small" href="#">Attachment: {{ mess.name.substring(mess.session_id.length+1) }}  <small>{{new Date(mess.timestamp).toLocaleString()}}</small></a>
            <div class="uk-accordion-content" ><a :href="JSON.parse(mess.json).url">download</a></div>
          </li>
        </ul>
       </tr>
      </tbody>
    </table>
</article>
</div>
</template>

<script>
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
UIkit.use(Icons);

export default {
  name: 'Support',
  data() {
    return {
      supportMessages: [],
      containerURL:"",
      fileInput : {},
      uploadFiles :{},
      uploadProgress: 10.0,
      uploadFile:"",
      currentSession:"",
    };
  },
  methods: {
    sendUploadNotification(sessionid,name,url){
      if(this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        var d = new Date();
        this.$http
          .post("/api/supportsessionpublish", { "session_id": sessionid, "timestamp": d.toISOString().split('.')[0]+"Z","name": name ,"msgtype":"upload","json":"{\"url\":\""+url+"\"}"},{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
             }
             else if(response.data){
               this.$store.commit('addNegativeMessage',response.data.msg);
             }
             this.$store.commit('setBusyState',false);
             this.updateSessions();
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
      }
    },
    selectAndUploadFiles(mess){
      UIkit.modal.prompt("Enter subject:").then( subject => { this.subject=subject;
      UIkit.modal.prompt("Enter message:").then( message => { this.htmlmessage=message;
       // eslint-disable-next-line no-undef
      this.containerURL = new azblob.ContainerURL(JSON.parse(mess.json).url,azblob.StorageURL.newPipeline(new azblob.AnonymousCredential));
      this.fileInput = document.getElementById("file-input");
        if(this.fileInput){
          this.currentSession=mess.session_id;
          this.fileInput.click();
        }
      });});
    },
    updateSupportMessages() {
      if(this.getAuth()!=null){
        this.$http
        .get("/api/supportmessages", {
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.supportMessages = response.data.data;
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
      }
    },
    message(container) {
      if(this.getAuth()!=null){
        var d = new Date();
        UIkit.modal.prompt("Enter subject:").then( subject => { this.subject=subject;
        UIkit.modal.prompt("Enter message:").then( message => { this.htmlmessage=message;
        this.$http
          .post("/api/supportsessionpublish", { "session_id": container.session_id, "timestamp": d.toISOString().split('.')[0]+"Z","name": container.session_id+"-message-"+d.toISOString() ,"msgtype":"mail","json":"{\"subject\":\""+this.subject+"\",\"message\":\""+this.htmlmessage+"\"}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.supportMessages = response.data.data;
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
        });});}
    },
    deleteContainer(container) {
      UIkit.modal.confirm("Please confirm intent to delete support case and all associated files.").then(()=>{
        if(this.getAuth()!=null){
        var req = { session_id : container.session_id, name: "all"};
        this.$http
        .post("/api/removesupportmessage", req, {
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data && response.data.msg){
            this.$store.commit('addPositiveMessage',response.data.msg);
          }
          else{
            this.$store.commit('addNegativeMessage',response);
          }
           this.updateSupportMessages();
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
      }});
    },
    refresh() {
       this.updateSupportMessages();
      },
    getAuthSupport(){
      return (localStorage.getItem("token")!=null && localStorage.getItem("token").length > 0)?
              "Basic ".concat(localStorage.getItem("token") )
            :((localStorage.getItem("bearerjwt")!=null && localStorage.getItem("bearerjwt").idToken )?
              "Bearer ".concat(localStorage.getItem("bearerjwt").idToken)
              : null);
    }
  },
  created() {
    this.updateSupportMessages();
    this.fileInput = document.getElementById("file-input"),
    this.uploadProgress = 0.0,
    this.uploadFile="",
    this.fileShares=[],
    this.subject="Subject missing";
    this.htmlmessage="Message missing";
    this.containerURL="";
    this.uploadFiles = async () => {
      try {
        // eslint-disable-next-line no-console
        this.$store.commit('setBusyState',true);
        const promises = [];
        // eslint-disable-next-line no-unused-vars
        for (const file of this.fileInput.files) {
            this.uploadFile = file.name;
            // eslint-disable-next-line no-undef
            const blockBlobURL = azblob.BlockBlobURL.fromContainerURL(this.containerURL, file.name);promises.push(azblob.uploadBrowserDataToBlockBlob(azblob.Aborter.none, file, blockBlobURL,
            { progress: (progressEvent) => {
              //TransferProgressEvent
                  // eslint-disable-next-line no-console
                  this.uploadProgress = (progressEvent.loadedBytes*100) / file.size;
             }
            }));
        }
        await Promise.all(promises);
         // eslint-disable-next-line no-unused-vars
        for (const file of this.fileInput.files) {
            // eslint-disable-next-line no-undef
            const blockBlobURL = azblob.BlockBlobURL.fromContainerURL(this.containerURL, file.name);
            // Notify ecm about uploaded file
            this.sendUploadNotification(this.currentSession,file.name,blockBlobURL.url);
            this.htmlmessage += "<br><a href=\""+blockBlobURL.url+"\">"+file.name+"</a>"
        }
        var d = new Date();
        this.$http
          .post("/api/supportsessionpublish", { "session_id": this.currentSession, "timestamp": d.toISOString().split('.')[0]+"Z","name":this.currentSession+"-message-"+d.toISOString() ,"msgtype":"mail","json":"{\"subject\":\""+this.subject+"\",\"message\":\""+this.htmlmessage+"\"}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.supportMessages = response.data.data;
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
        // eslint-disable-next-line no-console
       this.uploadProgress = 0.0;
       this.$store.commit('setBusyState',false); 
      } catch (error) {
        this.$store.commit('setBusyState',false);
          // eslint-disable-next-line no-console
          console.log("AZURE ERROR: " + error);
         UIkit.modal.alert("File upload failed");
      }
    };
  }
};
</script>

<style scoped lang="less">

</style>