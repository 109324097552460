<template>
<div class="uk-container">
  <div id="modal-edit-json" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Edit item</h2>
        <p>Edit item and save to database or delete it from the database.</p>
        <div>
         <textarea spellcheck="false" :rows="Math.min(jsontext.split(/\r\n|\r|\n/).length,40)" cols="50" v-model="jsontext" :style="{color: isJsonString(this.jsontext) ? 'inherit' : 'red'}"></textarea>
        </div>
        <div class="uk-margin-small-top uk-align-right">
        <button class="uk-button uk-button-default uk-margin-small-right" type="button"
          @click="hideEditModal"
        >
          Cancel
        </button>
        <button class="uk-button uk-button-primary uk-margin-small-right" type="button"
          :disabled="!isJsonString(this.jsontext)"
          @click="updateSelectedItem"
        >
          Save
        </button>
        <button class="uk-button uk-button-default" type="button"
          :disabled="!isJsonString(this.jsontext)"
          @click="deleteSelectedItem"
        >
          Delete
        </button>
      </div>
      </div>
  </div>

 <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
      <h1>Database  &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refresh">&#8635;</button></h1> 
  <form class="uk-form">
    <label class="uk-label uk-label-default uk-margin-small-top" for="database">Database</label>
    <select class="uk-select uk-width-1-1 uk-margin-small-right" name="database" ref="database" required="true" v-model="db" type="text" >
        <option  v-for="entry in available" :key="entry.database" :text="entry.database" :value="entry"></option>
    </select>
    <label class="uk-label uk-label-default uk-margin-small-top" for="collection">Collection</label>
    <select class="uk-select uk-width-1-1 uk-margin-small-right" ref="collection" type="text" v-model="collection">
         <option  v-for="colle in db.collections" :key="colle" :text="colle" :value="colle"></option>
    </select>
    <label class="uk-label uk-label-default uk-margin-small-top" for="qsearch" >Search</label>
    <input class="uk-input uk-width-1-1 uk-margin-small-right" ref="qsearch" type="text" placeholder='' v-model="searchstring" >
    <label class="uk-label uk-label-default uk-margin-small-top" for="qfilter" >Filter</label>
    <input class="uk-input uk-width-1-1 uk-margin-small-right" ref="qfilter" type="text" placeholder='{"key": value}' v-model="jsonfilter" :style="{color: isJsonString(this.jsonfilter) ? 'green' : 'red'}">
    <div class="uk-margin-small">
      <label class="uk-label uk-label-default" for="exclude" >Exclude matching</label>
      <input class="uk-checkbox uk-margin-small-right uk-margin-small-left uk-margin-remove-top" ref="exclude" type="checkbox" v-model="exclude" :disabled="this.jsonfilter=='' || !isJsonString(this.jsonfilter)">
    </div>
    <label class="uk-label uk-label-default uk-margin-small-top" for="skip">Skip</label>
    <input class="uk-input uk-width-1-1 uk-margin-small-right" ref="skip" type="number" v-model="skip" >
    <label class="uk-label uk-label-default uk-margin-small-top" for="maxcount">Max count</label>
    <input class="uk-input uk-width-1-1 uk-margin-small-right" ref="maxcount" type="number" v-model="maxcount" >
    <button class="uk-button uk-button-default uk-margin-small-top"  type="button" :hidden="skip==0" @click="skip=skip-maxcount;skip=skip<0?0:skip;updateContents()">&lt;&lt;</button>
    <button class="uk-button uk-button-default uk-margin-small-top"  type="button" @click="updateContents">Read</button>
    <button class="uk-button uk-button-default uk-margin-small-top"  type="button" :hidden="totalCount==0 || skip+maxcount>totalCount" @click="{skip=skip+maxcount;updateContents()}">&gt;&gt;</button>
  </form>
    <span v-for="num in '0'" :key="num" ></span>
    <input class="uk-input uk-width-1-1 uk-margin-small-right path" ref="path" type="text">
  <h2 v-if="this.collection.length > 0"> Collection: {{this.collection}}, total count: {{this.totalCount}}</h2>
  <pre ref="jsonrenderer"></pre>
 </div>
</div>
</template>

<script>
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

UIkit.use(Icons);
export default {
  name: "Json",
  props: {
    dataArray: String,
  },
  data() {
      return {
        db: {},
        collection : "",
        totalCount : 0,
        available: {},
        source : {},
        data : {},
        dest : [],
        targetList : [],
        options : {},
        selectedItem :{},
        jsonfilter:"",
        searchstring:"",
        exclude:false,
        jsontext:"",
        skip:0,
        maxcount:10,
      }
  },
  methods:{
    getAvailableContents() {
        const req = {"command": "contents", "argument": "{}"}      
        this.$http
        .post("/api/getraw", req, {
            timeout: 0,
            headers: {
            Authorization: this.getAuth()
            }
        })
        .then(response => {
            this.available = response.data.data.values;
            this.$store.commit("setContents", response.data.data.values);
            this.totalCount = 0;
            this.data = this.$store.state.appmessages.contents;
            this.clearJsonPathPicker(this.source);
            this.jsonPathPicker(this.source, this.data, this.dest,this.clickHandler,this.spanClickHandler, {
            outputWithQuotes: true,
            outputCollapsed: false,
            pathNotation: 'brackets',
            pathQuotesType: 'double'})
        })
        .catch({
            //console.log(error);
        });
    },
    isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return str.length==0;
    }
      return true;
    },
    updateContents() {
        const arg = { database: this.db.database, collection: this.collection, filter: this.jsonfilter,search: this.searchstring, exclude: this.exclude,skip: this.skip.toString(), maxcount: this.maxcount.toString() };
        //Validate the filter
        if(!this.isJsonString(this.jsonfilter)){
           UIkit.modal.alert("The query filter string is not valid Json.")
        }
        else{
          const req = {"command": "find", "argument": JSON.stringify(arg)}      
          this.$http
          .post("/api/getraw", req, {
              timeout: 0,
              headers: {
              Authorization: this.getAuth()
              }
          })
          .then(response => {
              this.$store.commit("setContents", response.data.data.values);
              this.totalCount = response.data.data.count;
              this.data = this.$store.state.appmessages.contents;
              this.clearJsonPathPicker(this.source);
              this.jsonPathPicker(this.source, this.data, this.dest,this.clickHandler,this.spanClickHandler, {
              outputWithQuotes: true,
              outputCollapsed: false,
              pathNotation: 'brackets',
              pathQuotesType: 'double'})
          })
          .catch({
              //console.log(error);
          });
        }
    },
    updateItem() {
        const arg = { database: this.db.database, collection: this.collection, updated: JSON.stringify(this.selectedItem) };
        const req = {"command": "update", "argument": JSON.stringify(arg)}      
        this.$http
        .post("/api/getraw", req, {
            timeout: 0,
            headers: {
            Authorization: this.getAuth()
            }
        })
        .then(response => {
          //Check for error
          if(response.data){
           this.updateContents();
          }
        })
        .catch({
            //console.log(error);
        });
    },
    deleteItem() {
        const arg = { database: this.db.database, collection: this.collection, updated: JSON.stringify(this.selectedItem) };
        const req = {"command": "delete", "argument": JSON.stringify(arg)}      
        this.$http
        .post("/api/getraw", req, {
            timeout: 0,
            headers: {
            Authorization: this.getAuth()
            }
        })
        .then(response => {
          //Check for error..
          if(response.data){
           this.updateContents();
          }
        })
        .catch({
            //console.log(error);
        });
    },
    refresh(){
      this.getAvailableContents();
    },
      /**
 * Check if arg is either an array with at least 1 element, or a dict with at least 1 key
 * @return boolean
 */
isCollapsable(arg) {
  return arg instanceof Object && Object.keys(arg).length > 0;
},
collapsableItems(arg) {
  return Object.keys(arg).length;
},
/**
 * Check if a string represents a valid url
 * @return boolean
 */
isUrl(string) {
  const regexp = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#:.?+=&%@!\-/]))?/;
  return regexp.test(string);
},

/**
 * Transform a json object into html representation
 * @return string
 */
json2html(json, options,level) {
  let html = '';
  if (typeof json === 'string') {
    // Escape tags
    const tmp = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    if (this.isUrl(tmp)) {
      html += `<a href="${tmp}" class="json-string">${tmp}</a>`;
    } else {
      html += `<span class="json-string">"${tmp}"</span>`;
    }
  } else if (typeof json === 'number') {
    html += `<span class="json-literal">${json}</span>`;
  } else if (typeof json === 'boolean') {
    html += `<span class="json-literal">${json}</span>`;
  } else if (json === null) {
    html += '<span class="json-literal">null</span>';
  } else if (json instanceof Array) {
    if (json.length == 1) {
      html += '[<span class="json-literal">';
      for (let i = 0; i < json.length; i += 1) {
        if (level == 0){
          html += `<span data-key-type="array" data-key="${i}" class="singleitemlist">`;
        }
        else{
          html += `<span data-key-type="array" data-key="${i}" class="json-array json-items">`;
        }
        // Add toggle button if item is collapsable
        if (this.isCollapsable(json[i])) {
          if (this.collapsableItems(json[i]) > 50) {
            html += '<a href class="json-toggle collapsed"></a>';
          }
          else{
            html += '<a href class="json-toggle"></a>';
          }
        }

        html += this.json2html(json[i], options,level+1);
        // Add comma if item is not last
        if (i < json.length - 1) {
          html += ',';
        }

        html += '</span>';
      }
      html += '</span>]';
    } else if (json.length > 1) {
      if(level > 0 && this.isCollapsable(json) && this.collapsableItems(json) > 50){
        html += '[<ol start="'+((level==0?this.skip:0)+1).toString()+'" class="json-array'+(level==0?' json-items':'')+'" style="display: none;">';
      }
      else{
        html += '[<ol start="'+((level==0?this.skip:0)+1).toString()+'" class="json-array'+(level==0?' json-items':'')+'">';
      }
      for (let i = 0; i < json.length; i += 1) {
        html += `<li data-key-type="array" data-key="${i}">`;
        // Add toggle button if item is collapsable
        if (this.isCollapsable(json[i])) {
          if (this.collapsableItems(json[i]) > 50) {
            html += '<a href class="json-toggle collapsed"></a>';
          }
          else{
            html += '<a href class="json-toggle"></a>';
          }
        }

        html += this.json2html(json[i], options,level+1);
        // Add comma if item is not last
        if (i < json.length - 1) {
          html += ',';
        }

        html += '</li>';
      }
      html += '</ol>]';
    } else {
      html += '[]';
    }
  } else if (typeof json === 'object') {
    let keyCount = Object.keys(json).length;
    if (keyCount > 1) {
      html += '{<ul class="json-dict" style="margin-top: 0px;margin-bottom: 0px;">';
      for (const key in json) {
        // eslint-disable-next-line no-prototype-builtins
        if (json.hasOwnProperty(key)) {
          html += `<li data-key-type="object" data-key="${key}">`;
          const keyRepr = options.outputWithQuotes
            ? `<span class="json-string">"${key}"</span>` : key;

          // Add toggle button if item is collapsable
          if (this.isCollapsable(json[key])) {
            if (this.collapsableItems(json[key]) > 50) {
              html += `<a href class="json-toggle collapsed">${keyRepr}</a>`;
            }
            else{
              html += `<a href class="json-toggle">${keyRepr}</a>`;
            }
          } else {
            html += keyRepr;
          }
          //html += '<span class="pick-path" title="Pick path">&' + options.pickerIcon + ';</span>';
          html += `: ${this.json2html(json[key], options,level+1)}`;
          // Add comma if item is not last
          keyCount -= 1;
          if (keyCount > 0) { html += ','; }
          html += '</li>';
        }
      }
      html += '</ul>}';
    } else if (keyCount == 1) {
      for (const key in json) {
         if(key=="$date"){
            html += '<span class="json-literal">';
            html += new Date(json[key]).toUTCString();
            html += '</span>';
          }
          else if(key=="$oid"){ //Open edit modal on click?
            html += '<span class="json-string">"';
            html += json[key];
            html += '"</span>';
          }
          else{
            html += '{ <span class="json-literal">';
            // eslint-disable-next-line no-prototype-builtins
            if (json.hasOwnProperty(key)) {
            html += `<span data-key-type="object" data-key="${key}">`;
            const keyRepr = options.outputWithQuotes
                ? `<span class="json-string">"${key}"</span>` : key;

            // Add toggle button if item is collapsable
            if (this.isCollapsable(json[key])) {
                if (this.collapsableItems(json[key]) > 50) {
                  html += `<a href class="json-toggle collapsed">${keyRepr}</a>`;
                }
                else{
                  html += `<a href class="json-toggle">${keyRepr}</a>`;
                }
            } else {
                html += keyRepr;
            }
            //html += '<span class="pick-path" title="Pick path">&' + options.pickerIcon + ';</span>';      
            html += `: ${this.json2html(json[key], options,level+1)}`;
            html += '</span>}';
          }
        }
      }
    } else {
      html += '{}';
    }
  }
  return html;
},

/**
 * Remove an event listener
 * @param  {String}   event    The event type
 * @param  {Node}     elem     The element to remove the event to (optional, defaults to window)
 * @param  {Function} callback The callback that ran on the event
 * @param  {Boolean}  capture  If true, forces bubbling on non-bubbling events
 */
off(event, elem, callback, capture) {
  let captureIntern = capture;
  let callbackIntern = callback;
  let elemIntern = elem;
  if (typeof (elem) === 'function') {
    captureIntern = callback;
    callbackIntern = elem;
    elemIntern = window;
  }
  captureIntern = !!captureIntern;
  elemIntern = typeof elemIntern === 'string' ? document.querySelector(elemIntern) : elemIntern;
  if (!elemIntern) return;
  elemIntern.removeEventListener(event, callbackIntern, captureIntern);
},

/**
 * Equivalent of JQuery $().siblings(sel) with callback features
 *
 * Retrieve all siblings/neighbors of a node
 * Usage:
 * - siblings(node, '.collapse', (sib) => { });
 * - const sibs = siblings(node);
 * - const sibs = siblings(node, '.collapse');
 *
 * @param {HTMLNode} el Element to apply siblings methods
 * @param {String} sel CSS Selector
 * @param {Function} callback (sib) => {}
 */
siblings(el, sel, callback) {
  const sibs = [];
  for (let i = 0; i < el.parentNode.children.length; i += 1) {
    const child = el.parentNode.children[i];
    if (child !== el && typeof sel === 'string' && child.matches(sel)) {
      sibs.push(child);
    }
  }

  // If a callback is passed, call it on each sibs
  if (callback && typeof (callback) === 'function') {
    for (let i = 0; i < sibs.length; i += 1) {
      callback(sibs[i]);
    }
  }

  return sibs;
},

/**
 * Fire a click handler to the specified node.
 * Event handlers can detect that the event was fired programatically
 * by testing for a 'synthetic=true' property on the event object
 * @param {HTMLNode} node The node to fire the event handler on.
 */
fireClick(node) {
  // Make sure we use the ownerDocument from the provided node to avoid cross-window problems
  let doc;
  if (node.ownerDocument) {
    doc = node.ownerDocument;
  } else if (node.nodeType === 9) {
    // the node may be the document itself, nodeType 9 = DOCUMENT_NODE
    doc = node;
  } else {
    throw new Error(`Invalid node passed to fireEvent: ${node.id}`);
  }

  if (node.dispatchEvent) {
    const eventClass = 'MouseEvents';
    const event = doc.createEvent(eventClass);
    event.initEvent('click', true, true); // All events created as bubbling and cancelable.

    event.synthetic = true;
    node.dispatchEvent(event, true);
  } else if (node.fireEvent) {
    // IE-old school style, you can drop this if you don't need to support IE8 and lower
    const event = doc.createEventObject();
    event.synthetic = true; // allow detection of synthetic events
    node.fireEvent('onclick', event);
  }
},

/**
 * Check if an element is visible or not
 * @param {HTMLNode} elem Element to check
 * @returns {Boolean}
 */
isHidden(elem) {
  const width = elem.offsetWidth;
  const height = elem.offsetHeight;

  return ((width === 0 && height === 0) || window.getComputedStyle(elem).display === 'none');
},

/**
 * Method use to retrieve parents of given element
 * @param {HTMLNode} elem Element which we want parents
 * @param {Strign} sel selector to filter parents (CSS selectors)
 * @returns {Array<HTMLNode>}
 */
getParents(elem, sel) {
  const result = [];
  for (let p = elem && elem.parentElement; p; p = p.parentElement) {
    if ( typeof sel === 'string' && p.matches(sel)) {
      result.push(p);
    }
  }
  return result;
},

HandlerEventToggle(elm, event) {
  // Change class
  elm.classList.toggle('collapsed');

  // Fetch every json-dict and json-array to toggle them
  const subTarget = this.siblings(elm, 'ul.json-dict, ol.json-array', (el) => {
    el.style.display = (el.style.display === '' || el.style.display === 'block') ? 'none' : 'block';
  });

  // ForEach subtarget, previous siblings return array so we parse it
  for (let i = 0; i < subTarget.length; i += 1) {
    if (!this.isHidden(subTarget[i])) {
      // Parse every siblings with '.json-placehoder' and remove them (previous add by else)
      this.siblings(subTarget[i], '.json-placeholder', el => el.parentNode.removeChild(el));
    } else {
      // count item in object / array
      const childs = subTarget[i].children; let count = 0;
      for (let j = 0; j < childs.length; j += 1) {
        if (childs[j].tagName === 'LI') { count += 1; }
      }
      const placeholder = count + (count > 1 ? ' items' : ' item');

      // Append a placeholder
      subTarget[i].insertAdjacentHTML('afterend', `<a href class="json-placeholder">${placeholder}</a>`);
    }
  }

  // Prevent propagation
  event.stopPropagation();
  event.preventDefault();
},
ToggleEventListener(event) {
  let t = event.target;
  while (t && t !== this) {
    if (t.matches && t.matches('a.json-toggle')) {
      this.HandlerEventToggle.call(null, t, event);
      event.stopPropagation();
      event.preventDefault();
    }
    t = t.parentNode;
  }
},

// Simulate click on toggle button when placeholder is clicked
SimulateClickHandler(elm, event) {
  this.siblings(elm, 'a.json-toggle', el => this.fireClick(el));

  event.stopPropagation();
  event.preventDefault();
},
SimulateClickEventListener(event) {
  let t = event.target;
  while (t && t !== this) {
    if (t.matches && t.matches('a.json-placeholder')) {
      this.SimulateClickHandler.call(null, t, event);
    }
    t = t.parentNode;
  }
},
PickPathHandler(elm) {
  if (this.targetList.length === 0) {
    return;
  }

  const $parentsList = this.getParents(elm, 'li').reverse();
  let pathSegments = [];
  for (let i = 0; i < $parentsList.length; i += 1) {
    let { key } = $parentsList[i].dataset;
    const { keyType } = $parentsList[i].dataset;

    if (
      keyType === 'object'
      && typeof key !== 'number'
      && this.options.processKeys
      && this.options.keyReplaceRegexPattern !== undefined
    ) {
      const keyReplaceRegex = new RegExp(
        this.options.keyReplaceRegexPattern,
        this.options.keyReplaceRegexFlags,
      );
      const keyReplacementText = this.options.keyReplacementText === undefined ? '' : this.options.keyReplacementText;
      key = key.replace(keyReplaceRegex, keyReplacementText);
    }

    pathSegments.push({
      key,
      keyType,
    });
  }

  const quotes = {
    none: '',
    single: '\'',
    double: '"',
  };

  const quote = quotes[this.options.pathQuotesType];

  pathSegments = pathSegments.map((segment, idx) => {
    const isBracketsNotation = this.options.pathNotation === 'brackets';
    const isKeyForbiddenInDotNotation = !/^\w+$/.test(segment.key) || typeof segment.key === 'number';

    if (segment.keyType === 'array' || segment.isKeyANumber) {
      return `[${segment.key}]`;
    } if (isBracketsNotation || isKeyForbiddenInDotNotation) {
      return `[${quote}${segment.key}${quote}]`;
    } if (idx > 0) {
      return `.${segment.key}`;
    }
    return segment.key;
  });

  const path = pathSegments.join('');

  for (let i = 0; i < this.targetList.length; i += 1) {
    if (this.targetList[i].value !== undefined) {
      this.targetList[i].value = path;
    }
  }
},
PickEventListener(event) {
  let t = event.target;
  while (t && t !== this) {
    if (t.matches && t.matches('.pick-path')) {
      this.PickPathHandler.call(null, t);
    }
    t = t.parentNode;
  }
},

// Uniq id generator
uuidv4() {
  function randomString(length, chars) {
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
  }
  return randomString(32, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
},
clickHandler(ev) {
    if (ev.target.tagName !== 'SPAN'){
      let i = 0;
      for (var el = ev.currentTarget.firstElementChild; el; el = el.nextElementSibling){
          el.className = el === ev.target ? 'selected' : '';
          if(el === ev.target){
            this.selectItem(i);
          }
          i = i+1;
      }
    }
},
spanClickHandler() {
    this.selectItem(0);
},
selectItem(i){
  this.selectedItem = this.data[i];
  this.jsontext = JSON.stringify(this.selectedItem,undefined,2);
  UIkit.modal("#modal-edit-json").show();
},
hideEditModal(){
  UIkit.modal("#modal-edit-json").hide();
  this.jsontext="";
},
deleteSelectedItem(){
  const newItem = JSON.parse(this.jsontext);
  if(JSON.stringify(newItem._id)==JSON.stringify(this.selectedItem._id)){
    UIkit.modal.confirm("Really delete?").then(() => {
       this.selectedItem = newItem;
       this.deleteItem();
       this.hideEditModal();
      },() => {});
  }
  else{
    UIkit.modal.alert(JSON.stringify(newItem._id)+ " not equal to "+JSON.stringify(this.selectedItem._id))
  }
},
updateSelectedItem(){
  const newItem = JSON.parse(this.jsontext);
  if(JSON.stringify(newItem._id)==JSON.stringify(this.selectedItem._id)){
    this.selectedItem = newItem;
    this.updateItem();
    this.hideEditModal();
  }
  else{
    UIkit.modal.alert(JSON.stringify(newItem._id)+ " not equal to "+JSON.stringify(this.selectedItem._id))
  }
},
/**
 * Plugin method
 * @param source: Element
 * @param json: a javascript object
 * @param target: NodeListOf<Element> | Element | { value: String }[] | { value: String }
 * @param opt: an optional options hash
 */
jsonPathPicker(source, json, target,clickhdler,spanclickhdler, opt) {
  this.options = opt || {};

  if (!(source instanceof Element)) {
    alert("Source is not an element")
    return 1;
  }

  if (target) {
    if (target.length) {
      this.targetList = target;
    } else if (target.value) {
      this.targetList = [target];
    } else {
      alert("Target not a list")  
      return 3;
    }
  } else {
    alert("No target")
    return 3;
  }

  // Add to source unique identifier
  const uuid = this.uuidv4();
  source.id = source.id ? `${source.id} ${uuid}` : uuid;
  source.setAttribute('data-jsonpath-uniq-id', uuid);

  this.options.pathQuotesType = this.options.pathQuotesType !== undefined ? this.options.pathQuotesType : 'single';

  // Transform to HTML
  this.options.pickerIcon = this.options.pickerIcon || '#x1f4cb';
  let html = this.json2html(json, this.options,0);
  if (this.isCollapsable(json)) {
    html = `<a href class="json-toggle"></a>${html}`;
  }
  
  // Insert HTML in target DOM element
  source.innerHTML = html;

  // Bind click on toggle buttons
  this.off('click', source);
  source.addEventListener('click', this.ToggleEventListener);
  source.addEventListener('click', this.SimulateClickEventListener);

  // Bind picker only if user didn't diseable it
  if (!this.options.WithoutPicker) {
    source.addEventListener('click', this.PickEventListener);
  } else {
    // Remove every picker icon
    const sourceSelector = source.getAttribute('data-jsonpath-uniq-id'); // Prevent affect other jp-picker
    document.querySelectorAll(`[id*='${sourceSelector}'] .pick-path`).forEach(el => el.parentNode.removeChild(el));
  }

  if (this.options.outputCollapsed === true) {
    // Trigger click to collapse all nodes
    const elms = document.querySelectorAll('a.json-toggle');
    for (let i = 0; i < elms.length; i += 1) {
      this.fireClick(elms[i]);
    }
  }

  //Bind event handler to selection
  [].slice.call (document.querySelectorAll ('ol.json-items')).forEach (function (list) {
    // eslint-disable-next-line no-unused-vars
    for (var s, el = list.firstElementChild; el; el = el.nextElementSibling)
      el.innerHTML = '&#9998;<span>' + el.innerHTML + '</span>';

    list.addEventListener ('click', clickhdler, false);    
});

[].slice.call (document.querySelectorAll ('span.singleitemlist')).forEach (function (list) {
    // eslint-disable-next-line no-unused-vars
     var el = list.firstElementChild
     el.innerHTML = '&#9998;<span>' + el.innerHTML + '</span>';

    list.addEventListener ('click', spanclickhdler, false);    
});
},
/**
 * Plugin clear method
 * @param source: Element
 */
clearJsonPathPicker(source) {
  if (!(source instanceof Element)) {
    return 1;
  }

  //Remove event listener
  source.removeEventListener('click', this.PickEventListener);
  source.removeEventListener('click', this.ToggleEventListener);
  source.removeEventListener('click', this.SimulateClickEventListener);
},
/*
module.exports = {
  jsonPathPicker,
  clearJsonPathPicker
}*/
 getAuthJson(){
      return (localStorage.getItem("token")!=null && localStorage.getItem("token").length > 0)?
              "Basic ".concat(localStorage.getItem("token") )
            :((localStorage.getItem("bearerjwt")!=null && localStorage.getItem("bearerjwt").idToken )?
              "Bearer ".concat(localStorage.getItem("bearerjwt").idToken)
              : null);
    }
  },
  created() {
      this.$nextTick(() => {
        this.source = this.$refs.jsonrenderer;
        this.dest = [this.$refs.path];
        this.getAvailableContents();
      });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: opacity 0.2s;
}

h1 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h2 {
  font-size: 1.17em;
  margin: 1em 0;
}

pre {
  margin: 0;
}

.json-input {
  border: none;
  resize: none;
  border-bottom: 1px solid lightgray;
  padding: 10px 30px;
  width: 100%;
  height: 200px;
}

.loading {
  opacity: 0;
}

.sidebar {
  background-color: whitesmoke;
  width: 270px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 40px;
  box-shadow: 0 0 5px gray;
  padding: 0 30px 20px 30px;
  overflow: auto;
}

.sidebar .label {
  display: block;
}

.content {
  margin: 0 0 40px 270px;
}

.generate-button {
  border: none;
  color: white;
  background-color: #65265b;
  border-radius: 2px;
  font-size: 16px;
  height: 30px;
  width: 100%;
}

.json-tree {
  padding: 10px 30px;
}

.path-container {
  z-index: 1;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  box-shadow: 0 0 5px gray;
}

.path {
  height: 100%;
  width: 100%;
  padding: 0 20px;
  border: none;
  font-family: monospace;
}

.selected { color: red; }

textarea {
    width: 100%;
    height: 100%; 
    box-sizing: border-box;
}

* + .json-dict {
  margin: 0;
}

ol.json-array.json-items {
  color: red;
  /* delete default counter */
  list-style-type: none;
  /* create custom counter and set it to 0 */
  counter-reset: elementcounter;
}

ol.json-array.json-items li:before {
  /* print out "Element " followed by the current counter value */
  content: "Element " counter(elementcounter) ": ";
  /* increment counter */
  counter-increment: elementcounter;
}

</style>