<template>
  <div class="uk-container">
    <input
      type="file"
      id="file-input"
      multiple
      style="display: none;"
      @change="uploadFiles"
    />
    <div id="editProjectModal" uk-modal>
       <div class="uk-modal-dialog uk-modal-body">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
            <h2 class="uk-modal-title">Allocate session to project:</h2>
            <p>Administrators can create projects with associated file sharing and control access using the subscription administration page.</p>
        </div>
        <div class="uk-modal-body">
         <h3>Existing projects</h3> 
         <select class="uk-select"
          :value="selectedProject"
          v-model="selectedProject"
        >
          <option
            v-for="opt in this.allProjects"
            :key="opt.value"
            :value="opt.value"
            :selected="selectedProject==opt.value"
          >
            {{ opt.text }}
          </option>
        </select>
        <h3>Project for this session</h3>
        <label for="includesim">Include simulations</label>
        <input name="includesim" v-model="include_simulations" class="uk-checkbox uk-margin-left" type="checkbox"/>
        <input class="uk-input"
          v-model="selectedProject"
        > 
        </div>
        <div class="uk-modal-footer uk-text-right">
          
         <button class="uk-button uk-button-default uk-margin-small-right"
          floated="right"
          :disabled="selectedProject==''"
          positive
          @click="updateProject"
        >
          Set project
        </button>
        <button class="uk-button uk-button-default"
          floated="right"
          negative
          @click="hideEditProjectModal"
        >
          Cancel
        </button>
        </div>
      </div>
    </div>
    <div class="uk-container">
      <h1>Sessions</h1>
      <PoolStatus/>
      <div  v-if="this.sessions.length > 0 || (this.rdpsession && this.rdpsession.user)">
        <hr>
        <table class="uk-table uk-table-middle">
          <tbody>
            <tr>
              <h3>Sessions &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="updateSessions">&#8635;</button></h3>
              <p>Ongoing sessions and their status are shown. Sessions can be disconnected or terminated.</p>
            </tr>
            <span v-if="this.rdpsession && this.rdpsession.user">
            <tr class="uk-align-left">
              <span class="uk-margin-small">
               Current session ( Session CPU time: {{ Math.round(this.total_session_time) }} s, Simulation CPU time: {{ Math.round(this.total_simulation_time) }} s )
             </span>
                <div class="">User: {{rdpsession.user}}, Status: {{rdpsession.connectionStatus}}, Host: {{rdpsession.machine}}</div>
            </tr>
            <tr>   
                <div class="uk-align-left"> 
                  <button class="uk-button uk-button-default" floated="right" uk-icon="sign-out" @click="rdsLogoff(rdpsession)"> Stop ICE Session</button>
                  <button class="uk-margin-left uk-button uk-button-default" floated="right" uk-icon="bolt" @click="rdsDisconnect(rdpsession)"> Disconnect Client</button>
                  <!--<Button icon="comment outline icon" @click="rdsMessage(rdpsession)"> Message</button>-->
                </div>
              
              
            </tr>
            </span>
            <hr v-if="sessions.length > 0">
            <tr
              v-for="s in sessions"
              :key="s.id"       
            >
              <div >                
                <h3>Files</h3>
                <p>Files transfered to and from the active IDA ICE sessions are listed. Files can be uploaded to the IDA ICE Projects folder and downloaded. Progress of ongoing file transfers are shown.</p>
                    <h4 v-if="s.frozen" class="uk-text-warning uk-text-center">
                      <span uk-icon="warning"
                        color="red"
                      ></span>
                      IDA ICE is not responding 
                    </h4>
                  <table class="uk-table uk-table-divider">
                  <thead v-if="s.messages.length > 0" >
                        <tr class="uk-table-row"
                          v-for="mess in s.messages.filter((m)=>{return m.msgtype=='container'})"
                          :key="mess.name"
                          
                        >
                        <th  style="text-align:left;" colspan="3">
                        <button class="uk-button uk-button-default uk-text-center uk-margin-bottom" uk-icon="cloud-upload"  @click="selectAndUploadFiles(s,mess)">
                            Upload files .
                        </button>
                        
                          <progress class="uk-progress" style="width:100%" floated="left"
                            v-if="uploadProgress > 0.0 && uploadProgress < 100.0" 
                            :value="uploadProgress"
                            max = 100
                          ><label>{{uploadFile + Math.round(uploadProgress)+' %'}}</label>  </progress>
                          
                          </th>
                        </tr>
                    
                    <tr v-if="s.messages.filter((m)=>{return m.msgtype!='container'}).length > 0"><th>Type</th><th>Status</th><th>Delete</th></tr>
                    
                  </thead>
                     <tbody>
                     
                      <tr
                        v-for="mess in s.messages.filter((m)=>{return m.msgtype=='upload'})"
                        :key="mess.name"
                      >
                      <td>
                      <div uk-icon="icon: file-text"/>
                        </td>
                        <td>
                        <progress class="uk-progress" 
                          v-if="!mess.delivered && mess.progress < 100.0" 
                          :value="mess.progress"
                          max="100"
                          :label="mess.name + ' ' + Math.round(mess.progress)+' %'"
                        />
                        
                        <span  v-else>{{ mess.name }} transferred at {{ mess.timestamp }} <span class="uk-margin-left" uk-icon="cloud-upload"></span></span>
                        </td>
                        <td>
                        <button class="uk-button uk-button-default" type="button" uk-icon="icon: trash" @click="removeMessage(s,mess)"></button>
                        </td>
                      </tr>
                      <tr 
                        v-for="mess in s.messages.filter((m)=>{return m.msgtype=='excel'})"
                        :key="mess.name"
                      >
                      <td>
                        Excel
                        </td>
                        <td>
                        <a
                          :href="'https://view.officeapps.live.com/op/view.aspx?src='+ encodeURIComponent(JSON.parse(mess.json).url)"
                          target="_"
                        >
                          {{ mess.name.substring(s.id.length+1) }} <span uk-icon="link"></span>
                        </a>
                        </td>
                        <td>
                        <button class="uk-button uk-button-default" type="button" uk-icon="icon: trash" @click="removeMessage(s,mess)"></button>
                        </td>
                      </tr>
                      <tr
                        v-for="mess in s.messages.filter((m)=>{return m.msgtype!='container' && m.msgtype!='upload' && m.msgtype!='excel'})"
                        :key="mess.name" 
                      >
                      <td>
                      <div uk-icon="icon: file-text"/>
                        </td>
                        <td>
                        <a
                          :href="JSON.parse(mess.json).url"
                          target="_"
                        >
                          {{ mess.name.substring(s.id.length+1) }} <span uk-icon="icon: download"></span>
                        </a>
                        </td>
                        <td>
                        <button class="uk-button uk-button-default" type="button" uk-icon="icon: trash" @click="removeMessage(s,mess)"></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
 
                  <div v-if="s.ended.length === 0 && s.simulations.filter((m)=>{return m.endReason=='running'}).length !== 0">
                   <hr>
                   <h3>Simulations</h3>
                   <p>Progress of ongoing IDA ICE simulations are shown and notifications on their completion via e-mail can be requested.</p>
                    <ul uk-accordion
                    >
                      <li v-bind:class="[sim.endReason=='running' ? 'uk-open' : '']"
                        v-for="sim in s.simulations.filter((m)=>{return m.endReason=='running'})"
                        :key="sim.simid"
                        :uk-tooltip="'title: <div>Customer: '+ sim.info.customer+'<br>Engineer: '+sim.info.engineer+'<br>Description:<div>'+(sim.info.description ?? '').replace(/(?:\r\n|\r|\n)/g, '<br>')+'</div></div>; pos: top-left'"
                      >
                            <a class="uk-accordion-title uk-text-small" href="#">
                              {{ sim.model }} : {{ sim.simType }} <span v-if="sim.taskid!==''">
                              - Task: {{ sim.taskid }}
                            </span></a>
                            <div class="uk-accordion-content">
                            <span>Status:
                            <span>{{ sim.endReason }}</span>  <span>( {{ sim.simulationSeconds }} seconds )</span></span>
                            <progress class="uk-progress" 
                              v-if="sim.endReason==='running'" 
                              :value="sim.progress"
                              max=1 
                            >
                            </progress>
                            
                            <span v-if="sim.notifyMe===''">
                              <button class="uk-button uk-button-default" 
                                v-if="sim.endReason==='running'"
                                compact
                                positive
                                :loading="sim.loading"
                                @click="requestNotification(s,sim,'mail')"
                              >
                                Notify when done!
                              </button>                 
                            </span>
                            <span v-else>
                              <button class="uk-button uk-button-default" 
                                v-if="sim.endReason==='running'"
                                compact
                                negative
                                :loading="sim.notifyMe==='loading'"
                                @click="requestNotification(s,sim,'')"
                              >
                                Cancel notification!
                              </button>
                            </span>
                          </div>
                      </li>
                    </ul>
                    <div v-if="s.ended.length > 0">
                      Ended {{ new Date(s.ended).toLocaleString() }}
                    </div>
                  </div>
                </div>
                <div>
                  <hr>
                  <h3>Summary</h3>
                   <p>Simulations performed during the current session are listed and summarized. The session can be allocated to a project for book-keeping.</p>
                    <div class="uk-h3 uk-text-left">
                      Project {{ s.project }} 
                        <button class="uk-button uk-button-default uk-button-small uk-margin-small-left"
                          uk-icon="pencil"
                          @click="editProject(s)"
                        />
                    </div>
                    <div class="uk-card uk-card-body uk-card-small uk-padding-remove">
                   <table class="uk-table uk-table-striped" uk-table>
                    <thead>
                          <th>Started</th>
                          <th>Duration</th>
                          <th>Session CPU time:</th>
                          <th>Simulation CPU time:</th>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="uk-padding-remove-top uk-padding-remove-bottom">{{ new Date(s.started).toLocaleString() }},</td>
                          <td class="uk-padding-remove" v-if="s.duration">{{ Math.round(100*Math.round(s.duration/1000)/60.0/60)/100 }} hours</td>
                          <td class="uk-padding-remove" v-else></td>
                          <td class="uk-padding-remove">{{ Math.round(s.sessionSeconds) }} s.</td>
                          <td class="uk-padding-remove">{{ Math.round(s.total_simulation_time) }} s.</td>
                          </tr>
                        </tbody>

                   </table>
                  <ul class="uk-list" uk-accordion>
                  <li>
                    <a class="uk-accordion-title uk-button uk-button-text uk-text-small" href="#">                       
                    Simulations
                    </a>
                  <div :active="s.ended.length === 0" class="uk-accordion-content">
                    <div class="uk-container"
                      :hidden="s.simulations.length === 0"
                    >
                    <table class="uk-table-small uk-table-striped" uk-table>
                      <thead>
                        <th>Project</th>
                        <th>Model</th>
                        <th>Simulation type</th>
                        <th>Status</th>
                        <th>Time</th>
                        <th>Started</th>
                        <th>Ended</th>
                      </thead>
                      <tbody>
                      <tr :uk-tooltip="'title: <div>Customer: '+ sim.info.customer+'<br>Engineer: '+sim.info.engineer+'<br>Description:<div>'+(sim.info.description ?? '').replace(/(?:\r\n|\r|\n)/g, '<br>')+'</div></div>; pos: top-left'"
                        v-for="sim in s.simulations.slice().reverse()"
                        :key="sim.simid"
                      >
                        <td class="uk-padding-remove-top uk-padding-remove-bottom">
                             {{ sim.projectname }}
                        </td>
                        <td class="uk-padding-remove">
                             {{ sim.model }}
                        </td>
                        <td class="uk-padding-remove">      
                              {{ sim.simType }} <span v-if="sim.taskid!==''"> - Task: {{ sim.taskid }} </span>
                        </td>
                        <td class="uk-padding-remove">
                             {{ sim.endReason }}
                        </td>      
                        <td class="uk-padding-remove">{{ sim.simulationSeconds }} seconds</td>
                        <td class="uk-padding-remove">{{ sim.started }}</td>
                        <td class="uk-padding-remove">{{ sim.ended }}</td>
                      <td class="uk-padding-remove">
                        <progress class="uk-progress" 
                              
                              :value="sim.progress"
                              max=1 
                            >
                        </progress>
                      </td>
                      </tr>
                      </tbody>
                      </table>
                    </div>
                  </div>
                  </li>
                  </ul>
                  </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="uk-margin" v-else>
        <h1 class="uk-h3">You have no running IDA ICE sessions&nbsp;<button class="uk-button uk-button-default uk-text-large" @click="updateSessions">&#8635;</button></h1>
      </div>
    </div>
    <div>
      <div class="">
        <hr>
        <h3>Session history</h3>
        <p>Previos sessions can be listed and the project metadata can be edited.</p>
            <div  v-if="this.history.length>0">
                <button class="uk-button uk-button-default" 
                  uk-icon="refresh"
                  @click="updateHistory()"
                >Refresh</button>
            </div>
            <div v-else>
                <button class="uk-button uk-button-primary"
                  uk-icon="download"
                  @click="updateHistory()"
                >Load history </button>
            </div>
            <div class="uk-margin-small">
            <span class="uk-margin-small" v-if="this.history.length>0">
              Finished ( Total session CPU time: {{ Math.round(this.total_session_time_history) }} s, total simulation CPU time: {{ Math.round(this.total_simulation_time_history) }} s )
            </span></div>
          
            <div
              v-for="s in history"
              :key="s.id"
              :positive="s.ended.length > 0"       
            >
              <div class="uk-card uk-card-default uk-card-hover">
                    <div class="uk-card-header uk-h3 uk-text-middle">
                      Project {{ s.project }} 
                        <button
                        class="uk-button uk-button-default uk-button-small uk-margin-small-left"
                          uk-icon="pencil"
                          @click="editProject(s)"
                        />
                    </div>
              
                    <div class="uk-card uk-card-body uk-card-small uk-padding-remove">
                      
                      <table class="uk-table uk-table-striped">
                        <thead>
                          <th>Started</th>
                          <th>Ended</th>
                          <th>Duration</th>
                          <th>Session CPU time:</th>
                          <th>Simulation CPU time:</th>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="uk-padding-remove-top uk-padding-remove-bottom">{{ new Date(s.started).toLocaleString() }},</td>
                          <td class="uk-padding-remove" v-if="s.ended.length > 0">{{ new Date(s.ended).toLocaleString() }}</td>
                          <td class="uk-padding-remove" v-if="s.duration">{{ Math.round(100*Math.round(s.duration/1000)/60.0/60)/100 }} hours</td>
                          <td class="uk-padding-remove">{{ Math.round(s.sessionSeconds) }} s.</td>
                          <td class="uk-padding-remove">{{ Math.round(s.total_simulation_time) }} s.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  <ul class="uk-card-footer" uk-accordion>
                    <li>
                      <a class="uk-accordion-title uk-button uk-button-text uk-text-small uk-margin" href="#">                       
                      Simulations
                      </a>
                      <div :active="s.ended.length === 0" class="uk-accordion-content">
                        <div class="uk-container"
                          :hidden="s.simulations.length === 0">
                          <table class="uk-table uk-table-striped">
                            <thead>
                              <th>Project</th>
                              <th>Model</th>
                              <th>Simulation type</th>
                              <th>Status</th>
                              <th>Time</th>
                              <th>Started</th>
                              <th>Ended</th>
                            </thead>
                            <tbody>
                            <tr :uk-tooltip="'title: <div>Customer: '+ sim.info.customer+'<br>Engineer: '+sim.info.engineer+'<br>Description:<div>'+(sim.info.description ?? '').replace(/(?:\r\n|\r|\n)/g, '<br>')+'</div></div>; pos: top-left'"
                              v-for="sim in s.simulations.slice().reverse()"
                              :key="sim.simid"
                            >
                              <td class="uk-padding-remove-top uk-padding-remove-bottom">
                                  {{ sim.projectname }}
                              </td>
                              <td class="uk-padding-remove">
                                  {{ sim.model }}
                              </td>
                              <td class="uk-padding-remove">      
                                    {{ sim.simType }} <span v-if="sim.taskid!==''"> - Task: {{ sim.taskid }} </span>
                              </td>
                              <td class="uk-padding-remove">
                                  {{ sim.endReason }}
                              </td>      
                              <td class="uk-padding-remove">{{ sim.simulationSeconds }} seconds</td>
                              <td class="uk-padding-remove">{{ sim.started }}</td>
                              <td class="uk-padding-remove">{{ sim.ended }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </li>
                  </ul>
              </div>
            </div>
      </div>
    </div>
  </div>
</template>
<script>
import PoolStatus from "@/components/PoolStatus.vue";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
UIkit.use(Icons);

export default {
  name: "CloudSessions",
  components: {
    PoolStatus
  },
  data() {
    return {
      total_time: 0,
      sessions: [],
      history: [],
      messages: [],
      fileShares:[],
      sessionsTimer: {},
      currentSession: {},
      selectedProject: "default",
      containerURL:"",
      fileInput : {},
      uploadFiles :{},
      uploadProgress: 10.0,
      uploadFile:"",
      rdpsession:{},
      include_simulations:true,
    };
  },
  computed: {
   allProjects : function(){
     var projectOptions=Array.from(new Set((this.sessions.concat(this.history)).map((s)=> s.project)));//
     projectOptions= projectOptions.concat(this.fileShares).filter(this.onlyUnique);
     var projectHistoryOptions=Array.from(projectOptions.map((s)=> { var newObj = {}; newObj["text"] = s; newObj["value"]=s;return newObj;}))
         .sort(function(a,b){return (a.text.toLowerCase() > b.text.toLowerCase())?1:((a.text.toLowerCase() < b.text.toLowerCase())?-1:0)});
    //this.fileShares
     return projectHistoryOptions
   }
  },
  methods: {
    //"sessionupdates" in service worker?
    selectAndUploadFiles(session,mess){
      //`https://${accountName}.blob.core.windows.net/${containerName}?${sasString}`
      this.currentSession = session;
       // eslint-disable-next-line no-undef
      this.containerURL = new azblob.ContainerURL(JSON.parse(mess.json).url,azblob.StorageURL.newPipeline(new azblob.AnonymousCredential));
      this.fileInput = document.getElementById("file-input");
      if(this.fileInput){
        this.fileInput.click();
      }
    },
    requestNotification(sess,sim,notificationType) {
      for (var i = 0; i < this.sessions.length; i++) {
        if (this.sessions[i].started === sess.started) {
           for (var j = 0; j < this.sessions[i].simulations.length; j++) {
             if (this.sessions[i].simulations[j].started === sim.started) {
               sim.notifyMe='loading'
             }
           }
        }
      }
      const req = { simid: sim.simid , msgType: notificationType};
      //disable button
        this.$http
          .post("/api/notifyme", req, {
            timeout: 0,
            headers: {
              Authorization: this.getAuth()
            }
          })
          .then( {
            //console.log(response);
          })
          .catch({
            //console.log(error);
          });      
    },
    updateFileShares(){
      if(this.getAuth()!=null){
        this.$http
          .get("/api/share", {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.fileShares = Array.from(response.data.data);
             }
             else if(response.data) {
              this.$store.commit('addNegativeMessage',response.data.msg); 
             }
            
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
      }
    },
    updateSessions() {
      if(this.getAuth()!=null){
        this.$http
          .get("/api/sessions", {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addSessionMessage',response.data.msg);
              this.sessions = Array.from(response.data.data);
              
             // if(!this.rdpsession){
             //   this.rdpsession={};
              this.rdsSession();
              //}
              this.calculateSessionTimes();
             }
             else if(response.data){
              this.$store.commit('addNegativeMessage',response.data.msg); 
             }
            clearTimeout(this.sessionsTimer)
            var span = 10000;
            //if(this.$store.state.appmessages.isBusy > 0){
            //  span = 10000;
            //}
            this.sessionsTimer=setTimeout(this.updateSessions,span);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
            this.total_time = "-1"
            //clearTimeout(this.sessionsTimer)
            //this.sessionsTimer=setTimeout(this.updateSessions, 60000);
          });
      }
      else{
        console.log("No auth?");
      }
    },
    fetchNdjson(resource, init, resultfn) {
      function NdjsonReader(reader) {
        const that = this;
        this.decoder = new TextDecoder();
        this.buf = ""; 
        reader.read().then(function ndjsonproc(result) {
            if (result.done) {
                that.nextfn(JSON.parse(that.buf));
                return;
            }
            that.buf = that.decoder.decode(
                         result.value, { stream: true }); 
            const lines = that.buf.split(/[\r\n](?=.)/);
            const ln = lines.length - 1;
            that.buf = lines[ln];

            for (let li = 0; li <= ln; li++) {
                that.nextfn(JSON.parse(lines[li]));
            }
            return reader.read().then(ndjsonproc);
        });
        this.next = function(fn) {
            this.nextfn = fn; 
        }
      }
      fetch(resource, init).then(
            resp => resp.body.getReader()
        ).then(reader => {
            (new NdjsonReader(reader)).next(result => {
                resultfn(result);
            });
        }).catch(console.error);
    },
    monitorSession(sessid) {
       this.fetchNdjson("/api/sessionupdates?sessionid=IDA-ICE."+sessid+".>",
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept-Encoding': 'chunked',
          },
        }, result => {
        console.log(result);
        if(result.data){
          //What type of data did we get?
          //this.sessions = Array.from(result.data);
          this.calculateSessionTimes();
        }
      });
    },
    onlyUnique(value, index, self) { 
       return self.indexOf(value) === index;
    },
    editProject(session){
      this.currentSession = session;
      this.selectedProject = session.project;
      this.updateFileShares();
      this.allProjects;
      UIkit.modal("#editProjectModal").show();
    },
    hideEditProjectModal(){
      UIkit.modal("#editProjectModal").hide();
    },
    updateProject(){
      if(this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        this.hideEditProjectModal();
        this.$http
          .post("/api/project", {"session_id": this.currentSession.id, "projectname": this.selectedProject,"include_simulations":this.include_simulations, "finished": !!this.currentSession.finished },{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              if(this.currentSession.finished){
                this.history = response.data.data;
                this.calculateHistoryTimes();
              }
              else{
                this.sessions = Array.from(response.data.data);
                this.calculateSessionTimes();
              }
             }
             else if(response.data) {
               this.$store.commit('addNegativeMessage',response.data.msg);
             }
             this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
      }
    },
    sendUploadNotification(session,name,url){
      if(this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        var d = new Date();
        this.$http
          .post("/api/cloudsessionpublish", { "session_id": session.id, "timestamp": d.toISOString().split('.')[0]+"Z","name": name ,"msgtype":"upload","json":"{\"url\":\""+url+"\"}"},{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
             }
             else if(response.data){
               this.$store.commit('addNegativeMessage',response.data.msg);
             }
             this.$store.commit('setBusyState',false);
             this.updateSessions();
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
      }
    },
    removeMessage(session,message){
    if(this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        this.$http
          .post("/api/removemessage", { "session_id": session.id, "name": message.name },{
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              message.removing=true;
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.updateSessions();
             }
             else if(response.data) {
               this.$store.commit('addNegativeMessage',response.data.msg);
             }
             this.$store.commit('setBusyState',false);
          })
          .catch(() => {
            //Notify user on error...
            this.$store.commit('setBusyState',false);
          });
      }
    },
    updateHistory() {
      if(this.getAuth()!=null){
      this.$http
        .get("/api/history", {
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response.data){
            this.history = response.data.data;
            this.calculateHistoryTimes();
          }
        })
        .catch(() => {
          this.total_time = "-1"
        });
      }
    },
    calculateSessionTimes(){
      this.total_session_time = 0.0;
      this.total_simulation_time = 0.0;
      for (var i = 0; i < this.sessions.length; i++) {
        this.sessions[i].total_simulation_time = 0.0;
        for (var j = 0; j < this.sessions[i].simulations.length; j++) {
          this.sessions[i].total_simulation_time = this.sessions[i].total_simulation_time + this.sessions[i].simulations[j].simulationSeconds;
        }
        this.total_simulation_time = this.total_simulation_time + this.sessions[i].total_simulation_time;
        this.total_session_time = this.total_session_time + this.sessions[i].sessionSeconds;
        if(this.sessions[i].ended){
          this.sessions[i].duration = new Date(this.sessions[i].ended) - new Date(this.sessions[i].started)
        }
        else{
          this.sessions[i].duration = Date.now() - new Date(this.sessions[i].started)
        }
      }
    },
    calculateHistoryTimes(){
        this.total_session_time_history = 0.0;
        this.total_simulation_time_history = 0.0;
        for (var i = 0; i < this.history.length; i++) {
          this.history[i].total_simulation_time = 0.0;
          for (var j = 0; j < this.history[i].simulations.length; j++) {  
            this.history[i].total_simulation_time = this.history[i].total_simulation_time + this.history[i].simulations[j].simulationSeconds;
          }
          this.total_simulation_time_history = this.total_simulation_time_history + this.history[i].total_simulation_time;
          this.total_session_time_history = this.total_session_time_history + this.history[i].sessionSeconds;
          if(this.history[i].ended){
            this.history[i].duration = new Date(this.history[i].ended) - new Date(this.history[i].started)
          }
          else{
            this.history[i].duration = Date.now() - new Date(this.history[i].started)
          }
        }
    },
    rdsSession(){
      if(this.getAuth()!=null){
        //this.$store.commit('setBusyState',true);
        this.$http.get("/api/rdpsession",{
            headers: {
              Authorization: this.getAuth()
            }
          })
          .then(response => {
             this.$store.commit('setBusyState',false);
            if(response && response.data){
              if(!response.data.error){
                this.rdpsession = response.data.data;
                this.$store.commit('addPositiveMessage',response.data.msg);
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
            }
          }).catch(e => {
            //this.$store.commit('setBusyState',false);
          // eslint-disable-next-line no-console
              console.log(e);
          })
      }
    },
    rdsDisconnect(rdsSession){
        if(this.getAuth()!=null){
          this.$store.commit('setBusyState',true);
          this.$http.post("/api/rdpsession",{ "sessionId": rdsSession.sessionId, "machine": rdsSession.machine,"command": "disconnect", "argument": "{}"},{
            headers: {
              Authorization: this.getAuth()
            }
          })
          .then(response => {
            if(response.data){
              if(!response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
            }
            this.$store.commit('setBusyState',false);
            this.rdsSession();
          }).catch(e => {
          // eslint-disable-next-line no-console
              console.log(e);
              this.$store.commit('setBusyState',false);
          })
          this.rdpsession=undefined;
        }
    },
    rdsLogoff(rdsSession){
        if(this.getAuth()!=null){
          this.$store.commit('setBusyState',true);
          this.$http.post("/api/rdpsession",{ "sessionId": rdsSession.sessionId, "machine": rdsSession.machine,"command": "logoff", "argument": "{}"},{
            headers: {
              Authorization: this.getAuth()
            }
          })
          .then(response => {
            if(response.data){
              if(!response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
              }
              else{
                this.$store.commit('addNegativeMessage',response.data.msg);
              }
            }
            this.$store.commit('setBusyState',false);
            this.rdsSession();
          }).catch(e => {
          // eslint-disable-next-line no-console
              console.log(e);
              this.$store.commit('setBusyState',false);
          })
          this.rdpsession=undefined;
        }
    },
    rdsMessage(rdsSession){
        var msg ="";
        UIkit.modal.prompt('Message',msg).then(function(msg){
          if(msg && this.getAuth()!=null){
            this.$store.commit('setBusyState',true);
            this.$http.post("/api/rdpsession",{ "sessionId": rdsSession.sessionId, "machine": rdsSession.machine,"command": "message", "argument": "{\"title\":\" ECS Message\",\"message\":\""+msg+"\"}"},{
              headers: {
                Authorization: this.getAuth()
              }
            })
            .then(response => {
              if(response.data){
                if(!response.data.error){
                  this.$store.commit('addPositiveMessage',response.data.msg);
                }
                else{
                  this.$store.commit('addNegativeMessage',response.data.msg);
                }
              }
              this.$store.commit('setBusyState',false);
            }).catch(e => {
            // eslint-disable-next-line no-console
                console.log(e);
                this.$store.commit('setBusyState',false);
            })
          }
        });
    },
    getAuthCloudSessions(){
      return (localStorage.getItem("token")!=null && localStorage.getItem("token").length > 0)?
              "Basic ".concat(localStorage.getItem("token") )
            :((localStorage.getItem("bearerjwt")!=null && localStorage.getItem("bearerjwt").idToken )?
              "Bearer ".concat(localStorage.getItem("bearerjwt").idToken)
              : null);
    }
  },
  created() {
    this.total_simulation_time = 0.0;
    this.total_session_time = 0.0;
    this.total_simulation_time_history = 0.0;
    this.total_session_time_history = 0.0;
    this.sessions = [];
    this.history = [];
    this.messages = [];
    this.currentSession = {};
    this.editProjectModal = false;
    this.busyState = false;
    this.selectedProject = "default";
    clearTimeout(this.sessionsTimer)
    this.updateSessions();
    if(this.sessions.length > 0){
      localStorage.setItem("sessionid",this.sessions[0].id);       
      this.monitorSession(this.sessions[0].id);
    }
    //this.sessionsTimer=setTimeout(this.updateSessions,20);
    this.fileInput = document.getElementById("file-input"),
    this.uploadProgress = 0.0,
    this.uploadFile="",
    this.fileShares=[],
    this.uploadFiles = async () => {
      try {
        // eslint-disable-next-line no-console
        this.$store.commit('setBusyState',true);
        const promises = [];
        // eslint-disable-next-line no-unused-vars
        for (const file of this.fileInput.files) {
            this.uploadFile = file.name;
            // eslint-disable-next-line no-undef
            const blockBlobURL = azblob.BlockBlobURL.fromContainerURL(this.containerURL, file.name);promises.push(azblob.uploadBrowserDataToBlockBlob(azblob.Aborter.none, file, blockBlobURL,
            { progress: (progressEvent) => {
              //TransferProgressEvent
                  // eslint-disable-next-line no-console
                  this.uploadProgress = (progressEvent.loadedBytes*100) / file.size;
             }
            }));
        }
        await Promise.all(promises);
         // eslint-disable-next-line no-unused-vars
        for (const file of this.fileInput.files) {
            // eslint-disable-next-line no-undef
            const blockBlobURL = azblob.BlockBlobURL.fromContainerURL(this.containerURL, file.name);
            // Notify ecm about uploaded file
            this.sendUploadNotification(this.currentSession,file.name,blockBlobURL.url);
        }
        // eslint-disable-next-line no-console
       this.uploadProgress = 0.0;
       this.$store.commit('setBusyState',false);
       this.updateSessions();       
      } catch (error) {
        this.$store.commit('setBusyState',false);
          // eslint-disable-next-line no-console
         UIkit.modal.alert("File upload failed");
      }
    };
    //user:String, machine: String, sessionId: Int, connectionStatus: String, lastInputTime: String
    //this.rdpsession={"machine": "RDSHost0", "user": "markus.hogberg", "sessionId":65, "connectionStatus": "Active", "lastInputTime": "Date(1239823088)"};
    this.rdpsession=undefined;
  }
};
</script>