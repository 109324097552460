<template>
<div class="uk-container">
<article class="uk-article">
    <h1>Overview</h1>
    <p>EQUA Cloud Services is providing access to EQUA software running on cloud resources utilizing <a href="https://docs.microsoft.com/en-us/azure/virtual-desktop/user-documentation/">Azure Virtual Desktop</a>. In addition to accessing the applications through the browser or native clients the services include: </p>
    <ul>
      <li>Backed up storage of user files</li>
      <li>Backed up shared storage with configurable inter company access.</li>
      <li>Cloud computing resources for large scale simulations.</li>
      <li>Dedicated configurable cloud computing resources.</li>
      <li>Company account management for users and subsciptions.</li>
      <li>Web application for monitoring sessions and simulations with e-mail notifications.</li>
      <li>Monitoring of costs and billing information.</li>
    </ul>
    <p>
      To use the services the company first needs to be granted access through EQUA. Then each user needs to register for an account.
      Each company needs at least one administrator that will be allowed to sign up for license subcriptions and allocate these to company users and to manage the company level services.
      The subsciptions include qoutas of storage, compute resources and data transfers that will cover normal use and additional resources are available in a pay-as-you-go manner. 
      Configurable alerts on resource use and costs can be configured on a company level.
    </p>
  <!--<iframe src="https://devs.equa.se/en/services/simulation-services/cssp" style="height:900px;width:100%;border:1px;" title=""></iframe>-->
</article>
</div>
</template>

<script>
export default {
  name: 'Hello'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>