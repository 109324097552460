<template>
<div>
  <h3 v-if="this.poolStatus.filter((x)=> { return x.available > 0}).length > 0 && this.pools.length>0">Compute resource availability:</h3>
  <h3 v-else>You do not have access to any active pool.</h3>
  <span style="height:24px;padding-top:4px;" class="uk-label uk-margin-right"  
    v-for='p in this.poolStatus.filter((x)=> { return x.available > 0})'
    :key='p.name'>
    <div class="uk-text-middle" v-if="pools.filter((x)=>{return x.startsWith(p.id)}).length > 0">
        <svg class="poolstatus" viewBox="0 0 32 32">
          <circle class="poolstatus" r="16" cx="16" cy="16" v-bind:style="'stroke-dasharray: '+(p.available==0?1:p.usage/p.available)*100+', 100'">
          </circle>
        </svg>
        <span class="uk-text-middle">{{p.id}} ( {{p.available}} nodes available, task limit {{ pools.filter((x)=>{return x.startsWith(p.id)})[0].slice(pools.filter((x)=>{return x.startsWith(p.id)})[0].lastIndexOf(':')+1)}})</span>
    </div>
  </span>
</div>
</template>
<script>
export default {
  name: "PoolStatus",
  data() {
    return {
      poolStatus: [],
      pools: [],
      poolStatusTimer:{},
    };
  },
  methods: {
    updatePoolStatus() {
      if(this.getAuth()!=null){
        this.$http
        .get("/api/poolstatus", {
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.poolStatus = response.data.data;
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
          }
          clearTimeout(this.poolStatusTimer)
          this.poolStatusTimer=setTimeout(this.updatePoolStatus, 20000);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
          clearTimeout(this.poolStatusTimer)
          this.poolStatusTimer=setTimeout(this.updatePoolStatus, 60000);
        });
      }
      else{
        clearTimeout(this.poolStatusTimer)
        this.poolStatusTimer=setTimeout(this.updatePoolStatus, 20000);
      }
    },
    updatePools() {
      if(this.getAuth()!=null){
        this.$http
        .get("/api/pools", {
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.pools = response.data.data;
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
      }
    },
    getAuthPoolStatus(){
      return (localStorage.getItem("token")!=null && localStorage.getItem("token").length > 0)?
              "Basic ".concat(localStorage.getItem("token") )
            :((localStorage.getItem("bearerjwt")!=null && localStorage.getItem("bearerjwt").idToken )?
              "Bearer ".concat(localStorage.getItem("bearerjwt").idToken)
              : null);
    }
  },
  created() {
    this.poolStatus = [];
    this.pools = [];
    this.updatePools();
    clearTimeout(this.poolStatusTimer)
    this.poolStatusTimer=setTimeout(this.updatePoolStatus,2000);
  }
};
</script>
<style>

@keyframes spin {
  to { transform: rotate(.5turn); }
}
@keyframes bg {
  50% { background: #655; }
}

svg.poolstatus {
  width: 20px; height: 20px;
  transform: rotate(-90deg) translateX(0px) translateY(-5px);
  background: yellowgreen;
  border-radius: 50%;
}

circle.poolstatus {
  fill: rgb(255, 255, 255);
  stroke: #C72831;
  stroke-width: 32;
  stroke-dasharray: 18 100; /* for 38% */
}
</style>


