<template>
  <div  class="uk-container">
    <div id="modal-vm-size" uk-modal>
       <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Select a Size</h2>
        <div class="uk-container">
          <select class="uk-select"
            v-model="newVMSize"
          >
            <option
              v-for="asize in availableSizes"
              :selected="asize==newVMSize"
              :key="asize.name"
              :value="asize"
            >
              {{ asize.name }}
            </option>
          </select>
          <button
            @click="resizeMachine();hideVMSizeModal()"
            :disabled="newVMSize==currentVMSize"
          >
            Resize to {{ newVMSize.name }}
          </button>
        </div>
        <div class="uk-container">
          <div>
            <h5>{{ newVMSize.name }}</h5>
            <div class="ui list">
              <div class="item">
                <i class="server icon" />
                <div class="content">
                  {{ newVMSize.name }}
                </div>
              </div>
              <div class="item">
                <i class="microchip icon" />
                <div class="content">
                 {{ newVMSize.vcpu }} vCPU
                </div>
              </div>
              <div class="item">
                <i class="hdd icon" />
                <div class="content">
                  {{ newVMSize.ram }} GB RAM
                </div>
              </div>
              <div class="item">
                <i class="hdd icon" />
                <div class="content">
                  {{ newVMSize.hdd }} GB
                </div>
              </div>
              <div class="item">
                <i class="money bill alternate icon" />
                <div class="content">
                  {{ newVMSize.rate }}
                </div>
              </div>
            </div>
          </div>
        </div>
          <p class="uk-text-center">
        <button class="uk-button uk-button-default" type="button"
            @click="hideVMSizeModal()"
          >
            Cancel
          </button>
          <button class="uk-button uk-button-default" type="button"
            :disabled="newVMSize==currentVMSize"
            @click="resizeMachine();hideVMSizeModal()"
          >
            OK
          </button>
          </p>
      </div>
    </div>
    <div id="modal-new-user" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Invite user</h2>
         <div class="uk-container">
          <Form @submit="inviteUser" class="uk-form-stacked">
            <div class="uk-margin">
              <label class="uk-label uk-label-default uk-margin-small-top" for="username">Choose a short user name ( 20 char max) </label>
              <input class="uk-input"  type="text" name="username"  placeholder="User name" autocomplete="nickname" v-model="proposedUsername"/>
            </div>
        <p class="uk-text-center">
          <button
            class="uk-button uk-button-default"
            @click="hideNewUserModal"
            type="button"
          >
            Cancel
          </button>
          <button class="uk-button uk-button-primary" type="submit">
            Proceed with cloud account creation
          </button>
        </p>
         </Form>
         </div>
      </div>
    </div>
      <div id="modal-admin-create-pool" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
      <h2 class="uk-modal-title">Create new pool</h2>
      <p>
      Pool names can only contain any combination of alphanumeric characters along with dash (-) and underscore (_).
       The name must be from 1 through 64 characters long.</p>
      <form class="uk-form-stacked">
        <span>
          Name:
          <input class="uk-input"
            v-model="poolname" 
            type="text"
          >
        </span>
        <span>
          Pool usage type:
          <select class="uk-select"
            :value="poolreplace"
            v-model="poolreplace"
          >
            <option
              v-for="opt in [{text:'Simulations',value:'ice-pool'},{text:'Daylight',value:'daylight-pool'},{text:'CFD',value:'cfd-pool'}]"
              :key="opt.value"
              :value="opt.value"
              :selected="poolreplace==opt.value"
            >
              {{ opt.text }}
            </option>
          </select> 
        </span>
        <span>
          Node size:
          <select class="uk-select"
            :value="poolnodesize"
            v-model="poolnodesize"
          >
            <option 
              v-for="opt in [{text:'64 cores',value:'64'},{text:'48 cores',value:'48'},{text:'32 cores',value:'32'},{text:'16 cores',value:'16'},{text:'8 cores',value:'8'},{text:'4 cores',value:'4'},{text:'2 cores',value:'2'}]"
              :key="opt.value"
              :value="opt.value"
              :selected="poolnodesize==opt.value"
            >
              {{ opt.text }}
            </option>
          </select>
        </span>
        <span>
          Max tasks per node:
          <select class="uk-select"
            :value="poolnodetaskmax"
            v-model="poolnodetaskmax"
          >
            <option 
              v-for="opt in [{text:'64',value:'64'},{text:'48',value:'48'},{text:'32',value:'32'},{text:'16',value:'16'},{text:'8',value:'8'},{text:'4',value:'4'},{text:'2',value:'2'},{text:'1',value:'1'}]"
              :key="opt.value"
              :value="opt.value"
              :selected="poolnodetaskmax==opt.value"
            >
              {{ opt.text }}
            </option>
          </select>
        </span>
        <span>
          Max pool size: 
          <input  class="uk-input"
            v-model="poolmaxsize" 
            type="number"
          >
        </span>
      </form>
      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-margin-small-right" type="button"
          @click="hideCreatePoolModal"
        >
          Cancel
        </button>
        <button class="uk-button uk-button-primary" type="button"
          :disabled="poolname.length==0 || poolreplace.length==0 || poolnodesize.length==0|| poolnodetaskmax.length==0"
          @click="createPool(poolname,poolreplace,poolnodesize,poolnodetaskmax,poolmaxsize)"
        >
          Create
        </button>
      </p>
      </div>
    </div>
    <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
      <h1>Manage services &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refreshAll">&#8635;</button></h1>
      <h3 class="uk-card-title uk-text-primary">Impersonate &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refreshCompanies">&#8635;</button></h3>
      <p>Select a company and a user in that company to impersonate in order to act on their behalf. To stop impersonating, press clear alias where you normally find the logout button.</p>
      <select class="uk-select uk-width-1-3 uk-margin-small-right"
        :value="selectedCompany"
        v-model="selectedCompany"
        @change="getUsers"
      >
        <option
          v-for="opt in companies.sort(function(a,b) { return (a.toLowerCase() > b.toLowerCase())?1:((a.toLowerCase() < b.toLowerCase())?-1:0)})"
          :key="opt"
          :value="opt"
          :selected="selectedCompany==opt"
        >
          {{ opt }}
        </option>
      </select>
      <select class="uk-select uk-width-1-3 uk-margin-small-right"
        :value="selectedUser"
        v-model="selectedUser"
      >
        <option
          v-for="opt2 in this.companyUsers.sort(function(a,b) { return (a.toLowerCase() > b.toLowerCase())?1:((a.toLowerCase() < b.toLowerCase())?-1:0)})"
          :key="opt2"
          :value="opt2"
          :selected="selectedUser==opt2"
        >
          {{ opt2 }}
        </option>
      </select>
      <span class="uk-margin">
        <button class="uk-button uk-button-default uk-margin-small-right" v-if="selectedUser!=''" @click="setAlias(selectedUser)">Impersonate</button>  
      </span>
    </div>
    <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
      <h3 class="uk-card-title uk-text-primary">Invite &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refreshCompanies">&#8635;</button></h3>
      <p>Select a company and then a user from the list of users that exists in the license databse but does not yet have an account. 
         When inviting the user, an account will be created and the user invited to activate this account. </p>
      <select class="uk-select uk-width-1-3 uk-margin-small-right"
        :value="selectedCompany"
        v-model="selectedCompany"
        @change="getUsers"
      >
        <option
          v-for="opt in companies.sort(function(a,b) { return (a.toLowerCase() > b.toLowerCase())?1:((a.toLowerCase() < b.toLowerCase())?-1:0)})"
          :key="opt"
          :value="opt"
          :selected="selectedCompany==opt"
        >
          {{ opt }}
        </option>
      </select>
      <select class="uk-select uk-width-1-3 uk-margin-small-right"
        :value="selectedUserToInvite"
        v-model="selectedUserToInvite"
      >
      <option
          v-for="opt2 in this.companyEmployees.filter( (x) => { return !this.companyUsers.includes(x) }).sort(function(a,b) { return (a.toLowerCase() > b.toLowerCase())?1:((a.toLowerCase() < b.toLowerCase())?-1:0)})"
          :key="opt2"
          :value="opt2"
        >
        {{opt2}}
      </option>
      </select>
      <!--<input list="employees" v-model="selectedUserToInvite" class="uk-input uk-width-1-3 uk-margin-small-right"> 
      <datalist id="employees">
        <option
          v-for="opt2 in this.companyEmployees.filter( (x) => { return !this.companyUsers.includes(x) })"
          :key="opt2"
          :value="opt2"
        />
      </datalist>-->
      <span class="uk-margin">
        <button class="uk-button uk-button-default uk-margin-small-right" v-if="selectedUserToInvite!=''" @click="showNewUserModal">Invite</button>  
      </span>
    </div>
    <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
      <h3 class="uk-card-title uk-text-primary">Force customer database synchronization &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="forceDBSynch">&#8635;</button></h3>
      <h3 class="uk-card-title uk-text-primary">Create Company Cloud account &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refreshCompanies">&#8635;</button></h3>
      <p>Create a new company cloud account by entering the email of the user who will be the administrator and the company reference used in the billing system (Fortnox).
         This will create an entry for the company in the cloud management system and allow the administrator to be invited to set up an account.</p>
        <input class="uk-input uk-width-1-3 uk-margin-small-right"
          v-model="selectedCompanyUser" placeholder="administrator e-mail"
        >
        <input class="uk-input uk-width-1-3 uk-margin-small-right" placeholder="company reference"
          v-model="companyRef"
        > 
      <span class="uk-margin">
        <button class="uk-button uk-button-default uk-margin-small-right" v-if="selectedCompanyUser!=''" @click="createCompany(selectedCompanyUser,companyRef)">Create company account</button>  
      </span>
    </div>
   <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
      <h3 class="uk-card-title uk-text-primary">Force AD synchronization &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="forceADSynch">&#8635;</button></h3>
      <h3 class="uk-card-title uk-text-primary">Create Trial license</h3>
      <p>Create an ida ice trial license by entering the email of any existing user that has a cloud account or by providing full details including email and proposed username for a new user.</p>
        <input class="uk-input uk-width-1-3 uk-margin-small-right" placeholder="e-mail"
          v-model="selectedCompanyUser"
        > 
        <input class="uk-input uk-width-1-3 uk-margin-small-right" placeholder="username"
          v-model="selectedCompanyUserName"
        > 
        <input class="uk-input uk-width-1-3 uk-margin-small-right" placeholder="first name"
          v-model="trialUserFirstName"
        >
        <input class="uk-input uk-width-1-3 uk-margin-small-right" placeholder="last name"
          v-model="trialUserLastName"
        >
        <input class="uk-input uk-width-1-3 uk-margin-small-right" placeholder="company name"
          v-model="trialUserCompanyName"
        > 
      <span class="uk-margin">
        <button class="uk-button uk-button-default uk-margin-small-right" v-if="selectedCompanyUser!='' && selectedCompanyUserName!='' && trialUserFirstName!='' && trialUserLastName!='' && trialUserCompanyName!=''" @click="createTrial(selectedCompanyUser,selectedCompanyUserName)">Create trial license</button>  
      </span>
      <h3 class="uk-card-title uk-text-primary">Modify Trial license</h3>
      <select class="uk-select uk-width-1-3 uk-margin-small-right"
        :value="trialUser"
        v-model="trialUser"
      >
      <option
          v-for="opt2 in this.trialUserList.filter( (x) => { return x!=='' }).sort(function(a,b) { return (a.toLowerCase() > b.toLowerCase())?1:((a.toLowerCase() < b.toLowerCase())?-1:0)})"
          :key="opt2"
          :value="opt2"
        >
        {{opt2}}
      </option>
      </select>
      <!--\"expire\":\""+this.trialexpire+"\" TODO use calendar to get timestamp in milliseconds
      \"extlicenses\":\""+this.extlicenses    TODO Select from list of available extensions -->
      <span class="uk-margin">
        <button class="uk-button uk-button-default uk-margin-small-right" v-if="trialUser!='' && trialUsr!='' && trialexpire!='' && extlicenses!='' " @click="changeTrial()">Modify trial license</button>  
      </span>
    </div>
    <div class="uk-margin-large-bottom">
        <!-- Session hosts -->
        <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
          <h3 class="uk-card-title uk-text-primary">Session hosts &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refreshMachines">&#8635;</button></h3>
          <p>Status of the session hosts available. It is possible to add a new host or stop, start and delete existing ones.</p>
            <button
                class="uk-button uk-button-primary uk-button-small"
                uk-icon="plus" 
                @click="scaleUp(undefined)">Add session host </button>
              <table class="uk-table uk-table-striped">
                <thead>
                <tr>
                  <th>Host name<span>
                  </span>
                  </th>
                  <th>Stop</th>
                  <th>Delete</th>
                </tr>
              </thead>
                <tbody>
                  <tr 
                    v-for="n in (nodes ?? []).filter((x)=>{return x.virtualMachine.roleName.substring(0,7).toLowerCase()=='rdshost' || x.virtualMachine.roleName.indexOf('-rds-') > 0})"
                    :key="n.virtualMachine.deploymentName"
                    >
                    <td>
                    <button class="uk-button uk-button-small"
                      v-if="n.virtualMachine.state!=='PowerState/running'" disabled>
                            {{n.virtualMachine.roleName}}  
                    </button>
                    <button class="uk-button uk-button-small"
                            v-else>
                            {{n.virtualMachine.roleName}}
                    </button>
                    </td>
                    <td>
                    <button class="uk-button  uk-button-small"
                           @click="scaleOut(n.virtualMachine.scaleSetVm,n.virtualMachine.roleName,'pause')"
                         
                        uk-icon="ban"
                        :style="n.virtualMachine.state==='PowerState/running'?'color: green':'color:gray'"
                    >
                    </button>
                    </td>
                    <td>
                    <button class="uk-button  uk-button-small" 
                            @click="scaleOut(n.virtualMachine.scaleSetVm,n.virtualMachine.roleName,'delete')"
                      
                        uk-icon="trash"
                        :style="n.virtualMachine.state==='PowerState/running'?'color: gray':'color:red'"
                      >
                    </button>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
        <!-- Compute pools -->
        <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
          <h3 class="uk-card-title uk-text-primary">Compute pools &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refreshPools">&#8635;</button></h3>
          <p>List of all pool of compute nodes available ant their status. It is possible to create new pools or delete existing ones. Default pools that are available to all users can be created.</p>
          <button
            class="uk-button uk-button-primary uk-button-small"
            uk-icon="plus" 
            @click="showCreatePoolModal()">Add Default Pool </button>

          <table class="uk-table uk-table-striped">
            <thead>
              <tr>
                <th>Pool name<span>
                  
                </span>
                </th>
                <th>Nodes</th>
                <th>Load</th>
                <th>Scale up</th>
                <th>Scale down</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pool in pooladminlist.sort(function(a,b) { return (a.id.toLowerCase() > b.id.toLowerCase())?1:((a.id.toLowerCase() < b.id.toLowerCase())?-1:0)})"
                :key="pool.id">
                <td>
                  {{pool.id}}
                </td>
                <td>
                  {{pool.available}}
                </td>
                <td>
                  {{pool.usage/pool.available*100}} %
                </td>
                <td>
                    <button class="uk-button uk-button-small" type="button"
                    uk-icon="arrow-up"
                    @click="notImplementedAlert('Not implemented scale-up')"
                  />
                </td>
                <td>
                    <button class="uk-button uk-button-small" type="button"
                    uk-icon="arrow-down"
                    @click="notImplementedAlert('Not implemented scale-down')"
                  />
                </td>
                <td>
                  <button class="uk-button uk-button-small" type="button"
                    uk-icon="trash" :style="'color:red'"
                    @click="deletePool(pool.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- File shares -->
        <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
          <h3 class="uk-card-title uk-text-primary">File shares &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refreshShareSizes">&#8635;</button></h3>
          <p>List of file share that can be mounted on users drives allowing them to share files. It is possible to add a file share paid for by Equa for example to use for support.</p>
           <button
                class="uk-button uk-button-primary uk-button-small"
                uk-icon="plus" 
                @click="createShare">Add EQUA controlled fileshare </button>
            <!--<button
                class="uk-button uk-button-primary uk-button-small uk-margin-left"
                uk-icon="plus" 
                @click="projectScaleUp">Add EQUA controlled desktop host</button>-->  
            <table class="uk-table uk-table-striped">
              <thead>
              <tr>
                <th>Share name
                </th>
                <th>Size</th>
                <th>Delete</th>
              </tr>
            </thead>
              <tbody>
                  <tr  v-for="share in shareadminlist.sort(function(a,b) { return (a.id.toLowerCase() > b.id.toLowerCase())?1:((a.id.toLowerCase() < b.id.toLowerCase())?-1:0)})"
                    :key="share.id">
                    <td>{{share.id}}</td>
                    <td>{{sharesizesgb[share.id]}} Gb</td>
                    <td> 
                      <button class="uk-button uk-button-small uk-margin-small-left" type="button" 
                        uk-icon="trash" :style="'color:red'"
                        @click="deleteShare(share.id)"
                      />
                    </td>
                  </tr>
              </tbody>
            </table>
        </div>
       <!-- User profile disks -->
        <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
          <h3 class="uk-card-title uk-text-primary">User profile disks &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refreshUPDSizes">&#8635;</button></h3>
          <p>List of user profile disks and their sizes </p>
            <table class="uk-table uk-table-striped">
              <thead>
              <tr>
                <th>User name<span>
                </span>
                </th>
                <th>Size</th>
                <th>Delete</th>
              </tr>
            </thead>
              <tbody>
                  <tr v-for="(sizegb,username) in updsizesgb"
                    :key="username">
                    <td>{{username}}</td>
                    <td>{{sizegb}} Gb</td>
                    <td> 
                      <button class="uk-button uk-button-small uk-margin-small-left" type="button" 
                        uk-icon="trash" :style="'color:red'"
                        @click="notImplementedAlert('Not implemented: Delete user profile disk')"
                      />
                    </td>
                  </tr>
              </tbody>
            </table>
        </div>
    </div>
    <div :hidden="rdpsessionlist === undefined || rdpsessionlist.length == 10">
      <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
        <h3 class="uk-card-title uk-text-primary">
          RDS sessions &nbsp;<button class="uk-button uk-button-default uk-text-large" @click="refreshRDPSessions">&#8635;</button>
        </h3>
        <p>Table listing the active sessions for users on different hosts. It is possible to forcefully end a users session or just disconnect them.</p>
      <table class="uk-table uk-table-striped">
        <thead>
          <tr>
            <th>User<span>
              
            </span>
            </th>
            <th>Host</th>
            <th>Status</th>
            <th>Disconnect</th>
            <th>Log off</th>
            <th>Send Message</th>
          </tr>
        </thead>
        <tbody>
      <tr v-for="rdpsession in rdpsessionlist.sort(function(a,b) { return (a.user.toLowerCase() > b.user.toLowerCase())?1:((a.user.toLowerCase() < b.user.toLowerCase())?-1:0)})" :key="rdpsession.user">
        <td>{{rdpsession.user}}</td>
        <td>{{rdpsession.machine}}</td>
        <td>{{rdpsession.connectionStatus}}</td>
        <td>
          <button class="uk-button uk-button-small uk-margin-small-left" type="button"
                  uk-icon="close" @click="rdsDisconnect(rdpsession)"> </button>
        </td>
        <td>
          <button class="uk-button uk-button-small uk-margin-small-left" type="button"
                  uk-icon="sign-out" @click="rdsLogoff(rdpsession)"> </button>
        </td>
         <td>     
          <button  class="uk-button uk-button-small uk-margin-small-left" type="button" 
                  uk-icon="mail" @click="rdsMessage(rdpsession)"></button>
          </td>
         </tr>
        </tbody>
      </table>
      </div>
    </div>


    <div
      :hidden="(nodes === undefined) || nodes.length == 0"
    >
      <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
        <h3 class="uk-card-title uk-text-primary">
          <span>
            Subscription total: {{ (this.nodes ?? []).length }} VMs
          </span>

        </h3>
     <p>A list of all virtual machines including servers grouped by resource group. It is possible to start, stop ,deallocate and resize the machines.</p>
     <table class="uk-table uk-table-striped">
      <tbody>
      <tr
        v-for="rgs in this.uniqueGroups()"
        :key="rgs"
      >
        <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
          <h4 class="uk-card-title uk-text-primary">
            {{ rgs }}
          </h4>
        
        <table>
           <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Start</th>
                <th>Stop</th>
                <th>Deallocate</th>
                <th>Configure</th>
                <th>SKU</th>
                <th>vCPU</th>
                <th>Gb RAM</th>
                <th>Gb Disk</th>
                <th>Rate</th>
              </tr>
            </thead>
          <tbody>
          <tr
            v-for="n in (nodes ?? []).filter((x)=>{return x.virtualMachine.resourceGroup==rgs})"
            :key="n.virtualMachine.deploymentName"
            style
          >
            <td>
              {{ n.virtualMachine.roleName }}
            </td>
            <td>
              {{ n.virtualMachine.state }}
            </td>
            <td>
               <a @click="selectNode(n); startMachine();">
                  <i
                    uk-icon="play"
                    :style="n.virtualMachine.state==='PowerState/running'?'color: gray':'color:green'"
                  />
                </a>
            </td>
            <td>
                <a @click="selectNode(n); stopMachine();">
                  <i
                    uk-icon="ban"
                    :style="n.virtualMachine.state==='PowerState/running'?'color: red':'color:gray'"
                  />
                </a>
            </td>
            <td>
                <a @click="selectNode(n); deallocateMachine();">
                  <i
                    uk-icon="trash"
                    :style="n.virtualMachine.state==='PowerState/running'?'color: red':'color:gray'"
                  />
                </a>
            </td>
            <td>
              <button uk-icon="cog" @click="selectNode(n); showVMSizeModal();"/>
            </td>
            <td>{{ n.virtualMachine.vmSize.name }}</td>
            <td>{{ n.virtualMachine.vmSize.vcpu }}</td>
            <td>{{ n.virtualMachine.vmSize.ram }}</td>
            <td>{{ n.virtualMachine.vmSize.hdd }}</td>
            <td>{{ n.virtualMachine.vmSize.rate }}</td>
          </tr>
          </tbody>
        </table>
        </div>
      </tr>
       </tbody>
       </table>
      </div>
    </div>
    <div
      :hidden="(totalRunningCost === undefined)"
    >
      <div class="uk-card uk-card-body uk-card-default uk-margin-bottom">
        <h3 class="uk-card-title uk-text-primary">
          Costs per resource group
        </h3>
     <p></p>
     <ul uk-accordion>  
      <li
        v-for="(costs,rgs) in Object.fromEntries(Object.entries(this.totalRunningCost).sort(([,a],[,b]) => b.Total-a.Total))"
        :key="rgs"
      >
        
          <a class="uk-accordion-title" href="#">
          <h4 class="uk-text-primary">
            {{ rgs }} (Total: {{parseFloat(this.totalRunningCost[rgs]["Total"]).toFixed(2)}} SEK)
          </h4></a>
          <div class="uk-accordion-content">
          <table  class="uk-table uk-table-striped uk-margin-small">
           <tr class="uk-margin-small" v-for="(val,key) in Object.fromEntries(Object.entries(costs).sort(([,a],[,b]) => b-a))" :key="key"><td class="uk-padding-remove">{{key}}</td><td class="uk-padding-remove">{{parseFloat(val).toFixed(2)}}</td></tr>
        </table>
         </div>
        </li>
       </ul>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

UIkit.use(Icons);

export default {
  name: "CloudServices",
  components: {
  },
  data() {
    return {
      companies:[],
      selectedCompany:"",
      companyUsers:[],
      companyEmployees:[],
      selectedUser:"",
      selectedUserToInvite:"",
      proposedUsername:"",
      selectedCompanyUser:"",
      nodes: [],
      poolname:"",
      poolreplace:"",
      poolnodesize:"",
      poolnodetaskmax:"",
      poolmaxsize: 10,
      loading: true,
      currentVMStatus: null,
      currentMachine: null,
      currentVMSize: "",
      newVMSize: "",
      availableMachines: [],
      availableSizes: [],
      currency: "SEK",
      locale: "sv-SE",
      username: "",
      billto: "",
      totalRunningCost: "0",
      scheduler: undefined,
      updateCostTimer:{},
      sharesizesgb:{},
      updsizesgb:{},
      rdpsessionlist:[],
      trialUserList:[],
      trialUser:"",
      trialexpire:"",
      extlicenses:""
    };
  },
  methods: {
    notImplementedAlert(text){
      UIkit.modal.alert(text);
    },
    thisCompanyUsers() {return this.companyUsers;},
    uniqueGroups(){
      return [...new Set(this.nodes.map( x => x.virtualMachine.resourceGroup))];
    },
    selectNode(node) {
      const item = node.virtualMachine;
      //console.log("selecting: ".concat(item));
      this.currentVMStatus = node;
      this.currentMachine = item;
      this.currentVMSize = item.vmSize;
      this.newVMSize = item.vmSize;
    },
    updateAvailableSizes() {
      const req = { machine: this.currentMachine };
      if (!this.availableSizes || this.availableSizes.length === 0) {
        this.$http
          .post("/api/availablesizes", req, {
            timeout: 0,
            headers: {
              Authorization: this.getAuth()
            }
          })
          .then(response => {
            this.availableSizes = response.data;
            if((this.availableSizes??[]).length==0)
              this.availableSizes = [this.currentVMSize];
            //console.log(response);
          })
          .catch({
            //console.log(error);
          });
      }
    },
    selectVMSize(item) {
      //console.log("selecting: ".concat(item));
      this.newVMSize = item;
    },

    isSelected(item) {
      return this.currentMachine === item;
    },
    createShare(){
      UIkit.modal.prompt("Enter project name:").then(result => this.doCreateShare(result))
    },
    doCreateShare(share){
      if(share && this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        this.$http
        .post("/api/share", {"share_id": share, "command": "create", "argument": "{}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
            if(response.data && !response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.company = response.data.data;
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            this.$store.commit('setBusyState',false);
        })
        .catch(() => {
          //Notify user on error...
          this.$store.commit('setBusyState',false);
        });
      }
    },
    doDeleteShare(share){
      if(this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        this.$http
        .post("/api/share", {"share": share, "command": "delete", "argument": "{}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
            if(response.data && !response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.company = response.data.data;
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            this.$store.commit('setBusyState',false);
        })
        .catch(() => {
          //Notify user on error...
          this.$store.commit('setBusyState',false);
        });
      }
    },
    deleteShare(share){
      UIkit.modal.confirm("Please confirm your intent to delete the project and all shared files: "+share,{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(()=>{this.doDeleteShare(share);},()=>{});
    },
    startMachine(){
      UIkit.modal.confirm("Please confirm your intent to start the virtual machine",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doStartMachine();},() => {})
    },
    doStartMachine() {
      this.currentVMStatus.state = "PowerState/running";
      const item = this.currentMachine;
      const req = { machine: item };
      //console.log("Starting: ".concat(item));
      this.$http
        .post("/api/start", req, {
          timeout: 0,
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          //console.log(response);
          if(response)
            this.updateStatus(req.machine);
        })
        .catch(error => {
          UIkit.modal.alert(error.response.data);
          const machine = JSON.parse(error.response.config.data).machine;
          const nodeToUpdate = this.nodes.find(
            node =>
              node.virtualMachine.deploymentName === machine.deploymentName
          );
          nodeToUpdate.state = "";
          //console.log(error);
        });
    },
    stopMachine(){
      UIkit.modal.confirm("Please confirm your intent to stop the virtual machine",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doStopMachine();},() => {})
    },
    doStopMachine() {
      this.currentVMStatus.state = "stopping";
      const item = this.currentMachine;
      const req = { machine: item };
      //console.log("Stopping: ".concat(item));
      this.$http
        .post("/api/stop", req, {
          timeout: 0,
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          //console.log(response);
          if(response)
            this.updateStatus(req.machine);
        })
        .catch(error => {
          UIkit.modal.alert(error.response.data);
          const machine = JSON.parse(error.response.config.data).machine;
          const nodeToUpdate = this.nodes.find(
            node =>
              node.virtualMachine.deploymentName === machine.deploymentName
          );
          nodeToUpdate.state = "";
          //console.log(error);
        });
    },
    deallocateMachine(){
      UIkit.modal.confirm("Please confirm your intent to deallocate the virtual machine",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doDeallocateMachine();},() => {})
    },
    doDeallocateMachine() {
      this.currentVMStatus.state = "PowerState/deallocated";
      const item = this.currentMachine;
      const req = { machine: item };
      //console.log("Deallocating: ".concat(item));
      this.$http
        .post("/api/deallocate", req, {
          timeout: 0,
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          //console.log(response);
          if (response)
          this.updateStatus(req.machine);
        })
        .catch(error => {
          UIkit.modal.alert(error.response.data);
          const machine = JSON.parse(error.response.config.data).machine;
          const nodeToUpdate = this.nodes.find(
            node =>
              node.virtualMachine.deploymentName === machine.deploymentName
          );
          nodeToUpdate.state = "";
          //console.log(error);
        });
    },
    restartMachine(){
      UIkit.modal.confirm("Please confirm your intent to restart the virtual machine",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doRestartMachine();},() => {})
    },
    doRestartMachine() {
      this.currentVMStatus.state = "restarting";
      const item = this.currentMachine;
      const req = { machine: item };
      //console.log("Restarting: ".concat(item));
      this.$http
        .post("/api/restart", req, {
          timeout: 0,
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          //console.log(response);
          if(response)
            this.updateStatus(req.machine);
        })
        .catch(error => {
          UIkit.modal.alert(error.response.data);
          const machine = JSON.parse(error.response.config.data).machine;
          const nodeToUpdate = this.nodes.find(
            node =>
              node.virtualMachine.deploymentName === machine.deploymentName
          );
          nodeToUpdate.state = "";
          //console.log(error);
        });
    },
    resizeMachine(){
      UIkit.modal.confirm("Please confirm your intent to resize the virtual machine",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doResizeMachine();},() => {})
    },
    doResizeMachine() {
      this.currentVMStatus.state = "resizing";
      const item = this.currentMachine;
      const req = { machine: item, vmSize: this.newVMSize.name };
      //console.log("Resizing: ".concat(JSON.stringify(req)));
      this.$http
        .post("/api/resize", req, {
          timeout: 0,
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          //console.log(response);
          if(response){
            this.updateStatus(req.machine);
            (this.availableSizes ??[]).length = 0;
          }
        })
        .catch(error => {
          UIkit.modal.alert(error.response.data);
          const machine = JSON.parse(error.response.config.data).machine;
          const nodeToUpdate = this.nodes.find(
            node =>
              node.virtualMachine.deploymentName === machine.deploymentName
          );
          nodeToUpdate.state = "";
          //console.log(error);
        });
    },

    hideVMSizeModal() {
        UIkit.modal("#modal-vm-size").hide();
    },
    showVMSizeModal() {
      this.updateAvailableSizes();
      UIkit.modal("#modal-vm-size").show();
    },
    updateStatus(item) {
      const req = { machine: item };
      this.$http
        .post("/api/status", req, {
          timeout: 0,
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          const newstatus = response.data;
          /*
        "virtualMachine":{
          "vmSize":{ "rate":"≈ 1.023 SEK / (Timmar)",
                     "name":"Standard_A2_v2",
                     "ram":4,
                     "hdd":20,
                     "vcpu":2,
                     "discs":4},
          "state":"PowerState/deallocated",
          "serviceName":"12345",
          "resourceGroup":"equa-esbo-swegon-master-rg",
          "subscription":{"seats":10,"title":"EQUA Master","applications":["ice","ice-viewer"]},
          "roleName":"master",
          "locale":"sv-SE",
          "deploymentName":"/subscriptions/...",
          "currency":"SEK"},
        "state":""}
        */
          const nodeToUpdate = this.nodes.find(
            node =>
              node.virtualMachine.deploymentName ===
              newstatus.virtualMachine.deploymentName
          );
          // UIkit.modal.alert(JSON.stringify(nodeToUpdate));
          if (
            nodeToUpdate.virtualMachine.deploymentName ===
            newstatus.virtualMachine.deploymentName
          ) {
            if (
              nodeToUpdate.virtualMachine.vmSize !==
              newstatus.virtualMachine.vmSize
            ) {
              nodeToUpdate.virtualMachine.vmSize =
                newstatus.virtualMachine.vmSize;
            }
            if (
              nodeToUpdate.virtualMachine.state !==
              newstatus.virtualMachine.state
            ) {
              nodeToUpdate.virtualMachine.state =
                newstatus.virtualMachine.state;
            }
            if (nodeToUpdate.state !== newstatus.state) {
              nodeToUpdate.state = newstatus.state;
            }
          }
          if (
            newstatus.state === "" &&
            newstatus.virtualMachine.state === "PowerState/running"
          ) {
            // setTimeout(this.updateStatus(newstatus.virtualMachine), 300000);
          }
        })
        .catch(error => {
          UIkit.modal.alert(error.response.data);
          const machine = JSON.parse(error.response.config.data).machine;
          const nodeToUpdate = this.nodes.find(
            node =>
              node.virtualMachine.deploymentName === machine.deploymentName
          );
          nodeToUpdate.state = "";
          //console.log(error);
        });
    },
    doCreatePool(pool,repl,sz,tpn,mxsz){
          if(pool && this.getAuth()!=null){
            this.$store.commit('setBusyState',true);
            this.$http
            .post("/api/pools",{"command": "create", "argument": "{ \"pool\": \""+pool+"\",\"replace\":\""+repl+"\",\"size\": \""+sz+"\",\"taskmax\":\""+tpn+"\",\"maxsize\":\""+mxsz+"\"}"},{
              headers: {
              Authorization: this.getAuth()
              }
            })
            .then(response => {
                if(!response.data.error){
                this.$store.commit('addPositiveMessage',response.data.msg);
                this.company = response.data.data;
                }
                else{
                  this.$store.commit('addNegativeMessage',response.data.msg);
                }
                this.$store.commit('setBusyState',false);
            })
            .catch(() => {
              //Notify user on error...
              this.$store.commit('setBusyState',false);
            });
          }
    },
    showCreatePoolModal() {
      UIkit.modal("#modal-admin-create-pool").show();
    },
    hideCreatePoolModal() {
      UIkit.modal("#modal-admin-create-pool").hide();
    },
    createPool(pool,repl,sz,tpn,mxsz){
      this.hideCreatePoolModal();
      UIkit.modal.confirm("Please confirm your intent to create a new default pool "+repl,{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doCreatePool(pool,repl,sz,tpn,mxsz);},() =>{});
    },
    doDeletePool(pool){
     if(pool && this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        this.$http
        .post("/api/pools",{"command": "delete", "argument": "{ \"pool\": \""+pool+"\"}"},{
          headers: {
          Authorization: this.getAuth()
          }
        })
        .then(response => {
            if(!response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.company = response.data.data;
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
            this.$store.commit('setBusyState',false);
        })
        .catch(() => {
          this.$store.commit('addNegativeMessage',"An error occured");
          this.$store.commit('setBusyState',false);
        });
      }
    },
    deletePool(pool){
      UIkit.modal.confirm("This operation can not be undone. Proceed?").then(()=>{this.doDeletePool(pool);},()=>{});
    },
    updateCost() {
      this.$http
        .get("/api/costs", {
          timeout: 0,
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          this.totalRunningCost = response.data;
          clearTimeout(this.updateCostTimer)
          this.updateCostTimer=setTimeout(this.updateCost, 1800000);
        })
        .catch(() => {
          this.totalRunningCost = "?";
          clearTimeout(this.updateCostTimer)
          this.updateCostTimer=setTimeout(this.updateCost, 1800000);
        });
    },
    downloadRdp(theMachine, theApp) {
      UIkit.modal.alert("Starting ".concat(theApp));
      window.open(
        "/api/rdp?where="
          .concat(theMachine.name)
          .concat("&what=".concat(theApp))
      );
    },
    getCompanies(){
       if(this.getAuth()!=null){
        this.$http
          .get("/api/companyadminnamelist", {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.companies = Array.from(response.data.data);
             }
             else{
              this.$store.commit('addNegativeMessage',response.data.msg); 
             }
            
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
      }
    },
    getUsers(){
       if(this.getAuth()!=null&& this.selectedCompany!=""){
        this.$http
          .post("/api/useradminnamelist",{"command": "list", "argument": "{ \"company\": \""+this.selectedCompany+"\"}"}, {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.companyUsers = Array.from(response.data.data);
              this.getPotentialUsers();
             }
             else{
              this.$store.commit('addNegativeMessage',response.data.msg); 
             }
            
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
      }
    },
    getPotentialUsers(){
       if(this.getAuth()!=null&& this.selectedCompany!=""){
        this.$http
          .post("/api/iceuseradminnamelist",{"command": "list", "argument": "{ \"company\": \""+this.selectedCompany+"\"}"}, {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.companyEmployees = Array.from(response.data.data);
             }
             else{
              this.$store.commit('addNegativeMessage',response.data.msg); 
             }
            
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
      }
    },
    getShares(){
       if(this.getAuth()!=null){
        this.$http
          .get("/api/shareadminlist", {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.shareadminlist = Array.from(response.data.data);
             }
             else{
              this.$store.commit('addNegativeMessage',response.data.msg); 
             }
            
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
      }
    },
    getPools(){
       if(this.getAuth()!=null){
        this.$http
          .get("/api/pooladminlist", {
            headers: {
            Authorization: this.getAuth()
            }
          })
          .then(response => {
             if(response.data && !response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
              this.pooladminlist = Array.from(response.data.data);
             }
             else{
              this.$store.commit('addNegativeMessage',response.data.msg); 
             }
            
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e);
          });
      }
    },
    setAlias(email){
       this.$store.commit('setBusyState',true);
      if(this.getAuth()!=null){
        this.$http.post("/api/alias",{"command": "create", "argument": "{ \"alias\":\""+email+"\" }"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response.data && !response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
            localStorage.setItem("isActuallyCloudAdmin",true);
            this.$router.push("/");
            window.location.reload();
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
    },
    createCompany(email,company_ref){
       this.$store.commit('setBusyState',true);
      if(this.getAuth()!=null){
        this.$http.post("/api/admincmd",{"command": "newcompany", "argument": "{ \"email\":\""+email+"\",\"company_ref\":\""+company_ref+"\" }"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response.data && !response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.getCompanies();
            this.$store.commit('setBusyState',false);
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
    },
    showNewUserModal() {
      UIkit.modal("#modal-new-user").show();
    },
    hideNewUserModal() {
      UIkit.modal("#modal-new-user").hide();
    },
    inviteUser(e) {
      e.preventDefault();
      this.doInviteUser();
      this.hideNewUserModal();
    },
    doInviteUser(){
      this.$store.commit('setBusyState',true);
      if(this.getAuth()!=null){
        //UIkit.modal.alert("{ \"company\":\""+this.selectedCompany+"\", \"email\":\""+this.selectedUserToInvite+"\" }")
        this.$http.post("/api/admincmd",{"command": "invite", "argument": "{ \"company\":\""+this.selectedCompany+"\", \"email\":\""+this.selectedUserToInvite+"\", \"username\":\""+this.proposedUsername+"\"}"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response.data && !response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
    },
    createTrial(){
      this.$store.commit('setBusyState',true);
      if(this.getAuth()!=null){
        //UIkit.modal.alert("{ \"email\":\""+this.selectedCompanyUser+"\" }")
        this.$http.post("/api/admincmd",{"command": "trial", "argument": "{ \"email\":\""+this.selectedCompanyUser+"\",\"username\":\""+this.selectedCompanyUserName+"\", \"firstname\":\""+this.trialUserFirstName+"\", \"lastname\":\""+this.trialUserLastName+"\", \"company\":\""+this.trialUserCompanyName+"\" }"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data && !response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
    },
    changeTrial(){
      this.$store.commit('setBusyState',true);
      if(this.getAuth()!=null){
        //UIkit.modal.alert("{ \"email\":\""+this.selectedCompanyUser+"\" }")
        this.$http.post("/api/admincmd",{"command": "changetrial", "argument": "{ \"email\":\""+this.trialUser+"\", \"expire\":\""+this.trialexpire+"\", \"extlicenses\":\""+this.extlicenses+"\" }"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data && !response.data.error){
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
    },
    listTrialUsers(){
      this.$store.commit('setBusyState',true);
      if(this.getAuth()!=null){
        this.$http.get("/api/trialusersadminlist",{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data && !response.data.error){
            this.trialUserList=response.data.data;
            this.$store.commit('addPositiveMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
          else{
            this.$store.commit('addNegativeMessage',response.data.msg);
            this.$store.commit('setBusyState',false);
          }
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
    },
    shareSizes(){
     if(this.getAuth()!=null){
      this.$store.commit('setBusyState',true);
      this.$http.get("/api/sharesizeadminlist",{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.sharesizesgb = response.data.data;
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
     }
   },
   updSizes(){
     if(this.getAuth()!=null){
      this.$store.commit('setBusyState',true);
      this.$http.get("/api/updsizeadminlist",{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.updsizesgb = response.data.data;
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
     }
   },
   rdpSessions(){
     if(this.getAuth()!=null){
      this.$store.commit('setBusyState',true);
      this.$http.get("/api/rdpsessionadminlist",{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.rdpsessionlist = response.data.data;
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
     }
   },
   rdsDisconnect(rdsSession){
      UIkit.modal.confirm("Please confirm your intent to disconnect the session",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doRdsDisconnect(rdsSession);},() => {})
    },
   doRdsDisconnect(rdsSession){
      if(this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        this.$http.post("/api/rdpsession",{ "sessionId": rdsSession.sessionId, "machine": rdsSession.machine,"command": "disconnect", "argument": "{}"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            this.rdpSessions();
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
   },
   rdsLogoff(rdsSession){
      UIkit.modal.confirm("Please confirm your intent to logoff the session",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doRdsLogoff(rdsSession);},() => {})
    },
   doRdsLogoff(rdsSession){
      if(this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        this.$http.post("/api/rdpsession",{ "sessionId": rdsSession.sessionId, "machine": rdsSession.machine,"command": "logoff", "argument": "{}"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.rdpSessions();
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
   },
   rdsMessage(rdsSession){
    UIkit.modal.prompt("Message").then( msg => {
      if(msg && this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        var payload = { "command": "message", "sessionId": rdsSession.sessionId, "machine": rdsSession.machine, "argument": "{\"title\":\"Admin message\",\"message\":\""+msg+"\"}"}
        this.$http.post("/api/rdpsession",payload,{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
    });
   },
   scaleUp(machine){
    if(machine === undefined){
      UIkit.modal.confirm("Please confirm your intent to create a new session host",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doScaleUp();},() => {})
    }
    else{
      this.doStart(machine);
    }
   },
   doStart(node){
    if(this.getAuth()!=null){
      this.$store.commit('setBusyState',true);  
      var apipath = (node.virtualMachine.scaleSetVm == true)? "/api/scale" : "/api/scaleup";
      this.$http.post(apipath,{"command": "scale", "argument": "{ \"hostname\":\""+node.virtualMachine.roleName+"\" }"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
    },
   doScaleUp(){
     if(this.getAuth()!=null){
      this.$store.commit('setBusyState',true);
      //Are there any scale set machines? Then use scale set
      var usess = (this.nodes ?? []).filter((node) => {
                             return node.virtualMachine.scaleSetVm == true;
                          }).length > 0;
      var apipath = (usess)? "/api/scale" : "/api/scaleup";
      this.$http.get(apipath,{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
     }
   },
   projectScaleUp(){
    UIkit.modal.prompt("Please proceed by providing the name of the project ","",{labels: { ok : 'Proceed', cancel: 'Cancel'}}).then(proj => this.doProjectScaleUp(proj),() => {})
   },
   doProjectoScaleUp(proj){
     if(this.getAuth()!=null){
      this.$store.commit('setBusyState',true);
      this.$http.post("/api/scaleup",{"command": "", "argument": "{ \"project\":\""+proj+"\" }"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        });
     }
   },
   scaleOut(ssVm,rdshost,deleteHost){
    UIkit.modal.confirm("Please confirm your intent to stop/delete the session host",{labels: { ok : 'Confirm', cancel: 'Cancel'}}).then(() => {this.doScaleOut(ssVm,rdshost,deleteHost);},() => {})
   },
   doScaleOut(ssVm,rdshost,deleteHost){
      if(this.getAuth()!=null){
        this.$store.commit('setBusyState',true);
        var apipath = ssVm? "/api/scale" : "/api/scaleout";
        this.$http.post(apipath,{"command": deleteHost, "argument": "{ \"hostname\":\""+rdshost+"\" }"},{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
      }
   },
   forceADSynch(){
     if(this.getAuth()!=null){
      this.$store.commit('setBusyState',true);
      this.$http.get("/api/synchad",{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
     }
   },
   forceDBSynch(){
     if(this.getAuth()!=null){
      this.$store.commit('setBusyState',true);
      this.$http.get("/api/synchdb",{
          headers: {
            Authorization: this.getAuth()
          }
        })
        .then(response => {
          if(response && response.data){
            if(!response.data.error){
              this.$store.commit('addPositiveMessage',response.data.msg);
            }
            else{
              this.$store.commit('addNegativeMessage',response.data.msg);
            }
          }
          this.$store.commit('setBusyState',false);
        }).catch(e => {
        // eslint-disable-next-line no-console
            console.log(e);
            this.$store.commit('setBusyState',false);
        })
     }
   },
   refreshAll(){
    this.$http
      .get("/api/machines", {
        headers: {
          Authorization: this.getAuth()
        }
      })
      .then(response => {
        this.nodes = response.data;
        this.loading = false;
        this.currentVMSize = "";
        this.newVMSize = "";
        this.availableSizes.length = 0;
        this.updateCost();
      });
    this.getCompanies();
    this.getShares();
    this.getPools();
    this.shareSizes();
    this.updSizes();
    this.rdpSessions();
   },
   refreshCompanies(){
    this.getCompanies();
   },
   refreshShares(){
    this.getShares();
   },
   refreshPools(){
    this.getPools();
   },
   refreshShareSizes(){
    this.shareSizes();
   },
   refreshUPDSizes(){
    this.updSizes();
   },
   refreshRDPSessions(){
    this.rdpSessions();
   },
   refreshMachines(){
     this.$http
      .get("/api/machines", {
        headers: {
          Authorization: this.getAuth()
        }
      })
      .then(response => {
        this.nodes = response.data;
        this.loading = false;
        this.currentVMSize = "";
        this.newVMSize = "";
        this.availableSizes.length = 0;
        this.updateCost();
      });
   },
    getAuthCloudServices(){
      return (localStorage.getItem("token")!=null && localStorage.getItem("token").length > 0)?
              "Basic ".concat(localStorage.getItem("token") )
            :((localStorage.getItem("bearerjwt")!=null && localStorage.getItem("bearerjwt").idToken )?
              "Bearer ".concat(localStorage.getItem("bearerjwt").idToken)
              : null);
    }
  },
  created() {
    this.rdpsessionlist=[];
    this.shareadminlist=[];
    this.sharesizesgb={};
    this.updsizesgb={};
    this.pooladminlist=[];
    this.companies = [];
    this.selectedCompany="";
    this.companyUsers=[];
    this.companyEmployees=[];
    this.selectedUser="";
    this.selectedUserToInvite="";
    this.proposedUsername="";
    this.selectedCompanyUser="";
    this.selectedCompanyUserName="";
    this.trialUserFirstName="";
    this.trialUserLastName="";
    this.trialUserCompanyName="";
    this.companyRef="";    
    this.nodes = [];
    this.loading = true;
    this.currentVMSize = "";
    this.currentVMStatus = null;
    this.currentMachine = null;
    this.newVMSize = "";
    this.availableSizes = [];
    this.$store.commit("setBusyState", false);
    this.$http
      .get("/api/machines", {
        headers: {
          Authorization: this.getAuth()
        }
      })
      .then(response => {
        this.nodes = response.data;
        this.loading = false;
        this.currentVMSize = "";
        this.newVMSize = "";
        this.availableSizes.length = 0;
        this.updateCost();
      }).catch(error => {
          this.nodes = [];
          console.log(error);
          this.loading = false;
          this.currentVMSize = "";
          this.newVMSize = "";
          this.availableSizes.length = 0;
          this.updateCost();
        });
    this.getCompanies();
    this.getShares();
    this.getPools();
    this.shareSizes();
    this.updSizes();
    this.rdpSessions();
    this.listTrialUsers();
  }
};
</script>
