//import Vue from 'vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store'
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import * as msal from '@azure/msal-browser';

UIkit.use(Icons);

// eslint-disable-next-line no-unused-vars
import * as azblob from './azure-storage-blob';

//sp=rw&st=2020-12-23T08:53:27Z&se=2030-12-23T16:53:27Z&spr=https&sv=2019-12-12&sr=b&sig=gUR8PWhLC6z2fXrLABLAtRrLS5naHKZUTzPOyTA4O8o%3D
//https://theequabatch.blob.core.windows.net/styles/ecs.css?sp=rw&st=2020-12-23T08:53:27Z&se=2030-12-23T16:53:27Z&spr=https&sv=2019-12-12&sr=b&sig=gUR8PWhLC6z2fXrLABLAtRrLS5naHKZUTzPOyTA4O8o%3D
//import 'https://theequabatch.blob.core.windows.net/styles/ecs.css';
/*
var msalConfig = {
  auth: {
      clientId: "feddb6da-4b70-4826-a598-fbaff74ee722",
      authority: "https://login.microsoftonline.com/e2ce1cd3-f9bb-4161-bffb-57274421f5dc",
      redirectUri: window.location.pathname
      //redirectUri: `http://localhost:3000`
  },
  cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              }
              console.log(message);
              /*
              switch (level) {
                  case window.msal.LogLevel.Error:
                      console.error(message);
                      return;
                  case window.msal.LogLevel.Info:
                      console.info(message);
                      return;
                  case window.msal.LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case window.msal.LogLevel.Warning:
                      console.warn(message);
                      return;
              }*//*
          }
      }
  }
};

var myMSALObj = new PublicClientApplication(msalConfig);

var loginRequest ={
  scopes: ["User.Read"]
};

myMSALObj.handleRedirectPromise().then(handleResponse);

var accountId = {};
const accounts =  this.myMSALObj.getAllAccounts();
if (accounts.length > 0) {
    accountId = accounts[0];
}
function signIn() {
  const signInType ="loginRedirect";
  if (signInType === "loginPopup") {
      myMSALObj.loginPopup(loginRequest).then(handleResponse).catch(function (error) {
          console.log(error);
      });
  } else if (signInType === "loginRedirect") {
      myMSALObj.loginRedirect(loginRequest);
  }
}

function signOut() {
  const logoutRequest = {
      account: myMSALObj.getAccountByHomeId(accountId)
  };
  myMSALObj.logoutRedirect(logoutRequest);
}

function handleResponse(resp){
  if(resp.idToken){
     localStorage.setItem(
        "bearerjwt",
        resp.idToken
      );
  }
}
*/


//Vue.config.productionTip = false
//Vue.use(VueAxios, axios);
//Vue.use(SuiVue);
//Vue.prototype.theWindow = window;
//Vue.prototype.theNotification = Notification;
//Vue.prototype.theSharedWorker = new SharedWorker('./sharedWorker.js', {name: 'ecs-shared',type: 'module'});
//new Vue({store,router, render: h => h(App)}).$mount('#app')
function handleResponse(resp){
  if(resp!=null && resp.idToken){
    console.log(resp);
    const reload = (localStorage.getItem("bearerjwt")==null);
    localStorage.setItem(
       "bearerjwt",
       JSON.stringify(resp)
     );
     localStorage.setItem(
      "aaduser",
      resp.account.username
    );
     localStorage.removeItem("prompt")
     if(reload==true){
       location.reload();
     }
     else{
       localStorage.removeItem("authenticating");
       //localStorage.setItem("authenticating",null);
     }
  }
}
const conf = {
  auth: {
    // eslint-disable-next-line no-undef
    clientId: appConf.clientId,
    // eslint-disable-next-line no-undef
    authority: appConf.authority,
    redirectUri: window.location.origin
    //redirectUri: `http://localhost:3000`
  },
  cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case msal.LogLevel.Error:
                      console.error(message);
                      return;
                  case msal.LogLevel.Info:
                      console.info(message);
                      return;
                  case msal.LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case msal.LogLevel.Warning:
                      console.warn(message);
                      return;
              }
          }
      }
  }
};

const mMSALObj = await msal.PublicClientApplication.createPublicClientApplication(conf);

function getAADAuth(){
  console.log("getAADAuth refresh silently")
  if(localStorage.getItem("username")!=null) {
  let silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "username@d.cloud.equa.se"
  };
  if(localStorage.getItem("aaduser")!=null){
    silentRequest.loginHint = localStorage.getItem("aaduser");
  }
  localStorage.setItem("authenticating",true)
  mMSALObj.ssoSilent(silentRequest)
  .then(handleResponse).catch( function (error) {
    console.log("single singon failed to refresh " + error);
    /*
    localStorage.removeItem("token");
    localStorage.removeItem("bearerjwt");
    localStorage.removeItem("username");
    localStorage.removeItem("userid");
    localStorage.removeItem("mailtoken");
    localStorage.removeItem("forumurl");
    localStorage.setItem("prompt","select_account")
    */
    localStorage.removeItem("authenticating");
    location.reload();
  });
  }
}

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    getAADAuth();
    //UIkit.notification("Unauthorized",{status:'warning',pos:'top-left'});
  }
  return error; 
});
//mMSALObj.handleRedirectPromise().then(handleResponse);

const app = createApp(App)
app.use(VueAxios, axios)
app.use(router)
app.use(store)
app.mixin({
  data: function() {
    return {
      msalConfig : conf,
      myMSALObj: mMSALObj,
      loginRequest:{scopes: ["openid", "profile"], prompt: 'none'},
      loginRequestPrompt:{scopes: ["openid", "profile"], prompt: 'login'},
      accountId:{},
      silentRequest : {
        scopes: ["openid", "profile"],
        loginHint: "username@d.cloud.equa.se",
        prompt: 'none'
      }
    }
  },
  created: function () {
    this.myMSALObj.handleRedirectPromise().then(handleResponse).catch((error)=>{localStorage.setItem("prompt","select_account");localStorage.removeItem("authenticating");console.log(error)});
    /*
    const accounts =  this.myMSALObj.getAllAccounts();
    if (accounts.length > 0) {
        console.log(accounts);
        this.accountId = accounts[0].homeAccountId;
    }
    */
    if(localStorage.getItem("bearerjwt") && localStorage.getItem("asked-for-notifications")==null){
      localStorage.setItem("asked-for-notifications",true);
      if (Notification.permission === "denied") {
        console.log("Notifications blocked. Please enable them in your browser settings for full functionality.");
      }
      else{
        if(Notification.permission === "default"){
          UIkit.modal.confirm("Would you like to get notifications in this browser?").then(()=>{
            Notification.requestPermission().then(() => {
              //Install service-worker
              if ('serviceWorker' in navigator) {
                console.log("register service worker");
                //window.addEventListener('load', function() {
                  navigator.serviceWorker.register('./sw.js',{ scope: '/' }).then(function() {
                    return navigator.serviceWorker.ready;
                  }).then(function(registration) {
                    // Registration was successful   
                    //var opts = {}
                    //opts.body = "Test notification"
                    //opts.icon = "/static/img/icons/favicon-32x32.png"
                    //dir,lang,badge,body,tag,icon,image,data,vibrate,renotify,requireInteraction,actions,silet
                    //registration.showNotification('hello',opts);
                    // eslint-disable-next-line no-console
                    console.log('ServiceWorker registration successful with scope: ', registration.scope);
                  }, function(err) {
                    // registration failed :(
                    // eslint-disable-next-line no-console
                    console.log('ServiceWorker registration failed: ', err);
                  });
                  
                 }
                //);}
                else{
                  console.log("No service workers in this browser.");
                }
              
              console.log("Notification permissions granted.");},
              () => {console.log("Notification permissions denied.");});
          });
        // value of permission can be 'granted', 'default', 'denied'
        // granted: user has accepted the request
        // default: user has dismissed the notification permission popup by clicking on x
        // denied: user has denied the request.
        }
        if(Notification.permission !== 'granted'){
          //Enable some kind of notification...
          //throw new Error('Permission not granted for Notification');
        }
        else{
          //Enable the request notifications dialogue
          
        }
      }
    }
  },
  methods: {
    signIn() {
        localStorage.setItem("authenticating",true)
        this.myMSALObj.loginRedirect(this.loginRequestPrompt);
        /*
        this.myMSALObj.ssoSilent( localStorage.getItem("prompt")!=null? this.loginRequestPrompt: this.loginRequest ).then(this.handleResponse).catch(
        (err) => {
          console.log(err);
          localStorage.setItem("prompt","select_account");
          const signInType ="loginRedirect";
          if (signInType === "loginPopup") {
              this.myMSALObj.loginPopup((localStorage.getItem("prompt")!=null)?this.loginRequestPrompt:this.loginRequest).then(this.handleResponse).catch(function (error) {
                  console.log(error);
              });
          } else if (signInType === "loginRedirect") {
              this.myMSALObj.loginRedirect((localStorage.getItem("prompt")!=null)?this.loginRequestPrompt:this.loginRequest);
          }
        });*/
    },
    signOut() {
      const accountFilter = {
        homeAccountId: this.accountId,
        username: localStorage.getItem("aaduser"),
      };
      const logoutRequest = {
          account: ()=>{try{this.myMSALObj.getAccount(accountFilter)} catch (err) {null}},
      };
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("prompt","select_account")
      this.myMSALObj.logoutRedirect(logoutRequest);
    },
    handleResponse(resp){
      if(resp!=null && resp.idToken){
        this.accountId = resp.account.homeAccountId;
        console.log(resp);
        if(resp.idTokenClaims && resp.idTokenClaims.groups){
          localStorage.setItem(
            "groups",
            JSON.stringify(resp.idTokenClaims.groups)
          );
        }
        const reload = (localStorage.getItem("bearerjwt")==null);
         localStorage.setItem(
            "bearerjwt",
            JSON.stringify(resp)
          );
          localStorage.setItem(
            "aaduser",
            resp.account.username
          );
          localStorage.removeItem("prompt")
          if(reload==true){
            location.reload();
          }
          else{
            localStorage.removeItem("authenticating");
            localStorage.removeItem("aaduser");
            //localStorage.setItem("authenticating",null);
          }
      }
    },
    handleError(error) {
      //Acquire token silent failure, and send an interactive request
      if (msal && error instanceof msal.InteractionRequiredAuthError && this.myMSALObj!=null) {
        this.myMSALObj
          .loginRedirect(this.loginRequestPrompt)
          .then(this.handleResponse)
          .catch(function (error) {
            // Acquire token interactive failure
            console.log(error);
          });
      }
      //localStorage.setItem("authenticating",null);
      localStorage.removeItem("authenticating");
      localStorage.removeItem("aaduser");
      console.log(error);
    },
    getAuth(){
      //console.log("getAuth from mixin")
      if(localStorage.getItem("bearerjwt")!=null){
        try{
          if((new Date(JSON.parse(localStorage.getItem("bearerjwt")).expiresOn).getTime()-new Date().getTime()) < 100){
            localStorage.setItem("aaduser",JSON.parse(localStorage.getItem("bearerjwt")).account.username);
            localStorage.removeItem("bearerjwt");
            console.log("Refresh token!");
          }
        }
        catch (err){
          localStorage.removeItem("bearerjwt");
        }
      }
      if (localStorage.getItem("token")==null && 
          localStorage.getItem("bearerjwt")==null && 
          localStorage.getItem("authenticating")==null && localStorage.getItem("aaduser")!=null){
          this.silentRequest.loginHint = localStorage.getItem("aaduser")
          localStorage.setItem("authenticating",true);
          this.myMSALObj.ssoSilent(this.silentRequest)
          .then(this.handleResponse)
          .catch(this.handleError);
      } 
      return (localStorage.getItem("token")!=null && localStorage.getItem("token").length > 0)?
              "Basic ".concat(localStorage.getItem("token") )
            :((localStorage.getItem("bearerjwt")!=null && localStorage.getItem("bearerjwt").length > 0 )?
              "Bearer ".concat(JSON.parse(localStorage.getItem("bearerjwt")).idToken)
              : null);
    }
  },
})
//app.use(azblob)
app.mount('#app')
window.azblob = azblob;

/*new Vue({
  store,
  router,
  created() {
    store.commit('initializeStore');
    /*
    this.theSharedWorker.port.addEventListener('message',function(e) {
      // eslint-disable-next-line no-console
      console.log("Got message from shared worker in main.js " + JSON.stringify(e.data));
    });
    this.theSharedWorker.port.addEventListener('error',function(e) {
      // eslint-disable-next-line no-console
      console.log("Shared worker error in main.js:" + JSON.stringify(e.data))  
    });
    this.theSharedWorker.port.start();
    // Ask for notifications permission
    if (Notification.permission === "denied") {
      alert("Notifications blocked. Please enable them in your browser settings for full functionality.");
    }
    else{
      if(Notification.permission === "default" && UIkit.modal.confirm("Would you like to get notifications in this browser?")){
        window.Notification.requestPermission();
        // value of permission can be 'granted', 'default', 'denied'
        // granted: user has accepted the request
        // default: user has dismissed the notification permission popup by clicking on x
        // denied: user has denied the request.
        
      }
      if(Notification.permission !== 'granted'){
        throw new Error('Permission not granted for Notification');
      }
      else{
        //Enable the request notifications dialogue
        
      }
    }
    //Install service-worker
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function() {
        navigator.serviceWorker.register('./sw.js',{ scope: '/' }).then(function() {
          return navigator.serviceWorker.ready;
        }).then(function(registration) {
          // Registration was successful   
          //var opts = {}
          //opts.body = "Hejsan"
          //opts.icon = "/static/img/icons/favicon-32x32.png"
          //dir,lang,badge,body,tag,icon,image,data,vibrate,renotify,requireInteraction,actions,silet
          //registration.showNotification('hello',opts);
          // eslint-disable-next-line no-console
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function(err) {
          // registration failed :(
          // eslint-disable-next-line no-console
          console.log('ServiceWorker registration failed: ', err);
        });
        
      });
    }*/
  //},
  //render: h => h(App)}).$mount('#app')
